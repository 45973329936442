/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  IconButton,
  useTheme,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer
} from '@mui/material';
import moment from 'moment';
import { getLocalData } from 'src/utils';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import { CustomDatePicker } from 'src/components/CustomDatePicker';
// import { getLocalData } from 'src/utils';
import Loader from '../../../../components/Loader';
import { ApiService } from 'src/services/ApiService';

export const CreditsTable = (props) => {
  const { tableRows, searchQuery, clientId, getAllAlert } = props;
  const theme = useTheme();
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rowExpDate, setRowExpDate] = useState('');
  const [isLoading, setLoading] = useState(false);
  const userId = getLocalData('userId');

  useEffect(() => {
    const searchText = searchQuery.toLowerCase();
    setSearchText(searchText);
    setPage(0);
  }, [searchQuery]);

  const filteredData =
    tableRows &&
    tableRows.filter((item) => {
      return Object.values(item).some((value) =>
        value?.toString()?.toLowerCase()?.includes(searchText)
      );
    });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedData =
    filteredData &&
    filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    setPage(0);
  }, [tableRows]);

  const dateFormate = (date) => {
    let utc;
    utc = date ? moment?.utc(date).format('MMM D, YYYY') : '';
    return utc;
  };

  // Edit method to edit credits
  const editCredit = (id, rowItem) => {
    if (
      rowItem?.remainingSession <= 0 ||
      rowItem?.refundStatus === 'COMPLETED' ||
      rowItem?.subscriptionStatus === 'PAUSED'
    ) {
      return;
    }
    if (id) {
      // setRowExpDate(date);
      let savebtn = document.getElementsByClassName('save_' + id)[0];
      let editbtn = document.getElementsByClassName('edit_' + id)[0];
      let editdate = document.getElementsByClassName('edit_date_' + id)[0];
      let showdate = document.getElementsByClassName('show_date_' + id)[0];
      let questionList = document.querySelectorAll('.saveicon');
      if (questionList.length) {
        for (let j = 0; questionList.length > j; j++) {
          if (questionList[j]) {
            if (!questionList[j].classList.contains('-d-none')) {
              questionList[j].click();
            }
          }
        }
      }
      if (savebtn && savebtn.classList.contains('-d-none')) {
        savebtn.classList.remove('-d-none');
        savebtn.className += ' editing';
      }

      // edit date
      if (editdate && editdate.classList.contains('-d-none')) {
        editdate.classList.remove('-d-none');
      }
      if (editbtn && !editbtn.classList.contains('-d-none')) {
        editbtn.className += ' -d-none';
      }
      // Show date
      if (showdate && !showdate.classList.contains('-d-none')) {
        showdate.className += ' -d-none';
      }
    }
  };

  /* Function to set EXPIRATION date */
  const ExpirationDate = (date) => {
    if (date === '01-01-1970') {
      return;
    }

    // setIsFieldUpdated(true);
    setRowExpDate(date);
  };

  const saveCredit = (id, item) => {
    if (id) {
      let savebtn = document.getElementsByClassName('save_' + id)[0];
      let editbtn = document.getElementsByClassName('edit_' + id)[0];
      let editdate = document.getElementsByClassName('edit_date_' + id)[0];
      let showdate = document.getElementsByClassName('show_date_' + id)[0];

      if (editbtn && editbtn.classList.contains('-d-none')) {
        editbtn.classList.remove('-d-none');
      }
      if (savebtn && !savebtn.classList.contains('-d-none')) {
        savebtn.className += ' -d-none';
        editbtn.classList.remove('editing');
      }

      // show date
      if (showdate && showdate.classList.contains('-d-none')) {
        showdate.classList.remove('-d-none');
      }

      // edit date
      if (editdate && !editdate.classList.contains('-d-none')) {
        editdate.className += ' -d-none';
      }

      // Now call the API to update the expiry date
      if (rowExpDate && id) updateExpiryDate(id, item);
    }
  };

  /* Function to add task  */

  const updateExpiryDate = async (id, item) => {
    setLoading(true);

    let url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}checkout/extendValidity`;
    let obj = {
      clientId: clientId,
      creditId: id,
      dateOfExpiry: rowExpDate, //  dateOfExpiry
      updatedBy: userId
    };

    try {
      const data = await ApiService.put(`${url}`, obj);
      if (data.statusCode && !data.error) {
        setLoading(false);
        setRowExpDate('');
        getAllAlert();
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Loader IsLoading={isLoading} />
      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px'
        }}
      >
        <TableContainer className="location-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell>Name</TableCell>
                <TableCell>Service</TableCell>
                <TableCell>Available Credits</TableCell>
                <TableCell className="text-transform-none">
                  Date of Purchase
                </TableCell>
                <TableCell>Purchase Mode</TableCell>
                <TableCell className="text-transform-none">Status</TableCell>
                <TableCell className="text-transform-none">
                  Date of Expiry
                </TableCell>

                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {(paginatedData &&
                paginatedData.length > 0 &&
                paginatedData.map((row) => {
                  // const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      key={row.sortKey}
                      role="checkbox"
                      tabIndex={-1}
                      className="table-row cursor-pointe"
                    >
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                        >
                          {row?.itemName || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          // noWrap
                        >
                          {(row?.services &&
                            row?.services?.length > 0 &&
                            row?.services
                              ?.map((item) => item.name)
                              .join(', ')) ||
                            '-'}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.remainingSession > 0
                            ? row?.remainingSession
                            : 0}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.dateOfPurchase &&
                            dateFormate(row?.dateOfPurchase)}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.modeOfPurchase || '-'}
                        </Typography>
                      </TableCell>

                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.subscriptionStatus || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          className={'show_date_' + row.sortKey}
                          gutterBottom
                          noWrap
                        >
                          {row?.dateOfExpiry &&
                            row?.dateOfExpiry !== ' ' &&
                            dateFormate(row?.dateOfExpiry)}
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          className={'-d-none edit_date_' + row.sortKey}
                          gutterBottom
                          noWrap
                        >
                          <CustomDatePicker
                            fieldValue={row?.dateOfExpiry}
                            onChange={ExpirationDate}
                            minDate={new Date()}
                            className="field-top-margin-edit-locatio mt-5 show no-height date-nobode credit"
                            sx={{ width: '100px' }}
                          />
                        </Typography>
                      </TableCell>

                      <TableCell className="table-td">
                        <IconButton
                          sx={{
                            '&:hover': {
                              background: theme.colors.secondary.lighter
                            },
                            color: theme.palette.primary.main,
                            borderRadius: '50% !important'
                          }}
                          color="inherit"
                          size="medium"
                          borderRadius="50% !important"
                          className={'editIcon edit_' + row.sortKey}
                          onClick={(e) => editCredit(row.sortKey, row)}
                        >
                          <EditTwoToneIcon fontSize="medium" />
                        </IconButton>
                        <IconButton
                          sx={{
                            '&:hover': {
                              background: theme.colors.secondary.lighter
                            },
                            color: theme.palette.primary.main,
                            borderRadius: '50% !important'
                          }}
                          color="inherit"
                          size="medium"
                          borderRadius="50% !important"
                          className={'saveicon -d-none save_' + row.sortKey}
                          onClick={(e) => saveCredit(row.sortKey, row)}
                        >
                          <SaveTwoToneIcon fontSize="medium" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })) || (
                <TableRow>
                  <TableCell colSpan={6} className="table-td">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ marginTop: 1 }}
                      gutterBottom
                      noWrap
                    >
                      No data found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box p={2}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={filteredData && filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Card>
    </div>
  );
};
