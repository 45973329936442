/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
import React, { useState, forwardRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { convertUtcToUserTimeEdit } from 'src/utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { convertToCustomFormat } from './Const';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { getUserTimeZone } from 'src/utils';
import {
  Typography,
  Button,
  Box,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Grid,
  FormControl,
  RadioGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Slide,
  FormHelperText,
  InputLabel,
  Select,
  Autocomplete
} from '@mui/material';
import Loader from '../Loader';
import {
  CustomTextField,
  CustomSelect,
  CustomButton
} from 'src/components/Global';
import { CustomDatePickerAddSchedule } from './CustomDatePickerAddSchedule';
import {
  isOutOfTimeAvailability,
  getValueFromId,
  isValidTimeFormat,
  isEndTimeGreaterOrEqual,
  formatTimeOrDate,
  convertUTCToNormal,
  convertTimeToUTC,
  getOccurencePayload
} from './Const';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { getLocalData } from 'src/utils';
import { makeStyles } from '@material-ui/core/styles';
import { CalenderDialogs } from '.';
import { ApiService } from 'src/services/ApiService';
import ErrorMessage from '../PopupMessage/ErrorMessage';
import SuccessMessage from '../PopupMessage/SuccessMessage';
import { Card, TextField } from '@material-ui/core';
import TrainerAvailabilityTable from './TrainerAvailabilityTable';
// import { GridSearchIcon } from '@mui/x-data-grid';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const initialDialogs = {
  state: false,
  isCreated: false,
  type: false,
  edit: false,
  delete: false,
  notify: false,
  outTime: false,
  bookClass: false,
  isDelete: false,
  isEditCustom: false,
  isDeleteCustom: false
};

export function AddSchedule(props) {
  const {
    handleResetStates,
    isScheduleEdit,
    // eslint-disable-next-line no-unused-vars
    setIsScheduleEdit,
    initialScheduleValues,
    newSchedule,
    setNewSchedule,
    dialog,
    setDialog,
    allReqFulFilled,
    setAllReqFulFilled,
    btnLoading,
    setBtnLoading,
    allServiceCategories,
    allTrainers,
    allServices,
    allParents,
    allPets
  } = props;
  const navigate = useNavigate();
  const BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;

  const locationId = getLocalData('locationId');
  const franchiseeId = getLocalData('franchiseeId');
  let userId = getLocalData('userId');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [notifyDialog, setNotifyDialog] = useState(initialDialogs);
  const [notify, setNotify] = useState('all');
  const [creditsAvailable, setCreditsAvailable] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);
  const [sufficiantCredits, setSufficiantCredits] = useState(false);
  const [showCredit, setShowCredit] = useState(false);
  const [noOfCredit, setnoOfCredit] = useState([]);
  const [isCreditApplied, setIsCreditApplied] = useState(false);
  const [openPaylaterPopUp, setOpenPaylaterPopUp] = useState(false);
  const [DateShouldDiffError, setDateShouldDiffError] = useState('');
  const [TimeShouldDiffError, setTimeShouldDiffError] = useState(false);
  const [trainerData, setTrainerData] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [roomError, setRoomError] = useState(false);
  const [trainerError, setTrainerError] = useState(false);
  const [parents, setParents] = useState(null);
  const [isDisable, setIsDisable] = useState(false);
  const [showTable, setShowTable] = useState(false);
  // const [roomId, setRoomId] = useState('');
  const [gettingCredits, setGettingCredits] = useState(false);
  const userName = getLocalData('UserName');
  const [isLoading, setIsLoading] = useState(false);
  const [closeDialog, setCloseDialog] = useState({
    state: false,
    isCreated: false,
    creditApplied: false,
    insufficientCredits: false
  });

  const [openVaccinationDialog, setOpenVaccinationDialog] = useState(false);
  const [vaccinationDetails, setVaccinationDetails] = useState({
    bordetellaAlert: '',
    dhppExpiration: '',
    rabiesAlert: ''
  });

  // be.low is used for the check availability
  const [availabilityCheck, setAvailabilityCheck] = useState({
    roomAvailble: true,
    staffAvailable: true,
    staffAvailablityHours: true,
    locationAvailability: true,
    locationOperatingHour: true
  });

  const [showOperatingHoursPopup, setShowOperatingHoursPopup] = useState(false);
  const [isHoliday, setIsHoliday] = useState(true);

  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefe
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });
  const autoCompleteClasses = useAutocompleteStyles();
  const daysOfWeek = [
    { label: 'M', value: 'mo' },
    { label: 'T', value: 'tu' },
    { label: 'W', value: 'we' },
    { label: 'T', value: 'th' },
    { label: 'F', value: 'fr' },
    { label: 'S', value: 'sa' },
    { label: 'S', value: 'su' }
  ];
  const timeSlots = [];
  const startTime = '12:00 AM';

  for (let i = 0; i < 288; i++) {
    const time = new Date(`01/01/2023 ${startTime}`);
    time.setMinutes(time.getMinutes() + i * 5);
    const timeString = time.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    });
    timeSlots.push({ value: timeString, label: timeString });
  }
  // useEffect(()=>{
  //   if(newSchedule.trainer&&newSchedule.room)
  // },[])
  const [isError, setIsError] = useState({
    status: false,
    serviceCategory: false,
    serviceId: false,
    fromDate: false,
    toDate: false,
    fromTime: false,
    toTime: false,
    fromTimeFormat: false,
    toTimeFormat: false,
    trainer: false,
    occurence: false,
    room: false,
    roomId: false,
    description: false,
    parent: false,
    pets: false,
    intervalDays: false
  });
  const valuesAreDifferent = () => {
    if (typeof newSchedule !== typeof initialScheduleValues) {
      return false;
    }

    if (
      typeof newSchedule !== 'object' ||
      typeof initialScheduleValues !== 'object'
    ) {
      return newSchedule === initialScheduleValues;
    }

    const keys1 = Object.keys(newSchedule);
    const keys2 = Object.keys(initialScheduleValues);
    if (keys1.length !== keys2.length) {
      return false;
    }

    const keys = Object.keys(initialScheduleValues);
    return keys.some(
      (key) => key !== 'type' && initialScheduleValues[key] !== newSchedule[key]
    );
  };

  useEffect(() => {
    const getData = async () => {
      if (newSchedule.serviceId) {
        allServices.forEach((service) => {
          if (newSchedule.serviceId === service.serviceId) {
            setNewSchedule({
              ...newSchedule,
              description: service.description
            });
          }
        });
        try {
          let data = {
            locationId: locationId,
            serviceIds: [newSchedule.serviceId]
          };

          let response = await ApiService.post(
            `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getTrainerWithRooms`,
            data
          );

          let Item =
            response && response.data && response.data.Items
              ? response.data.Items
              : [];

          setTrainerData(Item.staffData);
          setRoomData(Item.roomData);

          if (Item.staffData.length > 0) {
            setTrainerError(false);
          }
          if (Item.roomData.length > 0) {
            setRoomError(false);
          }
          if (Item.staffData.length <= 0) {
            setTrainerError(true);
          }
          if (Item.roomData.length <= 0) {
            setRoomError(true);
          }
        } catch (error) {
          console.error('Error getting data:', error);
        }
      }
    };

    getData();
  }, [newSchedule.serviceId, locationId, ApiService]);
  useEffect(() => {
    if (
      newSchedule?.parent &&
      newSchedule?.pets &&
      newSchedule?.fromDate &&
      newSchedule?.toDate&&
      !isScheduleEdit
    ) {
      let petData = allPets?.find(
        (item) => item?.sortKey === newSchedule?.pets
      );
      let scheduleDate = new Date(newSchedule?.fromDate);
      let scheduleToDate = new Date(newSchedule?.toDate);
      let brdelaexp = petData?.bordetellaExpiration?.replaceAll('-', '/');
      let rbexp = petData?.rabiesExpiration?.replaceAll('-', '/');
      let dhppexp = petData?.dhppExpiration?.replaceAll('-', '/');
      let bordetellaExpiration = new Date(brdelaexp);
      let rabiesExpiration = new Date(rbexp);
      let dhppExpiration = new Date(dhppexp);

      let newVaccinationDetails = {
        bordetellaAlert: '',
        rabiesAlert: '',
        dhppExpiration: ''
      };

      if (newSchedule.occurence === 'noRepeat') {
        if (scheduleDate > bordetellaExpiration) {
          setOpenVaccinationDialog(true);
          newVaccinationDetails.bordetellaAlert = `Bordetella vaccination expired on ${petData.bordetellaExpiration}.`;
        }
        if (scheduleDate > rabiesExpiration) {
          setOpenVaccinationDialog(true);
          newVaccinationDetails.rabiesAlert = `Rabies vaccination expired on ${petData.rabiesExpiration}.`;
        }
        if (scheduleDate > dhppExpiration) {
          setOpenVaccinationDialog(true);
          newVaccinationDetails.dhppExpiration = `Combo Shot vaccination expired on ${petData.dhppExpiration}.`;
        }
        setVaccinationDetails(newVaccinationDetails);
      } else {
        // Check both fromDate and toDate for other occurrences
        if (
          (bordetellaExpiration >= scheduleDate ||
            bordetellaExpiration <= scheduleDate) &&
          bordetellaExpiration < scheduleToDate &&
          scheduleDate !== scheduleToDate
        ) {
          newVaccinationDetails.bordetellaAlert = `Bordetella vaccination expires on ${petData.bordetellaExpiration}.`;
          setOpenVaccinationDialog(true);
        }
        if (
          (rabiesExpiration >= scheduleDate ||
            rabiesExpiration <= scheduleDate) &&
          rabiesExpiration < scheduleToDate &&
          scheduleDate !== scheduleToDate
        ) {
          newVaccinationDetails.rabiesAlert = `Rabies vaccination expires on ${petData.rabiesExpiration}`;
          setOpenVaccinationDialog(true);
        }
        if (
          (dhppExpiration >= scheduleDate || dhppExpiration <= scheduleDate) &&
          dhppExpiration < scheduleToDate &&
          scheduleDate !== scheduleToDate
        ) {
          newVaccinationDetails.dhppExpiration = `Combo Shot vaccination expires on ${petData.dhppExpiration}`;
          setOpenVaccinationDialog(true);
        }
      }
      setVaccinationDetails(newVaccinationDetails);
    }
  }, [
    newSchedule.parent,
    newSchedule.pets,
    newSchedule.fromDate,
    newSchedule.toDate
  ]);

  const handleCloseConfirm = () => {
    setOpenVaccinationDialog(false);
  };

  useEffect(() => {
    // VV commented below as we required validation on the Edit
    // if(!isScheduleEdit) {
    if (
      newSchedule.trainer &&
      newSchedule.room &&
      newSchedule.serviceId &&
      newSchedule.serviceCategory &&
      newSchedule.fromTime &&
      newSchedule.toTime
    ) {
      getTrainerAndStaffAvailability();
    }
    // }
  }, [newSchedule]);
  useEffect(() => {
    if (newSchedule.occurence === 'noRepeat') {
      setNewSchedule({
        ...newSchedule,
        toDate: newSchedule.fromDate
      });
    }
  }, [newSchedule.occurence]);
  const getTrainerAndStaffAvailability = async () => {
    let _fromDate = newSchedule.fromDate;
    let _toDate = newSchedule.toDate;

    if (_fromDate && _fromDate.includes('-'))
      _fromDate = _fromDate.replaceAll('-', '/');
    if (_toDate && _toDate.includes('-'))
      _toDate = _toDate.replaceAll('-', '/');

    // let startTime = convertDateTimeToUTC(newSchedule.fromDate + ',' + newSchedule.fromTime);
    // let endTime = convertDateTimeToUTC(newSchedule.toDate + ',' + newSchedule.toTime);
    let startTime = _fromDate + ',' + newSchedule.fromTime;
    let endTime = _toDate + ',' + newSchedule.toTime;

    let occurrence = {
      freq: newSchedule?.occurence,
      wkst:
        newSchedule && newSchedule?.intervalDays && newSchedule?.intervalDays[0]
          ? newSchedule?.intervalDays[0]
          : [],
      interval: newSchedule?.intervalValue ?? 1
      // "byweekday": newSchedule?.intervalDays,
    };
    if (newSchedule?.occurence === 'daily') {
      occurrence['byweekday'] = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];
    } else {
      occurrence['byweekday'] = newSchedule?.intervalDays;
    }
    try {
      let data = {
        locationId: locationId,
        staffId: newSchedule?.trainer,
        roomId: newSchedule?.roomId,
        // timeZone: getUserTimeZone(),
        // categoryId: newSchedule?.serviceCategory,
        fromDate: convertToCustomFormat(startTime),
        toDate: convertToCustomFormat(endTime),
        serviceId: newSchedule?.serviceId,
        repeat: newSchedule?.occurence,
        appointmentId: newSchedule?.id ?? ''
      };

      if (newSchedule?.occurence && newSchedule?.occurence !== 'noRepeat') {
        data.occurrence = occurrence;
      }

      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getTrainerAndStaffAvailability`,
        data
      );

      let Item = response && response.data ? response.data : [];

      const {
        roomAvailble,
        staffAvailable,
        staffAvailablityHours,
        locationAvailability,
        locationOperatingHour,
        staffTimeoff,
        staffTimeoffData
      } = Item;
      setAvailabilityCheck({
        roomAvailble: roomAvailble,
        staffAvailable: staffAvailable,
        staffAvailablityHours: staffAvailablityHours,
        locationAvailability: locationAvailability,
        locationOperatingHour: locationOperatingHour,
        staffTimeoff: staffTimeoff,
        staffTimeoffData: staffTimeoffData
      });

      // if (staffAvailable === false && staffAvailablityHours === false) {
      //   setAvailabilityCheck({
      //     roomAvailble: roomAvailble,
      //     staffAvailable: staffAvailable,
      //     staffAvailablityHours: !staffAvailablityHours,
      //     locationAvailability: locationAvailability,
      //     locationOperatingHour: locationOperatingHour
      //   });
      // } else if (staffAvailable === true && staffAvailablityHours === false) {
      //   setAvailabilityCheck({
      //     roomAvailble: roomAvailble,
      //     staffAvailable: staffAvailable,
      //     staffAvailablityHours: staffAvailablityHours,
      //     locationAvailability: locationAvailability,
      //     locationOperatingHour: locationOperatingHour
      //   });
      // }

      // const updatedIsError = {
      //   room: !roomAvailble,
      //   trainer: !staffAvailable,
      // };
      // setRoomTrainer(updatedIsError);
      if (!locationOperatingHour) {
        setShowOperatingHoursPopup(true);
      } else {
        setShowOperatingHoursPopup(false);
      }

      // below is show warning mesage for location holiday
      if (locationAvailability) {
        setIsHoliday(true);
      } else {
        setIsHoliday(false);
      }

      if (!staffAvailable || !roomAvailble || staffTimeoff) {
        setIsDisable(true);
      } else {
        setIsDisable(false);
      }
    } catch (error) {
      console.error('Error getting data:', error);
    }
  };
  useEffect(() => {
    if (
      newSchedule.parent &&
      newSchedule.pets &&
      newSchedule.serviceId &&
      newSchedule.serviceCategory
    ) {
      checkCredit();
    }
  }, [newSchedule]);

  const checkCredit = async () => {
    let sDate = newSchedule?.fromDate?.replaceAll('/', '-');
    let eDate = newSchedule?.toDate?.replaceAll('/', '-');
    let occurrence = {
      freq: newSchedule?.occurence,
      wkst:
        newSchedule && newSchedule?.intervalDays && newSchedule?.intervalDays[0]
          ? newSchedule?.intervalDays[0]
          : '',
      interval: newSchedule?.intervalValue ?? 1
      // "byweekday": newSchedule?.intervalDays,
    };
    if (newSchedule?.occurence === 'daily') {
      occurrence['byweekday'] = ['mo', 'tu', 'we', 'th', 'fr', 'sa','su'];
    } else {
      occurrence['byweekday'] = newSchedule?.intervalDays;
    }
    let data = {
      clientId: newSchedule.parent,
      locationId: locationId,
      petId: newSchedule.pets,
      fromDate: `${sDate}, ${newSchedule?.fromTime}`,
      toDate: `${eDate}, ${newSchedule?.toTime}`,
      categoryId: newSchedule.serviceCategory,
      serviceId: newSchedule.serviceId
    };
    if (newSchedule?.occurence && newSchedule?.occurence === 'noRepeat') {
      data.repeat = 'noRepeat';
    } else if (newSchedule?.occurence === 'daily') {
      data.repeat = 'daily';
      data.staffId = newSchedule.trainer;
      data.roomId = newSchedule.roomId;
      data.occurrence = occurrence;
    } else if (newSchedule?.occurence === 'weekly') {
      data.repeat = 'weekly';
      data.occurrence = occurrence;
      data.staffId = newSchedule.trainer;
      data.roomId = newSchedule.roomId;
    }
    setGettingCredits(true);
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}checkout/getAvailableCredits `,
        data
      );

      // Now, compare the lengths and open the dialog if necessary
      if (response.statusCode && !response.error) {
        const availableItems = response?.data?.Items?.filter(
          (items) => items?.remainingSession > 0
        );
        // setnoOfCredit(response?.data?.Items);
        setnoOfCredit(availableItems);
        setTotalCredits(response?.data?.totalRemainingSession);
        setSufficiantCredits(response?.data?.isSufficiantCredits);
        setShowCredit(true);
        setGettingCredits(false);
      }
    } catch (error) {
      setGettingCredits(false);
    }
  };
  // useEffect(() => {
  //   if (noOfCredit?.length > 0) {
  //     // setTotalCredits(noOfCredit[0]?.remainingSession);
  //     setTotalCredits(noOfCredit?.totalRemainingSession);
  //   } else {
  //     setTotalCredits(0);
  //   }
  //   // setShowCredit(true)
  // }, [noOfCredit]);
  const handleCloseScreen = () => {
    if (valuesAreDifferent()) {
      setCloseDialog({ ...closeDialog, state: true });
    } else {
      handleResetStates();
    }
  };

  const handleDialog = (value) => {
    if (value === 'Continue') {
      setCloseDialog({
        isCreated: false,
        state: false,
        creditApplied: false,
        insufficientCredits: false
      });
    } else if (value === 'Discard') {
      // setTrainerAvailable(true)
      handleResetStates();
    } else if (value === 'Cancel') {
      handleCloseScreen();
    }
  };
  const handleClosePopup = () => {
    setShowOperatingHoursPopup(false);
  };

  const handleToggleDay = (day) => {
    let selectedDays = [];
    if (newSchedule.intervalDays.includes(day)) {
      selectedDays = newSchedule.intervalDays.filter(
        (selectedDay) => selectedDay !== day
      );
    } else {
      selectedDays = [...newSchedule.intervalDays, day];
    }
    setNewSchedule({ ...newSchedule, intervalDays: selectedDays });
    setIsError({ ...isError, intervalDays: false });
  };

  const handleScheduleInfo = (type, value) => {
    if (type === 'serviceCategory') {
      setNewSchedule({ ...newSchedule, [type]: value, serviceId: '' });
      setIsError({ ...isError, [type]: false, serviceId: false });
    } else if (type === 'intervalValue') {
      if (value > 0) {
        setNewSchedule({ ...newSchedule, [type]: value });
      } else {
        setNewSchedule({ ...newSchedule, [type]: 1 });
      }
      setIsError({ ...isError, [type]: false });
    } else if (type === 'parent') {
      setNewSchedule({ ...newSchedule, [type]: value, pets: '' });
      setIsError({ ...isError, [type]: false, pets: false });
    } else if (type === 'fromDate') {
      setNewSchedule({
        ...newSchedule,
        [type]: formatTimeOrDate('date', value),
        toDate: formatTimeOrDate('date', value)
      });
      setIsError({ ...isError, [type]: false, toDate: false });
    } else if (type === 'toDate') {
      setNewSchedule({
        ...newSchedule,
        [type]: formatTimeOrDate('date', value)
        // ,
        // toTime: ''
      });
      setDateShouldDiffError('');
      setIsError({ ...isError, [type]: false });
    }
    // else if (type === 'fromTime') {
    //   setNewSchedule({ ...newSchedule, [type]: value });
    //   setIsError({ ...isError, [type]: false, fromTimeFormat: false });
    // } else if (type === 'toTime') {
    //   setNewSchedule({ ...newSchedule, [type]: value });
    //   setIsError({ ...isError, [type]: false, toTimeFormat: false });
    // }
    else if (type === 'occurence') {
      setNewSchedule({ ...newSchedule, [type]: value });
      setIsError({ ...isError, [type]: false, intervalDays: false });
    } else if (type === 'room') {
      setNewSchedule({
        ...newSchedule,
        room: value?.split('|')[0],
        roomId: value?.split('|')[1]
      });
      // setRoomId(value.split('|')[1])
      setIsError({ ...isError, room: false, roomId: false });
    } else {
      setNewSchedule({ ...newSchedule, [type]: value });
      setIsError({ ...isError, [type]: false });
    }
  };
  const handleScheduleInfoTime = (event) => {
    const { name, value } = event.target;

    if (name === 'fromTime') {
      setNewSchedule({ ...newSchedule, fromTime: value });
      setIsError({ ...isError, fromTime: false, fromTimeFormat: false });
    } else if (name === 'toTime') {
      setTimeShouldDiffError(false);
      setNewSchedule({ ...newSchedule, toTime: value });
      setIsError({ ...isError, toTime: false, toTimeFormat: false });
    }
  };
  const handleClientInfo = (e, value) => {
    setParents(value);
    const data = value?.sortKey;
    setNewSchedule({ ...newSchedule, parent: data, pets: '' });
    setIsError({ ...isError, parent: false, pets: false });
  };

  function isToDateGreater() {
    let startDate = newSchedule.fromDate;
    let endDate = newSchedule.toDate;

    if (!startDate || !endDate) {
      return true;
    }

    let startYear = '';
    let startMonth = '';
    let startDay = '';
    let endYear = '';
    let endMonth = '';
    let endDay = '';

    const startDateParts = startDate?.split('-');
    const endDateParts = endDate?.split('-');

    startYear = parseInt(startDateParts[2]);
    startMonth = parseInt(startDateParts[1]) - 1;
    startDay = parseInt(startDateParts[0]);
    endYear = parseInt(endDateParts[2]);
    endMonth = parseInt(endDateParts[1]) - 1;
    endDay = parseInt(endDateParts[0]);

    if (endYear > startYear) {
      return true;
      // eslint-disable-next-line no-else-return
    } else if (endYear === startYear) {
      if (endMonth > startMonth) {
        return true;
        // eslint-disable-next-line no-else-return
      } else if (endMonth === startMonth) {
        if (endDay > startDay) {
          return true;
        }
      }
    }
    return false;
  }

  function checkForEmptyValues() {
    let hasError = false;
    const updatedErrors = {};

    // Always check for empty values
    const alwaysCheck = [
      'serviceCategory',
      'serviceId',
      'fromDate',
      'fromTime',
      'toDate',
      'toTime',
      'trainer',
      'occurence',
      'room'
      // 'description'
    ];
    // eslint-disable-next-line no-restricted-syntax
    for (const key of alwaysCheck) {
      const value = newSchedule[key];
      const isEmpty =
        value === '' ||
        value == null ||
        (Array.isArray(value) && value.length === 0);
      updatedErrors[key] = isEmpty;

      if (isEmpty) {
        hasError = true;
      }
    }

    if (newSchedule.type === 'appointment') {
      const parentIsEmpty = newSchedule?.parent === '';
      const petIsEmpty = newSchedule?.pets === '';
      updatedErrors['parent'] = parentIsEmpty;
      updatedErrors['pets'] = petIsEmpty;

      if (parentIsEmpty || petIsEmpty) {
        hasError = true;
      }
    }

    if (newSchedule.fromTime && !isValidTimeFormat(newSchedule?.fromTime)) {
      updatedErrors['fromTime'] = true;
      updatedErrors['fromTimeFormat'] = true;
      hasError = true;
    }

    if (newSchedule.toTime && !isValidTimeFormat(newSchedule?.toTime)) {
      updatedErrors['toTime'] = true;
      updatedErrors['toTimeFormat'] = true;
      hasError = true;
    }

    if (
      newSchedule?.fromTime &&
      newSchedule?.toTime &&
      !isToDateGreater() &&
      isValidTimeFormat(newSchedule?.fromTime) &&
      isValidTimeFormat(newSchedule?.toTime)
    ) {
      if (
        !isEndTimeGreaterOrEqual(newSchedule?.fromTime, newSchedule?.toTime)
      ) {
        updatedErrors['toTime'] = true;
        hasError = true;
      }
    }

    if (
      newSchedule?.occurence === 'weekly' &&
      !newSchedule?.intervalDays?.length
    ) {
      // updatedErrors['intervalDays'] = true;
      hasError = true;
    }
    setIsError(updatedErrors);
    return hasError;
  }

  useEffect(() => {
    let credits = getValueFromId(
      newSchedule.parent,
      allParents,
      'sortKey',
      'credit'
    );
    if (isCreditApplied) {
      setCreditsAvailable(credits - 1);
    } else {
      setCreditsAvailable(credits || 0);
    }
  }, [newSchedule.parent, isCreditApplied]);

  const handleAddSchedule = (e, type) => {
    e.preventDefault();

    if (fromDateToDateValidation() && !checkForEmptyValues()) {
      if (type === 'credits') {
        // applying credits and deducting one credit from total
        let noOfCredits = getValueFromId(
          newSchedule.parent,
          allParents,
          'sortKey',
          'credit'
        );
        if (noOfCredits >= 1) {
          setCloseDialog({
            ...closeDialog,
            state: true,
            creditApplied: true,
            insufficientCredits: false
          });
          setIsCreditApplied(true);
        } else {
          setCloseDialog({
            ...closeDialog,
            state: true,
            creditApplied: true,
            insufficientCredits: true
          });
        }
      } else {
        let trainerArry = getValueFromId(
          newSchedule.trainer,
          trainerData,
          'sortKey',
          'availability'
        );
        // checking trainer availablility
        if (
          isOutOfTimeAvailability(
            trainerArry,
            newSchedule.fromTime,
            newSchedule.toTime
          )
        ) {
          setDialog({ ...dialog, state: true, outTime: true });
          setAllReqFulFilled(false);
        } else {
          handleFinalSubmission();
        }
      }
    }
  };
  useEffect(() => {
    if (allReqFulFilled) {
      handleFinalSubmission();
    }
  }, [allReqFulFilled]);

  function fromDateToDateValidation() {
    // if (!isScheduleEdit) {
    const fromTime = parseTime(newSchedule?.fromTime);
    const toTime = parseTime(newSchedule?.toTime);
    const fromDate = new Date(newSchedule?.fromDate);
    const toDate = new Date(newSchedule?.toDate);

    if (fromTime >= toTime) {
      setTimeShouldDiffError(true);
      return false;
    }

    if (
      !isScheduleEdit &&
      newSchedule?.fromDate !== newSchedule?.toDate &&
      newSchedule?.occurence === 'noRepeat'
    ) {
      setDateShouldDiffError(
        'Start date and End date should be the same for never occurrence.'
      );
      setTimeShouldDiffError(false);
      return false;
    }

    if (
      !isScheduleEdit &&
      (newSchedule?.occurence === 'daily' ||
        newSchedule?.occurence === 'weekly')
    ) {
      if (newSchedule?.fromDate === newSchedule?.toDate) {
        setDateShouldDiffError('End date should not be equal to start date');
        return false;
      }

      if (fromDate >= toDate) {
        setDateShouldDiffError('End date should be greater than start date');
        return false;
      }
      setTimeShouldDiffError(false);
      setDateShouldDiffError('');
      return true;
    }

    setDateShouldDiffError('');
    setTimeShouldDiffError(false);
    return true;
    // }

    // return false; // You may want to change this return value to suit your needs
  }

  function parseTime(timeString) {
    const [hour, minute] = timeString.split(' ')[0].split(':');
    const isPM = timeString.split(' ')[1] === 'PM';
    let hours = parseInt(hour, 10);

    if (isPM && hours !== 12) {
      hours += 12;
    } else if (!isPM && hours === 12) {
      hours = 0;
    }
    return new Date(1970, 0, 1, hours, parseInt(minute, 10));
  }

  useEffect(() => {
    try {
      let FromDate = convertUtcToUserTimeEdit(newSchedule?.fromDate);
      let ToDate = convertUtcToUserTimeEdit(newSchedule?.toDate);
      if (isScheduleEdit)
        setNewSchedule({
          ...newSchedule,
          fromTime: FromDate?.formattedTime,
          toTime: ToDate?.formattedTime,
          fromDate: FromDate?.formattedDate?.replaceAll('/', '-'),
          toDate: ToDate?.formattedDate?.replaceAll('/', '-')
        });
    } catch (error) {
      console.log('error', error);
    }
  }, []);
  const handleFinalSubmission = () => {
    if (isScheduleEdit) {
      setDialog({ ...dialog, state: true, edit: true });
    } else if(totalCredits>0 && newSchedule.payLater) {
      setOpenPaylaterPopUp(true)
    }else{
      createSchedule();
    }

  };
  const handleBookAppointment = (value) =>{
    if(value==='Cancel'){
      setOpenPaylaterPopUp(false)
    }else{
      setOpenPaylaterPopUp(false)
      createSchedule();
    }
  }

  // changing isBlock object -> IsBlock in trainer availability array
  function updateIsBlockProperty(objArray) {
    return objArray?.map((obj) => {
      const { isBlock, ...rest } = obj;
      // return { ...rest, IsBlock: isBlock };
      return { ...rest, isBlock: isBlock };
    });
  }
  const maxlist = allServices.filter((item) => {
    return item.sortKey === newSchedule.serviceId;
  });
  // =================Create Appointment =======================
  const createSchedule = async () => {
    let clinetchildLastNameCheck = getValueFromId(newSchedule.pets, allPets, 'sortKey', 'lastName');
    let clientParentLastNameCheck = getValueFromId(newSchedule.parent, allParents, 'sortKey', 'lastName');
    let trainerLastNameCheck = getValueFromId(newSchedule.trainer, trainerData, 'sortKey', 'lastName');
    
    setIsLoading(true);
    let categoryName = getValueFromId(newSchedule.serviceCategory, allServiceCategories, 'sortKey', 'name');
    let serviceName = getValueFromId(newSchedule.serviceId, allServices, 'sortKey', 'name');
    let trainerName = getValueFromId(newSchedule.trainer, trainerData, 'sortKey', 'firstName');
    let trainerImage = getValueFromId(newSchedule.trainer, trainerData, 'sortKey', 'profileImage');
    let trainerAvailability = updateIsBlockProperty(getValueFromId(newSchedule.trainer, trainerData, 'sortKey', 'availableSchedules'));
  
    const data = {
      timeZone : getUserTimeZone(),
      locationId: locationId,
      franchiseeId: franchiseeId,
      scheduleType: newSchedule.type,
      categoryName: categoryName,
      categoryId: newSchedule.serviceCategory,
      serviceName: serviceName,
      serviceId: newSchedule.serviceId,
      description: newSchedule.description,
      trainerId: newSchedule.trainer,
      trainerName: trainerName,
      trainerProfileImage: trainerImage,
      trainerAvailability: trainerAvailability ?? [],
      maximumCapacity: maxlist[0]?.maxCapacity,
      maximumWaitngListCapacity: maxlist[0]?.maxWaitlist,
      room: {
        id: newSchedule.roomId,
        name: newSchedule.room,
        roomLimit: 5
      },
      repeat: newSchedule.occurence,
      occurrence: await getOccurencePayload(newSchedule)
    };
  
    if (trainerLastNameCheck) {
      data.trainerLastName = trainerLastNameCheck;
    }
  
    if (isScheduleEdit) {
      data.updatedBy = userId;
    } else {
      data.createdBy = userId;
    }
  
    const createClientDetails = () => {
      const clientDetails = {
        payLater: newSchedule.payLater,
        clientParentId: newSchedule.parent,
        clientChildId: newSchedule.pets,
        bookedBy: userName,
        creditAvailable: creditsAvailable || 0,
        clientParentName: getValueFromId(newSchedule.parent, allParents, 'sortKey', 'firstName'),
        clientChildName: getValueFromId(newSchedule.pets, allPets, 'sortKey', 'firstName'),
        clientChildProfile: getValueFromId(newSchedule.pets, allPets, 'sortKey', 'profileImage'),
        clientChildBirthDate: getValueFromId(newSchedule.pets, allPets, 'sortKey', 'birthDate'),
        clientEmailId: getValueFromId(newSchedule.parent, allParents, 'sortKey', 'email'),
        clientParentPhoneNumber: getValueFromId(newSchedule.parent, allParents, 'sortKey', 'mobilePhone')
      };
  
      if (clinetchildLastNameCheck) {
        clientDetails.clientChildLastName = clinetchildLastNameCheck;
      }
  
      if (clientParentLastNameCheck) {
        clientDetails.clientParentLastName = clientParentLastNameCheck;
      }
  
      return clientDetails;
    };
  
    if (
      newSchedule.type === 'appointment' &&
      totalCredits > 0 &&
      !newSchedule.payLater
    ) {
      const clientDetails = createClientDetails();
      clientDetails.purchaseId = noOfCredit.map((item) => item.sortKey);
      data.clientDetails = [clientDetails];
    } else {
      data.clientDetails = [createClientDetails()];
    }
  
    if (
      newSchedule.type === 'enrollment' ||
      newSchedule.payLater ||
      totalCredits > 0 ||
      (!newSchedule.payLater && isCreditApplied)
    ) {
      try {
        let response = await ApiService.post(`${BASE_URL}schedule/addAppointment`, data);
        if (response.data.statusCode === 400) {
          setShowErrorMessage(true);
          setErrorMessage(response.data?.message || 'Something went wrong, Try again!');
          setIsLoading(false);
        } else if (!response.data?.error && response.statusCode === 200) {
          setIsLoading(false);
          setNotify('all');
          setCloseDialog({
            isCreated: true,
            state: true
          });
        } else {
          setShowErrorMessage(true);
          setErrorMessage(response.data?.message || 'Something went wrong, Try again!');
          setIsLoading(false);
        }
        setBtnLoading(false);
      } catch (error) {
        setIsLoading(false);
        setBtnLoading(false);
        setShowErrorMessage(true);
        setErrorMessage('Something went wrong, Try again!');
      }
    } else {
      navigate('/checkout');
    }
  };
  
  const updateSchedule = async (param) => {
    let categoryName = getValueFromId(
      newSchedule.serviceCategory,
      allServiceCategories,
      'sortKey',
      'name'
    );
    let serviceName = getValueFromId(
      newSchedule.serviceId,
      allServices,
      'sortKey',
      'name'
    );

    let trainerName = getValueFromId(
      newSchedule.trainer,
      trainerData,
      'sortKey',
      'firstName'
    );
    // let trainerLastName = getValueFromId(
    //   newSchedule.trainer,
    //   trainerData,
    //   'sortKey',
    //   'lastName'
    // );
    // let trainerName = `${trainerFistName} ${trainerLastName}`;

    let trainerImage = getValueFromId(
      newSchedule.trainer,
      trainerData,
      'sortKey',
      'profileImage'
    );

    let trainerAvailability = updateIsBlockProperty(
      getValueFromId(
        newSchedule.trainer,
        trainerData,
        'sortKey',
        'availableSchedules'
      )
    );

    let fromTime = convertTimeToUTC(newSchedule.fromTime);
    let toTime = convertTimeToUTC(newSchedule.toTime);
    let setFromtime = convertUTCToNormal(fromTime);
    let setEndtime = convertUTCToNormal(toTime);
    const data = {
      // createdBy: 'John Doe',
      locationId: locationId,
      scheduleType: newSchedule.type,
      categoryName: categoryName,
      categoryId: newSchedule.serviceCategory,
      serviceName: serviceName,
      serviceId: newSchedule.serviceId,
      classInterval: 1,
      startTime: setFromtime,
      endTime: setEndtime,
      description: newSchedule.description,
      trainerId: newSchedule.trainer,
      trainerName: trainerName,
      trainerProfileImage: trainerImage,
      trainerAvailability: trainerAvailability?.map((data) => {
        if (newSchedule?.editTypeValue === 'Reschedule') {
          return { ...data, isBlock: true };
          // eslint-disable-next-line no-else-return
        } else {
          return { ...data, isBlock: false };
        }
      }),
      room: {
        id: newSchedule.roomId,
        name: newSchedule.room,
        roomLimit: 5
      },

      trainerSlots: 30
    };

    if (isScheduleEdit) {
      // data.updatedBy= 'John Doe'
    } else {
      data.createdBy = userId;
    }

    if (newSchedule.type === 'appointment') {
      data.clientDetails = [
        {
          payLater: newSchedule.payLater,
          clientParentId: newSchedule.parent,
          clientChildId: newSchedule.pets,
          creditAvailable: creditsAvailable || 0,
          clientParentName: getValueFromId(
            newSchedule.parent,
            allParents,
            'sortKey',
            'firstName'
          ),
          clientChildName: getValueFromId(
            newSchedule.pets,
            allPets,
            'sortKey',
            'firstName'
          ),
          clientChildProfile: getValueFromId(
            newSchedule.pets,
            allPets,
            'sortKey',
            'profileImage'
          ),
          clientChildBirthDate: getValueFromId(
            newSchedule.pets,
            allPets,
            'sortKey',
            'birthDate'
          )
        }
      ];
    }

    const encodedOccurenceId = encodeURIComponent(newSchedule.id);
    if (newSchedule.payLater || newSchedule.type === 'enrollment') {
      try {
        let response = await ApiService.put(
          `${BASE_URL}schedule/updateAppointment?occurrenceId=${encodedOccurenceId}`,
          data
        );
        if (!response.data?.error || !response?.error) {
          if (param === 'notify') {
            setTimeout(() => {
              sendNotification();
            }, 500);
          }
          setShowSuccessMessage(true);
          setSuccessMessage(response?.message);
          setCloseDialog({
            isCreated: true,
            state: true
          });
        } else {
          setShowErrorMessage(true);
          setErrorMessage(
            response.data?.message ||
              'Something went wrong with update appoinment'
          );
        }

        setBtnLoading(false);
      } catch (error) {
        setBtnLoading(false);
        setShowErrorMessage(true);
        setErrorMessage('Something went wrong, Try again!');
      }
    } else {
      navigate('/checkout');
    }
  };

  const sendNotification = async () => {
    if (!notify.value) {
      setShowErrorMessage(true);
      setErrorMessage('Plz select option first');
      return;
    }

    const tranierEmail = props.trainerData.map((data) => data?.email);
    const clientEmail = props.allParents.map((data) => data?.email);
    const allEmails = [...tranierEmail, ...clientEmail];
    let payload = {
      toEmail:
        notify.value === 'trainer'
          ? tranierEmail
          : notify.value === 'client'
          ? clientEmail
          : allEmails,
      fromEmail: process.env.REACT_APP_NOTIFYFROMEMAIL
    };
    try {
      let response = await ApiService.post(
        `${BASE_URL}checkout/sendNotification`,
        payload
      );

      if (response?.statusCode === 200) {
        updateSchedule();
        // toast.success(response?.message);
        setNotifyDialog({ ...notifyDialog, state: false, notify: false });
      } else {
        setShowErrorMessage(true);
        setErrorMessage(response?.message || 'Something went Wrong');
      }
    } catch (error) {
      setShowErrorMessage(true);
      setErrorMessage('Something went Wrong');
    }
  };

  const handleCloseTypeModal = (type) => {
    if (type === 'skip') {
      setNotifyDialog({ ...notifyDialog, state: false, notify: false });
      updateSchedule();
    } else if (type === 'notify' || isScheduleEdit) {
      updateSchedule('notify');
    }
  };
  const closePopup = () => {
    setShowSuccessMessage(false);
  };
  function replaceHyphensWithSlashes(inputString) {
    if (inputString && inputString.includes('/')) {
      return inputString;
    }
    if (inputString && inputString.includes('-')) {
      return inputString.replaceAll('-', '/');
    }
    return null;
  }

  return (
    <>
      <Loader IsLoading={isLoading} />
      <div className="service-container">
        {showErrorMessage && (
          <ErrorMessage
            message={errorMessage}
            setShowMessage={setShowErrorMessage}
          />
        )}
        {showSucessMessage && (
          <SuccessMessage
            message={sucessMessage}
            setShowMessage={setSuccessMessage}
            closePopup={closePopup}
          />
        )}
        <Box
          sx={{ width: '100%' }}
          paddingBottom="20px"
          className="Main-container common-form-container"
        >
          <Box className="custom-form">
            <Grid mt={1} marginBottom="8px">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      textAlign: 'left',
                      alignItems: 'center',
                      gap: '8px',
                      marginTop: '10px'
                    }}
                  >
                    <KeyboardBackspaceIcon
                      color="primary"
                      sx={{
                        width: '30px',
                        height: '30px',
                        cursor: 'pointer'
                      }}
                      onClick={handleCloseScreen}
                    />
                    <h1
                      className="title primary-color "
                      style={{ textTransform: 'capitalize' }}
                    >
                      {`${isScheduleEdit ? 'Edit' : 'New'} ${
                        newSchedule.type === 'appointment'
                          ? 'Appointment'
                          : 'Enrollment schedule'
                      }`}
                    </h1>
                  </Box>
                </Grid>

                {!isScheduleEdit && newSchedule.type === 'appointment' ? (
                  <Grid item>
                    {newSchedule.payLater ||
                    (totalCredits > 0 && sufficiantCredits) ? (
                      <CustomButton
                        variant="contained"
                        loading={btnLoading['add_schedule']}
                        onClick={handleAddSchedule}
                        disabled={
                          roomError ||
                          trainerError ||
                          isDisable ||
                          gettingCredits
                        } // {roomError || trainerError || isDisable}
                      >
                        Book Now
                      </CustomButton>
                    ) : (
                      <Box sx={{ display: 'flex', gap: '12px' }}>
                        <CustomButton
                          variant="outline"
                          loading={btnLoading['add_schedule']}
                          onClick={handleAddSchedule}
                          disabled
                        >
                          Book Now
                        </CustomButton>
                      </Box>
                    )}
                  </Grid>
                ) : (
                  <Grid item>
                    <CustomButton
                      variant="contained"
                      loading={btnLoading['add_schedule']}
                      disabled={roomError || trainerError || isDisable} // {roomError || trainerError || isDisable}
                      onClick={handleAddSchedule}
                    >
                      {isScheduleEdit ? 'Update' : 'Save'}
                    </CustomButton>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {newSchedule.type === 'appointment' && isScheduleEdit && (
              <Grid container spacing={3} columns={12} mt={0.5} maxWidth={768}>
                <Grid item xs={12} paddingTop={'0px !important'}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 30,
                          color: '#003087'
                        },
                        '& .MuiFormControlLabel-label': {
                          color: '#000000',
                          letterSpacing: '0.3px',
                          fontWeight: 500
                        },
                        '& .MuiFormControlLabel-root': {
                          marginRight: '40px'
                        }
                      }}
                    ></RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            )}

            <Grid
              container
              maxWidth={768}
              spacing={3}
              columns={12}
              mt={newSchedule.type === 'appointment' ? 0 : 1}
            >
              <Grid
                item
                xs={12}
                sm={6}
                paddingTop={
                  newSchedule.type === 'appointment' && '16px !important'
                }
              >
                <CustomSelect
                  fullWidth
                  name="serviceCategory"
                  value={newSchedule.serviceCategory}
                  onChange={handleScheduleInfo}
                  label="SERVICE CATEGORY *"
                  disabled={newSchedule.editTypeValue !== 'Reschedule'}
                  error={isError.serviceCategory}
                  helperText={
                    isError.serviceCategory && 'Service category is required'
                  }
                  options={allServiceCategories?.map((item) => ({
                    label: item.name,
                    value: item.sortKey
                  }))}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                paddingTop={
                  newSchedule.type === 'appointment' && '16px !important'
                }
              >
                <CustomSelect
                  fullWidth
                  name="serviceId"
                  value={newSchedule.serviceId}
                  onChange={handleScheduleInfo}
                  label="SERVICE NAME *"
                  disabled={
                    !newSchedule.serviceCategory ||
                    newSchedule.editTypeValue !== 'Reschedule'
                  }
                  error={isError.serviceId}
                  helperText={isError.serviceId && 'Service name is required'}
                  options={allServices?.map((item) => ({
                    label: item.name,
                    value: item.sortKey
                  }))}
                />
              </Grid>
            </Grid>

            {newSchedule.type === 'appointment' && (
              <Grid
                container
                maxWidth={768}
                spacing={3}
                columns={12}
                mt={newSchedule.type === 'appointment' ? 0 : 1}
              >
                <Grid item xs={12} sm={6}>
                  {isScheduleEdit ? (
                    <CustomSelect
                      fullWidth
                      name="parent"
                      value={newSchedule.parent}
                      onChange={handleScheduleInfo}
                      label="SELECT CLIENT *"
                      disabled={
                        newSchedule.editTypeValue !== 'Reschedule' ||
                        isScheduleEdit
                      }
                      error={isError.parent}
                      helperText={isError.parent && 'Parent is required'}
                      options={allParents?.map((item) => ({
                        label: `${item.firstName} ${item.lastName}`,
                        value: item.sortKey
                      }))}
                    />
                  ) : (
                    <>
                      <Autocomplete
                        value={parents}
                        onChange={handleClientInfo}
                        className="status-field field-label-css"
                        options={allParents ?? []}
                        disabled={
                          newSchedule.editTypeValue !== 'Reschedule' ||
                          isScheduleEdit
                        }
                        error={isError.parent}
                        helperText={isError.parent && 'Client is required'}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.sortKey}>
                              {option.firstName} {option.lastName}
                            </li>
                          );
                        }}
                        getOptionLabel={(option) =>
                          `${option.firstName} ${option.lastName}`
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: false,
                              type: 'search'
                            }}
                            label={'SELECT CLIENT *'}
                            variant="outlined"
                          />
                        )}
                      />
                      {isError.parent && (
                        <FormHelperText>Client is required</FormHelperText>
                      )}
                    </>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CustomSelect
                    fullWidth
                    name="pets"
                    value={newSchedule.pets}
                    onChange={handleScheduleInfo}
                    label="SELECT DOG *"
                    disabled={
                      !newSchedule.parent ||
                      newSchedule.editTypeValue !== 'Reschedule' ||
                      isScheduleEdit
                    }
                    error={isError.pets}
                    helperText={isError.pets && 'Dog is required'}
                    options={allPets?.map((item) => ({
                      label: `${item?.firstName} ${item?.lastName}`,
                      value: item.sortKey
                    }))}
                  />
                </Grid>
              </Grid>
            )}

            <Grid container maxWidth={768} spacing={3} columns={12} mt={1}>
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  fullWidth
                  name="trainer"
                  value={newSchedule.trainer}
                  onChange={handleScheduleInfo}
                  label="TRAINER *"
                  disabled={newSchedule.editTypeValue !== 'Reschedule'}
                  error={isError.trainer}
                  helperText={
                    (isError.trainer && 'Trainer is required') ||
                    (trainerError &&
                      'Trainer is not available for this service.') ||
                    (!availabilityCheck?.staffAvailable &&
                      'This trainer is not available for the selected time. Please choose a different trainer or time.') ||
                    (availabilityCheck?.staffTimeoff && (
                      <p>
                        Staff is not available for this series.{' '}
                        <Button
                          sx={{
                            margin: 0,
                            padding: 0,
                            color: '#d13a00 !important'
                          }}
                          onClick={() => setShowTable(true)}
                        >
                          {' '}
                          Learn more.
                        </Button>
                      </p>
                    )) ||
                    (!availabilityCheck?.staffAvailablityHours &&
                      'The selected time does not match the trainer availability. You can still book this schedule.')
                  }
                  options={trainerData?.map((item) => ({
                    label: `${item.firstName || ''} ${item.lastName || ''}`,
                    value: item.sortKey
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  fullWidth
                  name="room"
                  value={
                    newSchedule.roomId
                      ? newSchedule.room + '|' + newSchedule.roomId
                      : ''
                  }
                  // value={newSchedule.room}
                  onChange={handleScheduleInfo}
                  label="ROOM *"
                  disabled={newSchedule.editTypeValue !== 'Reschedule'}
                  error={isError.room}
                  helperText={
                    (isError.room && 'Room is required') ||
                    (roomError && 'Room is not available for this service') ||
                    (!availabilityCheck?.roomAvailble &&
                      'This room is not available for the selected time. Please choose a different room or time.')
                  }
                  options={roomData?.map((item) => ({
                    label: item.name,
                    value: item.name + '|' + item.sortKey
                  }))}
                />
              </Grid>
            </Grid>

            <Grid container maxWidth={768} spacing={3} columns={12} mt={1}>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <CustomDatePickerAddSchedule
                      label="Date *"
                      name="fromDate"
                      fullWidth
                      disablePast
                      inputFormat="MM/dd/yyyy"
                      disabled={
                        newSchedule.editTypeValue !== 'Reschedule' ||
                        isScheduleEdit
                      }
                      value={replaceHyphensWithSlashes(newSchedule?.fromDate)}
                      onChange={handleScheduleInfo}
                      error={isError.fromDate}
                      helperText={
                        isError.fromDate &&
                        (newSchedule.fromDate > newSchedule.toDate
                          ? 'From date must be smaller than To date'
                          : 'From date is required')
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl
                      pt={2}
                      variant="outlined"
                      fullWidth
                      sx={{
                        width: '100%'
                      }}
                    >
                      <InputLabel className="text-field-styling">
                        Start Time *
                      </InputLabel>
                      <Select
                        value={newSchedule?.fromTime}
                        name="fromTime"
                        onChange={handleScheduleInfoTime}
                        disabled={newSchedule.editTypeValue !== 'Reschedule'}
                        label="Start Time *"
                        error={isError.fromTime || isError.fromTimeFormat}
                        helperText={
                          isError.fromTime &&
                          (isError.fromTimeFormat
                            ? 'Plese enter correct format e.g (12:00 AM/PM)'
                            : 'Time is required')
                        }
                        className="status-field field-label-css"
                        IconComponent={KeyboardArrowDownIcon}
                        MenuProps={{
                          PaperProps: { style: { maxHeight: 300 } }
                        }}
                      >
                        {timeSlots.map((item, index) => (
                          <MenuItem key={index} id={index} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      pt={2}
                      variant="outlined"
                      fullWidth
                      sx={{
                        width: '100%'
                      }}
                    >
                      <InputLabel className="text-field-styling">
                        End Time *
                      </InputLabel>
                      <Select
                        value={newSchedule?.toTime}
                        name="toTime"
                        onChange={handleScheduleInfoTime}
                        label="End Time *"
                        disabled={newSchedule?.editTypeValue !== 'Reschedule'}
                        disableClearable
                        error={isError.toTime || isError.toTimeFormat}
                        helperText={
                          isError.toTime || newSchedule.toTime === ''
                            ? 'Time is required'
                            : ''
                        }
                        className="status-field field-label-css"
                        IconComponent={KeyboardArrowDownIcon}
                        MenuProps={{
                          PaperProps: { style: { maxHeight: 300 } }
                        }}
                      >
                        {timeSlots.map((item, index) => (
                          <MenuItem key={index} id={index} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {TimeShouldDiffError && (
                        <FormHelperText>
                          End time must be greater
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container maxWidth={768} spacing={3} columns={12} mt={1}>
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  fullWidth
                  name="occurence"
                  value={newSchedule.occurence}
                  onChange={handleScheduleInfo}
                  disabled={
                    newSchedule.editTypeValue !== 'Reschedule' || isScheduleEdit
                  }
                  label="OCCURRENCE"
                  error={isError.occurence}
                  helperText={isError.occurence && 'Occurrence is required'}
                  options={
                    newSchedule.type === 'appointment'
                      ? [
                          {
                            label: 'Once',
                            value: 'noRepeat'
                          },
                          {
                            label: 'Daily',
                            value: 'daily'
                          },
                          {
                            label: 'Weekly',
                            value: 'weekly'
                          }
                        ]
                      : [
                          {
                            label: 'Once',
                            value: 'noRepeat'
                          },
                          {
                            label: 'Daily',
                            value: 'daily'
                          },
                          {
                            label: 'Weekly',
                            value: 'weekly'
                          }
                        ]
                  }
                />
              </Grid>
              {newSchedule.occurence !== 'noRepeat' ? (
                <Grid item xs={12} sm={6}>
                  <CustomDatePickerAddSchedule
                    label="End Date"
                    name="toDate"
                    fullWidth
                    disablePast
                    disabled={
                      newSchedule?.fromDate == null ||
                      newSchedule.editTypeValue !== 'Reschedule' ||
                      isScheduleEdit
                    }
                    value={replaceHyphensWithSlashes(newSchedule?.toDate)}
                    // minDate={newSchedule?.fromDate && newSchedule?.fromDate}
                    inputFormat="MM/dd/yyyy"
                    onChange={handleScheduleInfo}
                    error={isError.toDate}
                    helperText={
                      isError.toDate && DateShouldDiffError
                        ? !isToDateGreater() || !isScheduleEdit
                          ? 'To date must be greater than From date'
                          : 'To date is required'
                        : DateShouldDiffError
                    }
                  />
                </Grid>
              ) : null}
            </Grid>

            {newSchedule.occurence !== 'noRepeat' ? (
              <Grid container maxWidth={768} spacing={3} columns={12} mt={1}>
                <Grid item xs={12} sm={12}>
                  {newSchedule.occurence !== 'daily' && (
                    <div className="days-of-week-container">
                      {daysOfWeek.map(({ label, value }) => (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                        <div
                          key={value}
                          className={`day-circle vv-${value} ${
                            newSchedule.occurence === 'everyWeek'
                              ? value === 'su'
                                ? ''
                                : 'selected'
                              : newSchedule.intervalDays.includes(value)
                              ? 'selected'
                              : ''
                          }`}
                          onClick={() =>
                            !isScheduleEdit &&
                            newSchedule.occurrence !== 'everyWeek' &&
                            handleToggleDay(value)
                          }
                        >
                          {label}
                        </div>
                      ))}
                    </div>
                  )}
                </Grid>
              </Grid>
            ) : null}
            <Grid container spacing={3} columns={12} mt={0.5} maxWidth={768}>
              <Grid
                item
                xs={12}
                sm={12}
                display="flex"
                justifyContent="flex-start"
                paddingRight="16px"
                paddingTop={'0px !important'}
              >
                {newSchedule?.occurence === 'weekly' &&
                  !newSchedule?.intervalDays?.length && (
                    <Typography variant="body2" fontSize={14} color="#d13a00">
                      Select atleast one day
                    </Typography>
                  )}{' '}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                display="flex"
                justifyContent="flex-start"
                paddingRight="16px"
                paddingTop={'0px !important'}
              >
                {!isHoliday && (
                  <Typography variant="body2" fontSize={14} color="#d13a00">
                    One or more selected dates occurs during a scheduled Store
                    Holiday. You can still book this occurrence or series if you
                    would like.
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                display="flex"
                justifyContent="flex-start"
                paddingRight="16px"
                paddingTop={'0px !important'}
              >
                {showOperatingHoursPopup && (
                  <Typography variant="body2" fontSize={14} color="#d13a00">
                    The selected time slot is out of the store's operating
                    hours/on a store holiday. You can still book this schedule.
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              maxWidth={768}
              spacing={3}
              columns={12}
              mt={newSchedule.occurence === 'daily' ? -2 : 0}
            >
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  multiline
                  rows="6"
                  type="text"
                  name="description"
                  // disabled={newSchedule.editTypeValue !== 'Reschedule'}
                  value={newSchedule.description}
                  onChange={handleScheduleInfo}
                  disabled
                  label="Description"
                  // error={isError.description}
                  // helperText={isError.description && 'Description is required'}
                />
              </Grid>
            </Grid>

            {newSchedule.type === 'enrollment' && isScheduleEdit && (
              <Grid container spacing={3} columns={12} mt={0.5} maxWidth={768}>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 30,
                          color: '#003087'
                        },
                        '& .MuiFormControlLabel-label': {
                          color: '#000000',
                          letterSpacing: '0.3px',
                          fontWeight: 500
                        },
                        '& .MuiFormControlLabel-root': {
                          marginRight: '40px'
                        }
                      }}
                    ></RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            )}

            {newSchedule.type === 'appointment' && !isScheduleEdit && (
              <>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  mt={0}
                  maxWidth={768}
                  className="fullWidth-btn"
                >
                  <Grid item xs={12} sm={6} paddingTop={'10px !important'}>
                    <FormControlLabel
                      className="checkbox-pay-leter"
                      control={
                        <Checkbox
                          defaultChecked={newSchedule.payLater}
                          color="default"
                          value={newSchedule.payLater}
                          onChange={(e) =>
                            handleScheduleInfo('payLater', e.target.checked)
                          }
                          sx={{
                            color: '#003087',
                            '&.Mui-checked': {
                              color: '#003087'
                            }
                          }}
                        />
                      }
                      label={
                        <span style={{ fontWeight: 'bold', color: 'black' }}>
                          Pay Later
                        </span>
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    justifyContent="flex-end"
                    paddingRight="16px"
                    paddingTop={'10px !important'}
                  >
                    {showCredit && (
                      <Typography variant="body2" color="#003087">
                        {totalCredits > 0 ? (
                          <Typography
                            variant="body2"
                            fontWeight={800}
                            fontSize={16}
                            color="green"
                          >
                            Credit Available: {totalCredits}{' '}
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            fontWeight={800}
                            fontSize={16}
                            color="red"
                          >
                            No credits available for this service{' '}
                          </Typography>
                        )}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Box>

        <Dialog
          className="enrollClassDialog"
          open={showTable}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setShowTable(false)}
          aria-describedby="alert-dialog-slide-description "
        >
          <DialogContent className="custom-scroll -team-container-white-box">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%'
              }}
              mb={2}
            >
              <Typography className={'-dialogPopupHeader'}>
                {' '}
                Trainer Time Off
              </Typography>
              <CancelOutlinedIcon
                color="error"
                sx={{
                  width: '32px',
                  height: '32px',
                  cursor: 'pointer'
                }}
                onClick={() => setShowTable(false)}
              />
            </Box>
            <Card>
              <TrainerAvailabilityTable
                TableHeading={[
                  'Start Date',
                  'End Date',
                  'Start Time',
                  'End Time',
                  'Availability'
                ]}
                data={availabilityCheck?.staffTimeoffData}
              />
            </Card>
          </DialogContent>
        </Dialog>

        <Dialog
          open={closeDialog.state}
          TransitionComponent={Transition}
          keepMounted
          onClose={() =>
            handleDialog(closeDialog.creditApplied ? 'Continue' : 'Cancel')
          }
          aria-describedby="alert-dialog-slide-description"
        >
          {closeDialog.creditApplied ? null : (
            <DialogTitle
              className={`alert-title align-center ${
                closeDialog.isCreated && newSchedule.type === 'appointment'
                  ? 'success-class'
                  : 'default-class'
              }`}
            >
              {closeDialog.isCreated
                ? newSchedule.type === 'appointment'
                  ? 'Success!'
                  : 'Success!'
                : 'Save Changes'}
            </DialogTitle>
          )}
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="alert-decription align-center"
            >
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                {!closeDialog.isCreated ? (
                  <>
                    {closeDialog.creditApplied ? (
                      <Typography className="title-text-sub">
                        {closeDialog.insufficientCredits
                          ? 'Insufficient Credits'
                          : 'Credits Successfully Applied!'}
                      </Typography>
                    ) : (
                      <Typography className="title-text-sub">
                        You have unsaved changes, Do you want to <br /> save
                        them?
                      </Typography>
                    )}
                  </>
                ) : (
                  // className={`${newSchedule.type === 'appointment' ? 'title-text-sub-success' : 'align-center title-text-sub'}`}
                  <Typography
                    className={`${
                      newSchedule.type === 'enrollment'
                        ? 'title-text-sub-success'
                        : 'align-center title-text-sub'
                    }`}
                  >
                    {newSchedule.type === 'appointment' && !isScheduleEdit
                      ? 'The appointment has been scheduled successfully.'
                      : 'The enrollment schedule has been created successfully. You can now enroll dogs in any of the scheduled sessions.'}
                  </Typography>
                )}
              </Box>
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions className="alert-button-control">
            {closeDialog.creditApplied ? (
              <Button
                className="font-17 text-black no-hover"
                onClick={() => handleDialog('Continue')}
              >
                Close
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => handleDialog('Discard')}
                  className={`font-17 text-black no-hover ${
                    closeDialog.isCreated ? 'close-class' : 'default-class'
                  }`}
                >
                  {closeDialog.isCreated ? 'Close' : 'Discard Changes'}
                </Button>

                {!closeDialog.isCreated && (
                  <Divider className="vertical-divider" />
                )}
                {!closeDialog.isCreated && (
                  <Button
                    className="font-17 no-hover"
                    onClick={() => handleDialog('Continue')}
                  >
                    Go back to Editing
                  </Button>
                )}
              </>
            )}
          </DialogActions>
        </Dialog>

        <Dialog
          open={openVaccinationDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleCloseConfirm()}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="alert-decription align-center"
            >
              <Box display="flex" justifyContent="center" direction="column">
                <Typography className="title-text-sub">
                  {vaccinationDetails.bordetellaAlert}

                  <Typography className="title-text-sub">
                    {vaccinationDetails.dhppExpiration}
                  </Typography>

                  <Typography className="title-text-sub">
                    {vaccinationDetails.rabiesAlert}
                  </Typography>
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>

          <Divider />

          <DialogActions className="alert-button-control">
            <Button
              className="font-17 default-class no-hover"
              onClick={() => handleCloseConfirm()}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
        open={openPaylaterPopUp}
        TransitionComponent={Transition}
        keepMounted
        // onClose={() => handleConfirmClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-scheduling">
              The client already has available credits for this service.
                <Typography className="title-text-scheduling">
                Are you sure you want to choose the "Pay Later" option?
                </Typography>
                
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 cancel-btn-scheduling no-hover"
            onClick={() => handleBookAppointment('Cancel')}
          >
            No
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleBookAppointment('Save')}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
        <CalenderDialogs
          dialog={notifyDialog}
          setDialog={setNotifyDialog}
          onClose={handleCloseTypeModal}
          notify={notify}
          setNotify={setNotify}
        />
      </div>
    </>
  );
}
