import {
  Button,
  Box,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
} from '@mui/material';
import DrawStaffTable from './DrawStaffTable';
import React, { useEffect, useState, useImperativeHandle } from 'react';
import Loader from '../../../components/Loader';
import { getLocalData } from '../../../utils';
// import { toast } from 'react-toastify';
import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
import { ApiService } from 'src/services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function StaffListAPI({ ref, showScreen, setShowScreen }) {
  // console.log(props);
  const [staffList, setStaff] = useState([]);
  const [allTask, setAllTask] = useState([]);
  const [dataCount, setdataCount] = useState(0);
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  let [filters, setSidebarFilters] = useState({
    // isActive: null,
    status: 1,
    role: null,
    team: null,
    // filterLocationId: [],
    payRateType: null,
    startDate: null,
    city: null,
    endDate: null,
    clockedIn: null
    // notClockInTime: null
  });

  const userId = getLocalData('userId');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);

  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const isPrimaryOwner = getLocalData('isPrimaryOwner');
  // const locationId = getLocalData('locationId');
  // const locationId = setFullLocationId(getLocalData('locationId'));
  const locationId = getLocalData('locationId');
  const [selectedStaffLists, setSelectedStaffLists] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // check updated or saved
  const [isUpdated, setIsUpdated] = useState(false);
  // console.log(isFranchisor, locationId, isPrimaryOwner);

  // gets call the method to get the data
  useEffect(() => {
    // Not call the get franchisee method again
    getStaff();
    // Not call the get all the tasks method again
    getAllTask();
  }, [JSON.stringify(filters)]);

  // Handle the event from child to parent. It is being used for refresh the data after edit or delete
  const refreshStaffData = () => {
    // When popup is open then not call the loader
    // setIsPopup(true);
    // Not call the get franchisee method again
    getStaff();
  };
  // Getting hooks from parent to child
  useImperativeHandle(ref, () => ({
    refreshStaffData: () => {
      // Not call the get franchisee method again
      getStaff();
    }
  }));

  const refreshTaskData = () => {
    getAllTask();
  };
  // Getting hooks from parent to child

  useImperativeHandle(ref, () => ({
    refreshTaskData: () => {
      // Not call the get franchisee method again
      getAllTask();
    }
  }));

  // methos to ferech the data

  // Get Franchise method to get the list of franchisee
  const getStaff = async () => {
    // Now show the loader
    setIsLoading(true);
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (
        filtersParams[key] === -1 ||
        filtersParams[key] === null ||
        filtersParams[key] === '' ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === undefined
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

    let url;

    const encodedLocationId = encodeURIComponent(locationId);
    if (
      isFranchisor === 'false' &&
      isPrimaryOwner === 'true' &&
      encodedLocationId
    ) {
      url = `${process.env.REACT_APP_API_END_POINT}staff?limit=1000&locationId=${encodedLocationId}&${queryString}`;
    } else if (
      isFranchisor === 'false' &&
      isPrimaryOwner === 'false' &&
      encodedLocationId
    ) {
      url = `${process.env.REACT_APP_API_END_POINT}staff?limit=1000&locationId=${encodedLocationId}&${queryString}`;
    } else {
      url = `${process.env.REACT_APP_API_END_POINT}staff?limit=1000&${queryString}`;
    }
    try {
      let response = await ApiService.get(`${url}`);
      if (!response?.error) {
        setStaff(response?.data.Items);
        setdataCount(response?.data.Count);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  // Get All the tasks method
  const getAllTask = async () => {
    let url;
    let encodedLocationId = encodeURIComponent(locationId);
    if (isFranchisor === 'false' && isPrimaryOwner === 'true' && locationId) {
      url = `${process.env.REACT_APP_API_END_POINT}staff/getAllActiveTask?limit=1000&locationId=${encodedLocationId}`;
    } else if (
      isFranchisor === 'false' &&
      isPrimaryOwner === 'false' &&
      locationId
    ) {
      url = `${process.env.REACT_APP_API_END_POINT}staff/getAllActiveTask?limit=1000&locationId=${encodedLocationId}`;
    } else {
      url = `${process.env.REACT_APP_API_END_POINT}staff/getAllActiveTask?limit=1000`;
    }

    try {
      let response = await ApiService.get(url);
      if (!response?.error) {
        setAllTask(response?.data.Items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBulkAdd = async () => {
    setIsUpdated(false);
    setOpen(true);
  };

  const confirmBulkUpdate = async () => {
    setOpen(false);
    setBtnLoading(true);
    // show loader
    setIsLoading(true);
    const data = {
      status: selectedStatus,
      updatedBy: userId,
      staffIds: selectedStaffLists,
      locationId: locationId
    };
    try {
      const response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}staff/updateBulk`,
        data
      );

      if (!response?.error) {
        setSelectedStatus('');
        setSelectedStaffLists([]);
        setIsUpdated(true);
        setOpen(true);
      } else {
        setShowMessage(true);
        setErrorMessage(
          response?.message || 'Something went wrong with update staff status'
        );
      }
      setIsLoading(false);
      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      setIsLoading(false);
      setShowMessage(true);
      setErrorMessage('Something went wrong, Try again!');
    }
  };

  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      confirmBulkUpdate();
    } else if (val === 'Discard') {
      setOpen(false);
      if (isUpdated) {
        getStaff();
        setIsUpdated(false);
      }
    }
  };

  return (
    <Grid className="card-table-main" xs={12}>
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      <Loader IsLoading={isLoading} />
      {/* If data count is greater the 0 then pass the value into the parameter  refreshFranchiseeData={ refreshFranchiseeData } */}
      {/* {dataCount > 0 ? */}
      <DrawStaffTable
        staffLists={staffList}
        sideBarfilters={filters}
        setSidebarFilters={setSidebarFilters}
        // getCities={franchiseecity}
        dataCount={dataCount}
        refreshStaffData={refreshStaffData}
        allTask={allTask}
        refreshTaskData={refreshTaskData}
        showScreen={showScreen}
        setShowScreen={setShowScreen}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        handleBulkAdd={handleBulkAdd}
        btnLoading={btnLoading}
        selectedStaffLists={selectedStaffLists}
        setSelectedStaffLists={setSelectedStaffLists}
        open={open}
        setOpen={setOpen}
        getStaff={getStaff}
      />

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? '' : 'Activate/Deactivate Selected Items'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  Are you sure you want to mark Activate/Deactivate,
                  <Typography className="title-text-sub">
                    all the selected items?
                  </Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  All the selected staffs have been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Cancel'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default React.forwardRef(StaffListAPI);
