import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { getLocalData } from '../../../utils';
import GlobalData from 'src/global';
import { Helmet } from 'react-helmet-async';
import { ApiService } from '../../../services/ApiService';

import './style.css';
import '../GiftCards/style.css';
import SalesDetail from './SalesDetails/SalesDetail';
import { filtersQuery } from '../../../utils/index';

import SearchIcon from '@mui/icons-material/Search';
import { ReturnSuccess } from './SalesDetails/ReturnSuccess';
import { ReturnFailed } from './ReturnFailed';
import { useNavigate, useParams } from 'react-router-dom';
import { SalesHistoryFilter } from 'src/components/Filters/SalesHistoryFilters';
import { SalesHistoryTable } from './SalesHistoryTable';
import moment from 'moment';
import Loader from 'src/components/Loader';
import Header from 'src/components/Header';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';

const SalesHistoryMain = () => {
  const navigate = useNavigate()
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log(setLoading);
  let [filters, setFilters] = useState({
    startDate: '',
    petId: '',
    paymentMethod: '',
    endDate: ''
  });
  const [selectedClient, setSelectedClient] = useState(null);
  const [selected, setSelected] = useState(null);
  const [showScreen, setShowScreen] = useState(0);
  const [clientList, setClientList] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [receiptData, setReceiptData] = useState(null);
  const [refundOrderId, setRefundOrderId] = useState('');
  const [allOrderHistoryData, setAllOrderHistoryData] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const locationId = getLocalData('locationId');
  const { id } = useParams();

  useEffect(() => {
    sessionStorage.removeItem('paramKey');
  }, []);

  useEffect(() => {
    if (clientList?.length) {
      const newClient = clientList?.filter(
        (item) => item?.sortKey === `#CLIENT#${id}`
      );
      searchClients('', newClient[0]);
    }
  }, [clientList]);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     return (event.returnValue = 'Are you sure you want to leave this page?');
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  let elm = document.getElementsByClassName('page-header-text')[0];
  if (elm) elm.innerHTML = '';
  const title = GlobalData[0]?.title;

  const dateFormate = (date) => {
    let utc;
    utc = date ? moment?.utc(date).format('MMM DD, YYYY') : '';
    return utc;
  };

  const getSalesHistory = async (ClientId) => {
    setIsLoading(true);
    let queryString = filtersQuery(filters);
    let filtersParams = queryString ? `&${queryString}` : '';
    let encodedLocationId = encodeURIComponent(locationId);

    let encodedClientId = encodeURIComponent(ClientId);

    try{
      let response = await ApiService.get(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}checkout/getOrderHistory?locationId=${encodedLocationId}${filtersParams}&clientId=${encodedClientId}`
    );
    let Item =
      response && response?.data && response?.data?.Items
        ? response?.data?.Items
        : [];
    const result = Item.map((obj) => {
      const {
        sortKey,
        sqaureOrderState,
        orderDate,
        paymentSourceType,
        totalSquareDiscountAmountInDollar,
        petName,
        petId,
        dateOfPurchase,
        totalSquareAmountWithTaxInDollar,
        refundStatus,
        refundAmount
      } = obj;

      return {
        partitionKey: petId,
        sortKey,
        Date: orderDate
          ? dateFormate(orderDate)
          : dateOfPurchase && dateFormate(dateOfPurchase),
        'Payment Status': sqaureOrderState || '-',
        'Payment Method': paymentSourceType || '-',
        'Refund Status': refundStatus ?? '-',
        'Refund Amount':
          (refundAmount && `$${parseFloat(refundAmount)?.toFixed(2)}`) ?? '-',
        Dog: petName || '-',
        'Total Amount': totalSquareAmountWithTaxInDollar
          ? `$${parseFloat(totalSquareAmountWithTaxInDollar)?.toFixed(2)}`
          : '$0.00',
        Discount: `$${parseFloat(
          totalSquareDiscountAmountInDollar ?? 0
        )?.toFixed(2)}`
      };
    });
    setAllOrderHistoryData(Item);
    setTableData(result);
    setIsLoading(false);
    }catch(error){
      setIsLoading(false);
      setShowMessage(true);
      setErrorMessage(error?.message || "Something went wrong.")
      console.error(error)
    }
  }
  const getAllClients = async () => {
    setIsLoading(true)
    let encodedLocationId = encodeURIComponent(locationId);
    try{
      let response = await ApiService.get(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}client/getAllClients?locationId=${encodedLocationId}`
      );
      let Item =
        response && response?.data && response?.data?.Items
          ? response?.data?.Items
          : [];
      setClientList(Item);
      setIsLoading(false)
    }catch(error){
      setIsLoading(false)
      console.error(error)
    }
  };

  const searchClients = async (event, newValue) => {
    if (newValue) {
        navigate('/sales-history')
        setSelectedClient(newValue);
        getSalesHistory(newValue.sortKey);
    }
  };

  useEffect(() => {
    if (filters && selectedClient) {
      getSalesHistory(selectedClient.sortKey);
    }
  }, [filters, showScreen]);
  useEffect(() => {
    getAllClients();
  }, []);

  const handleOnClick = (data) => {
    if (data[2] === 'Detail') {
      const list = [...tableData];
      const selectedItem = list.filter((item) => item.sortKey === data[1]);
      setSelectedOrderId(data[1]);
      setSelected(selectedItem[0]);
      setShowScreen(1);
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      {(() => {
        switch (showScreen) {
          case 1:
            return (
              <SalesDetail
                detail={selected}
                setActive={setShowScreen}
                selectedOrderId={selectedOrderId}
                setRefundOrderId={setRefundOrderId}
              />
            );
          case 2:
            return <ReturnFailed setActive={setShowScreen} />;
          case 3:
            return (
              <ReturnSuccess
                setActive={setShowScreen}
                receiptData={receiptData}
                setReceiptData={setReceiptData}
                refundOrderId={refundOrderId}
                selectedClient={selectedClient}
              />
            );
          default:
            return (
              <>
                <Helmet>
                  <title>Sales History | {title}</title>
                </Helmet>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'start',
                    flexDirection: 'column',
                    gap: '20px',
                    ml: '25px'
                  }}
                >
                  <Header title="Sales History" />

                  <Box className="clientSearch searchBar" mt={2}>
                    <Autocomplete
                      style={{
                        width: '400px'
                      }}
                      value={selectedClient}
                      onChange={searchClients}
                      className="status-field field-label-css"
                      options={clientList ?? []}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.sortKey}>
                            {option.firstName} {option.lastName}
                          </li>
                        );
                      }}
                      getOptionLabel={(option) =>
                        `${option?.firstName} ${option?.lastName}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: !params.inputProps.value &&
                              selectedClient === null && <SearchIcon />,
                            type: 'search'
                          }}
                          label={'Search Client'}
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                </Box>
                {selectedClient && (
                  <SalesHistoryTable
                    allOrderHistoryData={allOrderHistoryData}
                    loading={loading}
                    filterCompoenent={
                      <SalesHistoryFilter
                        setFilters={setFilters}
                        filters={filters}
                        selectedClient={selectedClient}
                      />
                    }
                    data={tableData || []}
                    isEdit={false}
                    isFilter
                    isSearch
                    handleOnClick={handleOnClick}
                  />
                )}
              </>
            );
        }
      })()}
    </>
  );
};

export default SalesHistoryMain;