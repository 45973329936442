import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  Typography
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';
import { ApiService } from '../../services/ApiService';
import { CalenderdateFormat } from '../../utils';
import DynamicDateField from '../DynamicDateField';

const paymentMethodOptions = [
  { label: 'Cash', value: 'cash' },
  { label: 'Gift Card', value: 'gift_card' },
  { label: 'Credit Card', value: 'card' }
];
export const SalesHistoryFilter = ({ setFilters, filters, selectedClient }) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));
  // const locationId = setFullLocationId(getLocalData("locationId"));
  const classes = useStyles();

  const [petList, setPetList] = useState([]); // getting dynamic list
  console.log(petList, 'petList');
  //   const [pricingDataValue, setPricingDataValue] = useState([]); // getting dynamic list

  const [openFilter, setOpenFilter] = useState({
    date: false,
    pets: false,
    paymentMethod: false
  });

  const getAllPets = async (selectedClientId) => {
    let encodedClientId = encodeURIComponent(selectedClientId);
    let response = await ApiService.get(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}client/getPetList?clientId=${encodedClientId}`
    );
    let Item =
      response && response.data && response.data.Items
        ? response.data.Items
        : [];
    console.log(Item, 'result');
    setPetList(Item);
  };

  useEffect(() => {
    // get all categories
    getAllPets(selectedClient?.sortKey);
  }, [selectedClient]);
console.log(filters,'selectedpet22222');
  const changeType = (value) => {
    setFilters((prevState) => ({ ...prevState, petId: value }));
  };

  const ChangeValue = (value) => {
    setFilters((prevState) => ({ ...prevState, paymentMethod: value }));
  };
  const changeStartDate = (value) => {
    setFilters((prevState) => ({
      ...prevState,
      startDate: CalenderdateFormat(value)
    }));
  };
  const changeEndDate = (value) => {
    setFilters((prevState) => ({
      ...prevState,
      endDate: CalenderdateFormat(value)
    }));
  };

  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      date: field === 'date' ? !prevState.date : false,
      // category: field === 'category' ? !prevState.category : false,

      paymentMethod:
        field === 'paymentMethod' ? !prevState.paymentMethod : false,
      pets: field === 'pets' ? !prevState.pets : false
    }));
  };

  const clearFilters = () => {
    setFilters({
      petId: '',
      startDate: '',
      paymentMethod: '',
      endDate: ''
    });

    setOpenFilter({
      date: false,
      paymentMethod: false,
      pets: false
    });
  };

  return (
    <>
      <Card className="filter-content">
        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>
            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* Category filter */}
        <Grid item xs={12} onClick={() => handleOpenFilter('pets')} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.pets && classes.closeY,
                    openFilter.pets && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Pets</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openFilter.pets && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Pets</InputLabel>
              <Select
                onChange={(event) => changeType(event.target.value)}
                value={filters.petId ?? ''}
                sx={{
                  width: '100%'
                }}
                label="Value"
                className="status-field field-label-css"
              >
                {/* <MenuItem key={0} id={0} value="">
                  None
                </MenuItem> */}
                {petList.length > 0 &&
                  petList.map((item, index) => (
                    <MenuItem key={index} value={item.sortKey}>
                      {item.firstName} {item.lastName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}
        {/* //price  */}
        <Grid
          item
          xs={12}
          onClick={() => handleOpenFilter('paymentMethod')}
          pt={0.5}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.paymentMethod && classes.closeY,
                    openFilter.paymentMethod && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Payment method</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openFilter.paymentMethod && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Payment method</InputLabel>
              <Select
                onChange={(event) => ChangeValue(event.target.value)}
                value={filters.paymentMethod ?? ''}
                sx={{
                  width: '100%'
                }}
                label="Payment method"
                className="status-field field-label-css"
              >
                {/* <MenuItem key={0} id={0} value="">
                  None
                </MenuItem> */}
               
                {paymentMethodOptions.length > 0 &&
                  paymentMethodOptions.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}
        {/* expired */}
        <Grid item xs={12} onClick={() => handleOpenFilter('date')} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.date && classes.closeY,
                    openFilter.date && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Date</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openFilter.date && (
          <>
            <DynamicDateField
              label="Start Date"
              name="startDate"
              setDateValue={changeStartDate}
              dateValue={filters?.startDate}

              maxDate={filters?.endDate && filters?.endDate}
            />
            <DynamicDateField
              label="End Date"
              name="endDate"
              setDateValue={changeEndDate}
              dateValue={filters?.endDate}
              minDate={filters?.startDate && filters?.startDate}
            />
          </>
        )}
      </Card>
    </>
  );
};
