import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  CardHeader,
  Card,
  Grid,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Menu
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReactDOMServer from 'react-dom/server';
import { ApiService } from 'src/services/ApiService';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import { getLocalData } from 'src/utils';
import SalesHistoryReceipt from './SalesHistoryReceipt';

export const SalesHistoryTable = ({
  data,
  label,
  filterCompoenent,
  options = {},
  handleOnClick = () => {
    console.log('no event');
  },
  allOrderHistoryData
}) => {
  const locationId = getLocalData('locationId');
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [keys, setKeys] = useState([]);
  const [displayFilterForm, setdisplayFilterForm] = useState(false);
  const [promotionsType, setPromotionsType] = useState('All');
  const [anchor, setAnchor] = useState(null);
  const [getRowItem, setGetRowItem] = useState({});
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');

  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  let BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));
  const classes = useStyles();

  const handleSearchTextChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);
    setPage(0); // Reset page when search text changes
  };

  const filteredData = data.filter((item) => {
    return Object.values(item).some((value) =>
      value ? value.toString().toLowerCase().includes(searchText) : ''
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const showFilter = () => {
    setdisplayFilterForm(!displayFilterForm);
  };

  const getFilterSize = () => {
    return displayFilterForm ? 300 : 0;
  };

  const childOnClick = (e, rest) => {
    e.preventDefault();
    e.stopPropagation();
    handleOnClick(rest);
  };

  const onChange = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    handleOnClick([value, value, 'Type']);
    setPromotionsType(value);
  };

  useEffect(() => {
    let key = data && data.length > 0 && data[0] ? Object.keys(data[0]) : '';
    if (key) setKeys(key);
    // setKeys(['name', 'age', 'profession']);
  }, [data]);

  useEffect(() => {
    setPage(0);
  }, [data]);

  const openMenu = (rowDetails, event) => {
    const orderDetails = allOrderHistoryData?.find(
      (item) => rowDetails?.sortKey === item?.sortKey
    );
    event.stopPropagation();
    setGetRowItem(orderDetails);
    setAnchor(event.currentTarget);
  };

  const closeMenu = (e) => {
    e.stopPropagation();
    setGetRowItem({});
    setAnchor(null);
  };

  const printReceipt = () => {
    const receiptHtml = ReactDOMServer.renderToStaticMarkup(
      <SalesHistoryReceipt selected={getRowItem} />
    );

    const printWindow = window.open('', '', 'width=600,height=600');

    if (printWindow) {
      // Ensure the new window is opened only once
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Receipt</title>
          </head>
          <body>
            ${receiptHtml}
          </body>
        </html>
      `);
      printWindow.document.close();

      printWindow.onload = () => {
        printWindow.print();
        printWindow.onafterprint = () => {
          printWindow.close();
        };
      };
    } else {
      alert('Pop-up window blocked. Please allow pop-ups for this site.');
    }
  };

  const handleEmailReceipt = async () => {
    const ZROrderIdsss = encodeURIComponent(getRowItem?.sortKey);
    let encodedLocationId = encodeURIComponent(locationId);

    try {
      let response = await ApiService.get(
        `${BASE_URL}checkout/orderEmailReceipt?locationId=${encodedLocationId}&orderId=${ZROrderIdsss}`
      );

      if (response?.statusCode === 200) {
        setShowSuccessMessage(true);
        setSuccessMessage(
          response?.data?.message || 'Email has been sent successfully'
        );
      }
    } catch (error) {
      console.error(error);
      setShowMessage(true);
      setErrorMessage(error?.message || 'Something went Wrong');
    }
  };
  return (
    <Box sx={{ p: 2 }}>
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setSuccessMessage}
          closePopup={() => setShowSuccessMessage(false)}
        />
      )}
      {/* Search input with filter */}
      <Grid item xs={12} sx={{ padding: '0 1rem' }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid
            item
            sx={{
              marginTop: '10px',
              marginBottom: '20px'
            }}
          >
            {filterCompoenent && (
              <Button
                onClick={showFilter}
                variant={displayFilterForm ? 'contained' : 'outlined'}
              >
                Filter (All)
                <KeyboardArrowDownRoundedIcon
                  className={clsx(
                    !displayFilterForm && classes.closeY,
                    displayFilterForm && classes.openY
                  )}
                />
              </Button>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '10px',
              marginBottom: '20px'
            }}
            className="top-filter-cont"
          >
            {options && options.isPromotions === true ? (
              <>
                {/* Below field is for specific condition for Promotions */}
                <FormControl
                  variant="outlined"
                  style={{ marginRight: '20px', minWidth: '150px' }}
                >
                  <InputLabel className="status-field-lbl">
                    Promotions Type
                  </InputLabel>
                  <Select
                    onClick={(e) =>
                      childOnClick(e, [e.target.value, e.target.value, 'Type'])
                    }
                    onChange={(event) => onChange(event, event.target.value)}
                    label="Promotions Type"
                    value={promotionsType ?? 'All'}
                    autoWidth
                    className="status-field field-label-css"
                  >
                    <MenuItem value={'All'}>All</MenuItem>
                    <MenuItem value={'Draft'}>Draft</MenuItem>
                  </Select>
                </FormControl>
              </>
            ) : null}
            <TextField
              type="search"
              className="Search-field"
              label="Search"
              InputProps={{
                style: {},
                endAdornment: !searchText ? <SearchIcon /> : null
              }}
              onChange={handleSearchTextChange}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* END */}

      <Grid container spacing={2} sx={{ padding: '0 1rem' }}>
        {displayFilterForm === true ? (
          <Grid item width={getFilterSize()}>
            {filterCompoenent}
          </Grid>
        ) : null}

        <Grid item sx={{ width: `calc(100% - ${getFilterSize()}px)` }}>
          <Card sx={{ padding: '15px', borderRadius: '19px 19px 0px 0px' }}>
            {/* <CardHeader title={label} className="table-header-name" sx={{ fontSize: '20px', color: '#003087' }} /> */}
            {/* Table Header title and status field */}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                sm={5}
                className="d-flex align-item-center table-header-title"
              >
                <CardHeader
                  title={label}
                  className="table-header-name font-20 text-primary"
                />
              </Grid>
            </Grid>
            <TableContainer className="Custom-Table table-container common-table-container">
              <Table>
                <TableHead className="common-header">
                  <TableRow key={0}>
                    {keys?.map((item, indexhead) =>
                      indexhead > 1 ? (
                        item === 'Date' ? (
                          <TableCell
                            key={indexhead}
                            sx={{ minWidth: '110px' }}
                            className={
                              indexhead < 2 ? 'h-partitionKey h-sortKey' : ''
                            }
                          >
                            {item}
                          </TableCell>
                        ) : (
                          <TableCell
                            key={indexhead}
                            className={
                              indexhead < 2 ? 'h-partitionKey h-sortKey' : ''
                            }
                          >
                            {item}
                          </TableCell>
                        )
                      ) : (
                        ''
                      )
                    )}
                    <TableCell />
                    {/* Add more table headers as needed */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedData?.map((item, indexpage) => {
                    return (
                      <>
                        <TableRow
                          key={indexpage}
                          className="table-row cursor-pointer"
                        >
                          {keys?.map((key, indexkey) =>
                            indexkey > 1 ? (
                              <>
                                <TableCell
                                  key={indexkey}
                                  onClick={(e) =>
                                    childOnClick(e, [
                                      item['partitionKey'],
                                      item['sortKey'],
                                      'Detail'
                                    ])
                                  }
                                >
                                  {item[key]}
                                </TableCell>
                              </>
                            ) : (
                              ''
                            )
                          )}
                          <TableCell className="table-td">
                            <MoreVertIcon
                              onClick={(e) => openMenu(item, e)}
                              color="grey"
                              variant="contained"
                              className="three-dots-icon"
                            />

                            <Menu
                              open={Boolean(anchor)}
                              anchorEl={anchor}
                              onClose={(e) => closeMenu(e)}
                              keepMounted
                              PaperProps={{
                                style: {
                                  maxHeight: 40 * 5,
                                  width: '25ch'
                                }
                              }}
                            >
                              <MenuItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEmailReceipt();
                                }}
                              >
                                Email Receipt
                              </MenuItem>
                              <MenuItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  printReceipt();
                                }}
                              >
                                Print Receipt
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Pagination */}
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
