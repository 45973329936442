import {
  Button,
  Box,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
} from '@mui/material';
import React, { useEffect, useState, useImperativeHandle } from 'react';
import ProductGridView from './ProductGridView';
import Loader from '../../../components/Loader';
import { toast } from 'react-toastify';
import { getLocalData } from 'src/utils';
import { ApiService } from 'src/services/ApiService';
// import { getLocalData } from 'src/utils'; // setFullLocationId

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// props, ref, useImperativeHandle
function ProductListAPI({ ref, showScreen, setShowScreen }) {
  // const locationId = setFullLocationId(getLocalData('locationId'));
  const locationId = getLocalData('locationId');
  const [productList, setProductlist] = useState([]);
  const [totalCount, setTotalCount] = useState('');
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  const [productArray, setproductArray] = useState([
    { createdAt: '0', productId: '0' }
  ]);
  const [activePagination, setactivePagination] = useState('');
  const [ispopup, setIsPopup] = useState(false);
  const userId = getLocalData('userId');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedProductLists, setSelectedProductLists] = useState([]);
  const [open, setOpen] = React.useState(false);
  // check updated or saved
  const [isUpdated, setIsUpdated] = useState(false);

  // const[createdAt,setcreatedAt]=useState(null);

  // const[productId,setproductId]=useState(null);
  // let categoryList = [];
  const [categoryList, setcategoryList] = useState([]);
  const [supplierList, setsupplierList] = useState([]);
  let [filters, setSidebarFilters] = useState({
    // isDiscontinued: null,
    status: 1,
    categoryName: null,
    // isBestSeller: null,
    supplierName: null,
    stock: null,
    isFranchisor: null
  });

  // Getting hooks from parent to child
  /* useImperativeHandle(ref, () => ({
    refreshData: () => {
      // Not call the get franchisee method again
      getProducts();
    }
  })); */

  // const removeNullValues = (obj) => {
  //   return Object.fromEntries(
  //     Object.entries(obj)
  //       .filter(([_, value]) => value !== null)
  //       .map(([key, value]) => {
  //         if (typeof value === 'object' && value !== null) {
  //           return [key, removeNullValues(value)];
  //         }
  //         return [key, value];
  //       })
  //   );
  // };
  // const cleanObject = (obj) => {
  //   return Object.fromEntries(
  //     Object.entries(obj).filter(([key, value]) => {
  //       if (typeof value === 'object' && value !== null) {
  //         value = cleanObject(value);
  //       }
  //       return value !== null && value !== '' && value !== undefined;
  //     })
  //   );
  // };

  // const cleanFilters = (obj) => {
  //   return Object.entries(obj).reduce((acc, [key, value]) => {
  //     if (typeof value === 'object' && value !== null) {
  //       const nested = cleanFilters(value);
  //       if (Object.keys(nested).length !== 0) {
  //         acc[key] = nested;
  //       }
  //     } else if (value !== null && value !== '' && value !== undefined) {
  //       acc[key] = value;
  //     }
  //     return acc;
  //   }, {});
  // };

  const getProducts = async () => {
    const encodedLocationId = encodeURIComponent(locationId);
    // Now show the loader
    if (!ispopup) setIsLoading(true);
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (
        filtersParams[key] === null ||
        filtersParams[key] === 'All' ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === undefined
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

    let productUrl;

    if (
      Object.keys(queryString).length === 0 &&
      queryString.constructor === Object
    ) {
      productUrl = `${process.env.REACT_APP_INVENTRY_API_END_POINT}products/getAllProducts?locationId=${encodedLocationId}&${queryString}`;
    } else {
      // eslint-disable-next-line no-unused-vars
      productUrl = `${process.env.REACT_APP_INVENTRY_API_END_POINT}products/getAllProducts?locationId=${encodedLocationId}&${queryString}`;
    }

    try {
      let response = await ApiService.get(`${productUrl}`);
      if (!response?.data?.error) {
        setTotalCount(response.data.Count);
        setProductlist(response.data.Items);
        setIsLoading(false);
        const newArray = [
          ...productArray,
          {
            createdAt:
              response.data.LastEvaluatedKey &&
              response.data.LastEvaluatedKey.length > 0
                ? response.data.LastEvaluatedKey.createdAt
                : null,
            productId:
              response.data.LastEvaluatedKey &&
              response.data.LastEvaluatedKey.length > 0
                ? response.data.LastEvaluatedKey.productId
                : null
          }
        ];
        setproductArray(newArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // gets call the method to get the data
  useEffect(() => {
    getProducts(activePagination);
  }, [JSON.stringify(filters)]);

  const getCategories = async () => {
    const encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_INVENTRY_API_END_POINT}products/getAllProductCategories?locationId=${encodedLocationId}`
      );
      if (!response?.data?.error) {
        let Item = response.data.Items;
        setcategoryList(Item);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* Function to get the suppliers list */
  const getSupplier = async () => {
    const encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/getAllBusinessEntities?businessEntityType=Supplier&locationId=${encodedLocationId}`
      );
      if (!response?.data?.error) {
        setsupplierList(response.data.Items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
    getSupplier();
    // getProductList();
  }, []);

  // Handle the event from child to parent. It is being used for refresh the data after edit or delete
  const refreshProductData = () => {
    // When popup is open then not call the loader
    setIsPopup(true);
    // Not call the get location method again
    getProducts(activePagination);
  };
  // Getting hooks from parent to child
  useImperativeHandle(ref, () => ({
    refreshProductData: () => {
      // Not call the get product method again
      getProducts(activePagination);
    }
  }));

  const handleBulkAdd = async () => {
    setIsUpdated(false);
    setOpen(true);
  };

  const confirmBulkUpdate = async () => {
    setOpen(false);
    // show loader
    setIsLoading(true);
    const data = {
      status: selectedStatus,
      updatedBy: userId,
      productIds: selectedProductLists,
      locationId: locationId
    };

    try {
      const response = await ApiService.put(
        `${process.env.REACT_APP_INVENTRY_API_END_POINT}products/bulkUpdate`,
        data
      );
      if (!response?.error) {
        setSelectedStatus('');
        setSelectedProductLists([]);
        setIsUpdated(true);
        setOpen(true);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error || 'Something went wrong with update product status');
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      confirmBulkUpdate();
    } else if (val === 'Discard') {
      setOpen(false);
      if (isUpdated) {
        getProducts();
        setIsUpdated(false);
      }
    }
  };

  return (
    <Grid xs={12}>
      <Loader IsLoading={isLoading} />
      <ProductGridView
        productLists={productList}
        sideBarfilters={filters}
        setSidebarFilters={setSidebarFilters}
        totalCount={totalCount}
        categoryList={categoryList}
        paginationProducts={getProducts}
        setactivePagination={setactivePagination}
        refreshProductData={refreshProductData}
        showScreen={showScreen}
        setShowScreen={setShowScreen}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        handleBulkAdd={handleBulkAdd}
        selectedProductLists={selectedProductLists}
        setSelectedProductLists={setSelectedProductLists}
        open={open}
        setOpen={setOpen}
        supplierList={supplierList}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? '' : 'Activate/Deactivate Selected Items'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  Are you sure you want to mark Activate/Deactivate,
                  <Typography className="title-text-sub">
                    all the selected items?
                  </Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  All the selected products have been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Cancel'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default React.forwardRef(ProductListAPI);
