import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  // useTheme,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  IconButton,
  Slide,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  DialogActions,
  Button
} from '@mui/material';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditClientNotes from './EditClientNotes';
import Loader from 'src/components/Loader';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import { ApiService } from 'src/services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const NotesTable = (props) => {
  const { tableRows, searchQuery, setInvokeRefresh, allPets } = props;
  // const theme = useTheme();

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);

  const [selectedItem, setselectedItem] = useState({});
  const [deleteDialog, setDeleteDialog] = useState(false);

  const [openAlertNotesDialog, setOpenAlertNotesDialog] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showSucessMessage, setShowSuccessMessage] = useState(false);

  // Method to handle the popover open
  const handlePopoverOpen = (event, rest) => {
    setAnchorEl(event.currentTarget);
    setselectedItem(rest);
  };

  // Method to handle the popover close
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setselectedItem({});
  };

  const handleDeleteDialog = (type) => {
    if (type === 'cancel') {
      setDeleteDialog(false);
      setselectedItem({});
    } else {
      setDeleteDialog(false);
      deleteNotes();
    }
  };

  const deleteNotes = async () => {
    setIsLoading(true);
    let clientid = localStorage?.getItem('clientId');
    let encodedNotesId = encodeURIComponent(selectedItem?.notesId);
    let encodedClientId = encodeURIComponent(selectedItem?.clientId);
    let encodedLocalStorageClientId = encodeURIComponent(clientid);

    try {
      let url = `${
        process.env.REACT_APP_MEMBERSHIP_API_END_POINT
      }client/DeleteNotes?notesId=${encodedNotesId}&clientId=${
        encodedClientId || encodedLocalStorageClientId
      }`;
      const response = await ApiService?.delete(`${url}`);

      if (response?.statusCode === 200) {
        setIsLoading(false);
        setShowSuccessMessage(true);
        setSuccessMessage(response?.message);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setShowMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };
  useEffect(() => {
    const searchText = searchQuery.toLowerCase();
    setSearchText(searchText);
    setPage(0);
  }, [searchQuery]);

  const filteredData = tableRows?.filter((item) => {
    return Object.values(item)?.some((value) =>
      value?.toString()?.toLowerCase()?.includes(searchText)
    );
  });

  useEffect(() => {
    setPage(0);
  }, [tableRows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = filteredData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const dateFormate = (date) => {
    let utc;
    utc = date ? moment?.utc(date)?.format('MMM DD, YYYY') : '';
    return utc;
  };

  const formateDateLocaltimezone = (date) => {
    const utcDate = new Date(date);
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const userTimezone = 'America/New_York';

    const userDateStr = utcDate.toLocaleString('en-US', {
      timeZone: userTimezone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      fractionalSecondDigits: 3 // To include milliseconds
    });

    const [month, day, year, hour, minute, second] = userDateStr.match(/\d+/g);
    const milliseconds = date.split('.')[1].slice(0, 3);

    const userDate = new Date(
      `${year}-${month}-${day}T${hour}:${minute}:${second}.${milliseconds}`
    );

    return dateFormate(userDate.toISOString());
  };

  const closePopup = () => {
    setShowSuccessMessage(false);
    setInvokeRefresh(true);
  };
  const deactivatePets = allPets?.filter((item) => item.status === 0) || [];
  const disabledButton = deactivatePets.some(
    (item) => selectedItem.petId === item.sortKey
  );

  return (
    <div>
      <Loader IsLoading={isLoading} />

      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setSuccessMessage}
          closePopup={closePopup}
        />
      )}
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px'
        }}
      >
        <TableContainer className="location-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell>Notes</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Dog</TableCell>
                <TableCell>Author</TableCell>
                <TableCell>Notes For</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {(paginatedData &&
                paginatedData.length > 0 &&
                paginatedData.map((row) => {
                  // const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      key={row.sortKey}
                      role="checkbox"
                      tabIndex={-1}
                      className="table-row cursor-pointer"
                    >
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          // noWrap
                        >
                          {row?.notes}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.createdAt &&
                            formateDateLocaltimezone(row?.createdAt)}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.petName || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.createdByName}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.notesFor}
                        </Typography>
                      </TableCell>

                      <TableCell className="table-td">
                        <IconButton onClick={(e) => handlePopoverOpen(e, row)}>
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })) || (
                <TableRow>
                  <TableCell colSpan={6} className="table-td">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ marginTop: 1 }}
                      gutterBottom
                      noWrap
                    >
                      No data found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          keepMounted
          PaperProps={{
            style: {
              maxHeight: 40 * 5,
              width: '13ch'
              // border: '1px solid grey'
            }
          }}
        >
          <MenuItem
            onClick={() => {
              setOpenAlertNotesDialog(true);
              setAnchorEl(null);
            }}
            disabled={disabledButton}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              setDeleteDialog(true);
              setAnchorEl(null);
            }}
            disabled={disabledButton}
          >
            Delete
          </MenuItem>
        </Menu>

        <Box p={2}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={filteredData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Card>

      {/* Add alert and notes */}
      <Box>
        {openAlertNotesDialog && (
          <EditClientNotes
            openAlertNotesDialog={openAlertNotesDialog}
            setOpenAlertNotesDialog={setOpenAlertNotesDialog}
            selectedItem={selectedItem}
            setInvokeRefresh={setInvokeRefresh}
          />
        )}
      </Box>

      {/* delete confirmation dialog */}
      <Dialog
        open={deleteDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleDeleteDialog('cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to delete this note?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleDeleteDialog('delete')}
          >
            Delete
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleDeleteDialog('cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
