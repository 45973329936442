/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
  Avatar,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  DialogActions,
  Divider,
  Slide,
  FormHelperText
} from '@mui/material';
import Loader from '../../../components/Loader';
import './addClient.css';
import { getLocalData } from '../../../utils';
// import { CustomDatePicker } from 'src/components/CustomDatePicker';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { ApiService } from '../../../services/ApiService';
import { CustomDatePickerForAllBrowser } from 'src/components/CustomDatePickerForAllBrowser';
import heic2any from 'heic2any';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';

const EditPet = ({ petId, setShowScreen, selectedClientId }) => {
  let userId = getLocalData('userId');
  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  // const isPrimaryOwner = getLocalData('isPrimaryOwner');
  const franchiseeId = getLocalData('franchiseeId');
  const franchiseeName = getLocalData('franchiseeName');
  const locationName = getLocalData('locationName');

  const locationId =
    isFranchisor === 'true'
      ? localStorage.getItem('SelectedLocationId')
      : getLocalData('locationId');
  // const inputRef = useRef(null);
  /* State for add client */
  const currentDate = new Date();
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 20);
  const minDateValue = new Date(minDate);
  // const [petNotes, setPetNotes] = useState('');
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [clientId, setClientId] = useState('');
  let clientDetails;
  /* States for setting details of pet information */

  const [petFirstName, setpetFirstName] = useState('');
  const [petLastName, setpetLastName] = useState('');
  const [birthDate, setbirthDate] = useState('');
  const [gender, setgender] = useState('');
  const [acquisitionSource, setacquisitionSource] = useState('');
  const [breed, setbreed] = useState('');
  const [veterinarian, setveterinarian] = useState('');
  const [imgObj, setimgObj] = useState([]);
  const [imgUrl, setimgUrl] = useState('');
  const [allergies, setallergies] = useState([]);
  const [bark, setbark] = useState('');
  const [strangers, setstrangers] = useState('');
  const [biting, setbiting] = useState('');
  const [possessive, setpossessive] = useState('');
  const [advanced, setadvanced] = useState('');
  const [social, setsocial] = useState('');
  const [orientation, setorientation] = useState(false);
  const [evaluation, setevaluation] = useState(false);
  const [agility, setagility] = useState(false);
  const [tricks, settricks] = useState(false);
  const [rabiesExpiration, setrabiesExpiration] = useState('');
  const [bordetellaExpiration, setbordetellaExpiration] = useState('');
  const [dhppExpiration, setdhppExpiration] = useState('');
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('');

  /* State Error message for pet information validation */
  const [petFirstNameError, setpetFirstNameError] = useState('');
  const [birthDateError, setbirthDateError] = useState('');
  const [genderError, setgenderError] = useState('');
  const [acquisitionSourceError, setacquisitionSourceError] = useState('');
  const [breedError, setbreedError] = useState('');
  // const [veterinarianError, setveterinarianError] = useState("");
  // const [allergiesError, setallergiesError] = useState('');
  // const [orientationError, setorientationError] = useState('');
  // const [evaluationError, setevaluationError] = useState('');
  // const [notes, setNotes] = useState('');
  const [breeds, setbreeds] = useState([]);
  // const [openAddClient, setopenAddClient] = useState(false);
  const [openSuccess, setSuccessOpen] = React.useState(false);
  // show error message
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [veterinarianList, setVeterinarianList] = useState([]);
  const [acquisitionList, setAcquisitionList] = useState([]);
  const [allergiesList, setAllergiesList] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [multipleFile, setMultipleFile] = useState([]);
  const [productImage, setProductImage] = useState([]);
  const [imageError, setImageError] = useState('');
  const [profileImageError, setProfileImageError] = useState('');
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  // const [weight, setWeight] = useState('');

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

  useEffect(() => {
    // Scrolling window on top at initial
    window.scroll(0, 0);
    getBreed();
    getAllAcquisition();
    getAllAllergies();
    getVeterinarian();
  }, []);

  useEffect(() => {
    getChildDetails();
  }, [clientDetails]);

  /* Function to get getAllAcquisition */

  const getAllAcquisition = async () => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}breed-list/getAllAcquisition`
      );
      if (response.statusCode === 200) {
        setAcquisitionList(response.data.Items);
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* Function to get getAllAllergies */

  const getAllAllergies = async () => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}breed-list/getAllAllergies`
      );
      if (response.statusCode === 200) {
        setAllergiesList(response.data.Items);
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Method to get theclient details
  const getChildDetails = async () => {
    // Now show the loader
    setIsLoading(true);
    let encodedChildId = encodeURIComponent(petId);
    let encodedCLientId = encodeURIComponent(selectedClientId);

    // setIsLoadingEdit(true);
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}client/getChildDetails?childId=${encodedChildId}&clientId=${encodedCLientId}`
      );

      if (response.statusCode && !response.error) {
        // Set the list of location
        clientDetails = response.data.Items;
        getClientDetails();
        // Now hide the loader
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      // Now hide the loader
      setIsLoading(false);
    }
  };

  const getClientDetails = () => {
    // let parts = clientDetails[0]?.birthDate.split("-");

    // Reorder the array elements to reverse the date
    // let reversedBirthday = parts[2] + "-" + parts[0] + "-" + parts[1];
    // console.log(parts, 'parts', reversedBirthday, 'reversedBirthday');
    // Pet Data
    setpetFirstName(clientDetails[0]?.firstName);
    setpetLastName(clientDetails[0]?.lastName);
    setbirthDate(clientDetails[0]?.birthDate);
    // setbirthDate(reversedBirthday);
    setgender(clientDetails[0]?.gender);
    setacquisitionSource(clientDetails[0]?.acquisitionSource);
    setbreed(clientDetails[0]?.breed);
    setveterinarian(clientDetails[0]?.veterinarian);
    // setallergies([clientDetails[0]?.allergies]);
    if (clientDetails[0]?.allergies) {
      setallergies(clientDetails[0]?.allergies.split(','));
    }
    setimgUrl(clientDetails[0]?.profileImage);
    setbark(clientDetails[0]?.barking);
    setstrangers(clientDetails[0]?.strangers);
    setpossessive(clientDetails[0]?.possessive);
    setbiting(clientDetails[0]?.biting);
    setadvanced(clientDetails[0]?.advanced);
    setsocial(clientDetails[0]?.social);
    if (clientDetails[0]?.completed?.orientation === 'Yes') {
      setorientation(true);
    } else {
      setorientation(false);
    }
    if (clientDetails[0]?.completed?.evaluation === 'Yes') {
      setevaluation(true);
    } else {
      setevaluation(false);
    }
    if (clientDetails[0]?.completed?.agility === 'Yes') {
      setagility(true);
    } else {
      setagility(false);
    }
    if (clientDetails[0]?.completed?.tricks === 'Yes') {
      settricks(true);
    } else {
      settricks(false);
    }
    setrabiesExpiration(clientDetails[0]?.rabiesExpiration);
    setbordetellaExpiration(clientDetails[0]?.bordetellaExpiration);
    setdhppExpiration(clientDetails[0]?.dhppExpiration);
    // setPetNotes(clientDetails[0]?.yourDog);
    setClientId(clientDetails[0]?.sortKey);
    setStatus(clientDetails[0]?.status);
    setDocumentList(clientDetails[0]?.documentList);
    // setWeight(clientDetails[0]?.weight);
  };

  const handleConfirmClose = (val) => {
    if (val === 'Discard') {
      //  setopenNewClient(false);
      setOpenConfirmPopup(false);
      setShowScreen(3);
      // openEdit(false);
    }
    if (val === 'Save') {
      // openEdit(true);
      // setopenNewClient(true);
      setOpenConfirmPopup(false);
    }
  };

  // Method to open the update dialog
  const handleClickOpenSuccess = () => {
    setSuccessOpen(true);
  };

  /* Function to upload image Add Client */
  // const handleFileUpload = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  // };

  // fucntion to validate the date
  function isValidDate(dateString) {
    let regEx = /^\d{4}-\d{2}-\d{2}$/;
    return dateString.match(regEx) != null;
  }

  // Method to add the pet
  const updatePet = async () => {
    setShowError(false);
    setErrorMessage('');
    setIsLoading(true);
    // let clientId = clientId || clientDetails[0]?.clientId;
    let childId = petId || clientDetails[0]?.childId;

    if (clientId && childId) {
      // Now show the loader
      // setIsLoading(true);
      // let reversedBirthday;
      // let validDate = birthDate && isValidDate(birthDate);
      // if (validDate) {
      //   let parts = birthDate.split("-");

      //   // Reorder the array elements to reverse the date
      //   reversedBirthday = parts[1] + "-" + parts[2] + "-" + parts[0];
      // } else {
      //   reversedBirthday = birthDate;
      // }

      // let imagePath = (imgObj) ? String(imgObj) : '';
      // console.log(birthDate, 'birthDate2', reversedBirthday);
      // Use form data to send the body
      let formData = new FormData();
      formData.append('clientId', selectedClientId);
      formData.append('firstName', petFirstName || '');
      formData.append('lastName', petLastName || '');
      if (imgObj.name) formData.append('profileImage', imgObj);
      formData.append('birthDate', birthDate || ''); //  reversedBirthday
      formData.append('gender', gender || '');
      formData.append('acquisitionSource', acquisitionSource || '');
      formData.append('breed', breed || '');
      // formData.append('yourDog', petNotes || '');
      formData.append('veterinarian', veterinarian || '');
      formData.append('allergies', allergies || []);
      formData.append('barking', bark || '');
      formData.append('strangers', strangers || '');
      formData.append('biting', biting || '');
      formData.append('possessive', possessive || '');
      formData.append('advanced', advanced || '');
      formData.append('social', social || '');
      formData.append('completed[orientation]', orientation ? 'Yes' : 'No');
      formData.append('completed[evaluation]', evaluation ? 'Yes' : 'No');
      formData.append('completed[agility]', agility ? 'Yes' : 'No');
      formData.append('completed[tricks]', tricks ? 'Yes' : 'No');
      formData.append('status', status);

      formData.append('rabiesExpiration', rabiesExpiration || '');
      formData.append('bordetellaExpiration', bordetellaExpiration || '');
      formData.append('dhppExpiration', dhppExpiration || '');
      formData.append('updatedBy', userId);

      formData.append('franchiseeId', franchiseeId);
      formData.append('franchiseeName', franchiseeName);
      formData.append('locationId', locationId);
      formData.append('locationName', locationName);
      // formData.append('weight', weight || '');

      let encodedChildId = encodeURIComponent(petId);
      try {
        const response = await ApiService.put(
          `${process.env.REACT_APP_API_END_POINT}client/updateChild?id=${encodedChildId}`,
          formData
        );
        const data = response;

        if (data.statusCode === 200) {
          let clId = selectedClientId || localStorage.getItem('clientId');
          let childId = petId || clientDetails[0]?.childId;

          // Now call the method to add the pet document
          if (multipleFile && multipleFile.length > 0) {
            sessionStorage.setItem('cId', clientId);
            sessionStorage.setItem('petId', childId);

            for (let i = 0; i < multipleFile.length; i++) {
              let clientId = clId || sessionStorage.getItem('cId');
              let petId = childId || sessionStorage.getItem('petId');
              // Now call the method to add the pet document
              addPetDocument(clientId, petId, multipleFile[i]);
            }
          }
          // set field updated to false
          setIsFieldUpdated(false);
          setIsUpdated(true);
          refreshClientData();
          handleClickOpenSuccess();
          // Now hide the loader
          setIsLoading(false);
        } else {
          // Now hide the loader
          setIsLoading(false);
          // setOpen(false);
          setShowError(true);
          setErrorMessage(data.message);
        }
      } catch (error) {
        console.error(error);
        // Now hide the loader
        setIsLoading(false);
      }
    }
  };

  const refreshClientData = () => {
    // getEventToRefresh();
  };

  // const descriptionSet = (val) => {
  //   setIsFieldUpdated(true);
  //   setPetNotes(val);
  // };

  // Function to close modal popUp

  // Method to close the update dialog
  const handleSuccessClose = (val) => {
    if (val === 'Discard') {
      //  setopenNewClient(false);
      setSuccessOpen(false);
      // openEdit(false);
      setShowScreen(3);
    }
    if (val === 'Save') {
      updatePet();
      // setopenNewClient(true);
      setSuccessOpen(false);
    }
  };
  /* Function to upload Image for pet */

  const handleImageUpload = async (e) => {
    setProfileImageError('');
    const maxAllowedSize = 5 * 1024 * 1024;
    let filesLength = e.target.files[0].size;
    setIsFieldUpdated(true);
    const imageFile = e.target.files[0];

    if (imageFile && filesLength <= maxAllowedSize) {
      const isHeicFormat = imageFile.name.toLowerCase().endsWith('.heic');
      const isSupportedFormat = /\.(png|jpe?g|heic)$/i.test(imageFile.name);

      if (isSupportedFormat) {
        if (isHeicFormat) {
          try {
            // Convert HEIC to JPEG
            const jpegBlob = await heic2any({
              blob: imageFile,
              toType: 'image/jpeg',
              quality: 0.7 // Adjust quality as needed
            });

            const jpegFile = new File(
              [jpegBlob],
              `${imageFile.name.replace(/\.heic$/, '')}.jpeg`,
              {
                type: 'image/jpeg',
                lastModified: imageFile.lastModified
              }
            );

            setimgObj(jpegFile);
            setimgUrl(URL.createObjectURL(jpegFile));
            setProfileImageError(null);
          } catch (error) {
            console.error('Error converting HEIC to JPEG:', error);
            // Handle error as needed
            setProfileImageError(
              'Error converting HEIC to JPEG. Please try again.'
            );
          }
        } else {
          // If it's a supported format but not HEIC, handle it as usual
          setimgObj(imageFile);
          setimgUrl(URL.createObjectURL(imageFile));
          setProfileImageError(null);
        }
      } else {
        // If it's not a supported format, display an error message
        setProfileImageError(
          'Please upload only png, jpeg, jpg, or heic format images.'
        );
      }
    } else {
      setProfileImageError('Image size is more then 5mb.');
    }
  };

  const editPet = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!petFirstName) {
      setpetFirstNameError('First name is required');
    }

    if (!birthDate) {
      setbirthDateError('Birth date is required');
    }

    // if (!gender) {
    //   setgenderError('Gender is required');
    // }
    // if (!acquisitionSource) {
    //   setacquisitionSourceError(' Acquisition Source is required');
    // }
    // if (!breed) {
    //   setbreedError(' Breed is required');
    // }
    // if (!veterinarian) {
    //     setveterinarianError("Veterinarian is required")
    // }

    // if (!allergies) {
    //   setallergiesError('Allergies is required');
    // }
    // if (!orientation) {
    //   setorientationError('Orientation is required');
    // }

    // if (!evaluation) {
    //   setevaluationError('Evaluation is required');
    // }
    // check if all field are valid or not
    if (
      petFirstName &&
      birthDate
      // gender &&
      // acquisitionSource &&
      // breed
      // allergies
    ) {
      if (isFieldUpdated) {
        handleClickOpenSuccess();
      }
      // updateClientDetails();
    }
  };

  /* Functions to set values of various fields */

  const SetPetfirstName = (val) => {
    if (!val) {
      setpetFirstNameError('First name is required');
      setpetFirstName('');
    } else {
      setpetFirstNameError('');
      setpetFirstName(val);
      setIsFieldUpdated(true);
    }
  };

  const SetPetLastName = (val) => {
    setpetLastName(val);
    setIsFieldUpdated(true);
  };

  const SetBirthDate = (val) => {
    if (val === '01-01-1970') {
      return;
    }
    if (!val) {
      setbirthDateError('Birth date is required');
    } else {
      setbirthDateError('');
      setIsFieldUpdated(true);
      setbirthDate(val);
    }
  };

  const SetGender = (val) => {
    // if (!val) {
    //   setgenderError('Gender is required');
    // } else {
    setgenderError('');
    setIsFieldUpdated(true);
    setgender(val);
    // }
  };

  const SetacquisitionSource = (val) => {
    // if (!val) {
    //   setacquisitionSourceError('AcquisitionSource is required');
    // } else {
    setacquisitionSourceError('');
    setIsFieldUpdated(true);
    setacquisitionSource(val);
    // }
  };

  const SetBreed = (val) => {
    // if (!val) {
    //   setbreedError('Breed is required');
    // } else {
    setbreed(val);
    setbreedError('');
    setIsFieldUpdated(true);
    // }
  };

  const SetVeterinarian = (val) => {
    if (val) {
      setveterinarian(val);
      setIsFieldUpdated(true);
    } else {
      setveterinarian(false);
    }
  };

  const SetAllergies = (val) => {
    if (!val) {
      // setallergiesError('Allergies is required');
      setallergies('');
    } else {
      setallergies(val);
      setIsFieldUpdated(true);
      // setallergiesError('');
    }
  };

  const SetBark = (val) => {
    setbark(val);
    setIsFieldUpdated(true);
  };

  const SetStrangers = (val) => {
    setstrangers(val);
    setIsFieldUpdated(true);
  };

  const SetBiting = (val) => {
    setbiting(val);
    setIsFieldUpdated(true);
  };

  const SetPossessive = (val) => {
    setpossessive(val);
    setIsFieldUpdated(true);
  };

  const SetAdvanced = (val) => {
    setadvanced(val);
    setIsFieldUpdated(true);
  };

  const SetSocial = (val) => {
    setsocial(val);
    setIsFieldUpdated(true);
  };

  const SetOrientation = (val) => {
    setorientation(val);
    setIsFieldUpdated(true);
  };

  const SetEvaluation = (val) => {
    setevaluation(val);
    setIsFieldUpdated(true);
  };

  const SetAgility = (val) => {
    setagility(val);
    setIsFieldUpdated(true);
  };

  const SetTricks = (val) => {
    settricks(val);
    setIsFieldUpdated(true);
  };

  const SetRabiesExpiration = (val) => {
    if (val === '01-01-1970') {
      return;
    }

    setrabiesExpiration(val);
    setIsFieldUpdated(true);
  };

  const SetBordetellaExpiration = (val) => {
    if (val === '01-01-1970') {
      return;
    }
    setbordetellaExpiration(val);
    setIsFieldUpdated(true);
  };

  const SetDhppExpiration = (val) => {
    if (val === '01-01-1970') {
      return;
    }

    setdhppExpiration(val);
    setIsFieldUpdated(true);
  };

  // const SetWeight = (e) => {
  //   const weight = e.target.value;
  //   const onlyNumbers = /^[0-9]*$/;

  //   // Check if weight is empty or only contains numbers
  //   if (weight === '' || onlyNumbers.test(weight)) {
  //     setIsFieldUpdated(true);
  //     setWeight(weight);
  //   }
  // };

  /* Function to get breeds */

  const getBreed = async () => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}breed-list`
      );
      if (response.statusCode === 200) {
        setbreeds(response.data.Items);
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* Function to get Veterinarian */

  const getVeterinarian = async () => {
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/getAllBusinessEntities?locationId=${encodedLocationId}&businessEntityType=Veterinarian`
      );
      if (response.statusCode === 200) {
        setVeterinarianList(response.data.Items);
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Function to close the edit location dialog box
  const handleEditClientClose = () => {
    if (isFieldUpdated) {
      // setopenNewClient(true)
      setOpenConfirmPopup(true);
      // setOpen(true);
    } else {
      // setopenNewClient(false);
      setOpenConfirmPopup(false);
      setShowScreen(3);
      // openEdit(false);
      // setOpen(false);
    }
  };

  // Debounced function to call API
  // const debouncedCheckEmailExist = debounce(checkEmailExist, 500); // Adjust the debounce delay as per your requirements
  // const debouncedCheckMobileNumberExist = debounce(checkMobileNumberExist, 500); // Adjust the debounce delay as per your requirements

  const removeFile = (idToRemove) => {
    const updatedFiles = multipleFile.filter((file) => file.id !== idToRemove);
    setMultipleFile(updatedFiles);

    // check if multiple file lenght is less than or equal to 5
    if (multipleFile.length <= 5) {
      setImageError('');
    }
  };

  useEffect(() => {
    if (multipleFile) {
      let images = multipleFile.map((item) => item.name);
      setProductImage(images);

      // check if multiple file lenght is less than or equal to 5
      if (multipleFile.length <= 5) {
        setImageError('');
      }
    }
  }, [multipleFile]);

  /* Function to get uploaded image */
  const handleFileInput = (event) => {
    /* Maximum allowed size in bytes
      5MB Example
      Change first operand(multiplier) for your needs */
    const maxAllowedSize = 5 * 1024 * 1024;
    let filesLength = event.target.files.length;
    let uploadedfileLength = documentList?.length || 0;
    let total = multipleFile.length + filesLength + uploadedfileLength || 0;

    if (
      event.target.files.length !== 0 &&
      filesLength <= 5 &&
      multipleFile.length <= 5 &&
      total <= 5
    ) {
      const fileList = event.target.files;
      let totalSize = 0;
      const uploadedImages = Array.from(fileList)
        .map((file) => {
          totalSize += file.size;
          if (file.type === 'image/gif') {
            setImageError('Please upload a supported file size and format.');
            return null;
          }
          if (totalSize > maxAllowedSize) {
            setImageError(
              'Total file size exceeds the maximum allowed limit of 5 MB'
            );
            return null;
          }
          return {
            name: file.name,
            id: Math.random().toString(36).substring(7),
            file: file
          };
        })
        .filter(Boolean); // Remove null values

      if (uploadedImages.length > 0) {
        setMultipleFile([...multipleFile, ...uploadedImages]);
        setImageError('');
        setIsFieldUpdated(true);
      }
    } else if (filesLength > 5 || multipleFile.length > 5 || total > 5) {
      setImageError(
        'Number of 5 files allowed only. Please select/remove extra files.'
      );
    }
  };

  // Method to add the pet
  const addPetDocument = async (clId, petId, docObj) => {
    let clientId = clId || sessionStorage.getItem('cId');
    let childId = petId || sessionStorage.getItem('petId');
    let uploadData = docObj;

    if (clientId && childId) {
      // Now show the loader
      // setIsLoading(true);
      let formData = new FormData();
      formData.append('clientId', clientId);
      formData.append('childId', childId);
      formData.append('documentImage', uploadData?.file);
      formData.append('documentName', uploadData?.name);

      const response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}client/addDocumentProfile`,
        formData
      );
      if (response.statusCode === 200) {
        // localStorage.removeItem('clientId');
        // setIsLoading(false);
        // setOpen(true);
        // getEventToRefresh();
      } else {
        setShowError(true);
        setErrorMessage(response.message);
        setIsLoading(false);
      }
    }
  };

  const deletePetDocument = async (item) => {
    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this document?'
    );
    if (!isConfirmed) {
      return; // Exit the function if the user cancels the action
    }

    // To show loader
    setIsLoading(true);

    let body = {
      clientId: selectedClientId,
      childId: petId,
      documentId: item?.documentId
    };

    try {
      const response = await ApiService.delete(
        `${process.env.REACT_APP_API_END_POINT}client/deleteDocumentProfile`,
        body
      );

      if (response.statusCode === 200) {
        setShowSuccessMessage(true);
        setSuccessMessage('Selected document has been deleted successfully!');
        setIsLoading(false);
        // To hide loader
      }
    } catch (error) {
      console.error(error);
      // To hide loader
      setIsLoading(false);
      setShowError(true);
      setErrorMessage(error.message);
    }
  };

  const closePopup = () => {
    setShowSuccessMessage(false);
    getChildDetails();
  };

  return (
    <>
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setSuccessMessage}
          closePopup={closePopup}
        />
      )}
      <Loader IsLoading={isLoading} />
      <Grid
        sx={{ padding: '0 2rem' }}
        className="mt-20"
        component="form"
        onSubmit={editPet}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                onClick={() => handleEditClientClose()}
                className="back-arrow-close"
              >
                <ArrowLeft />
              </Box>
              <Typography
                className="header-blue-32"
                // style={{ marginTop: '-5px', marginLeft: '5px' }}
              >
                Edit Dog
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                sx={{ mt: { xs: 0, md: 0 } }}
                variant="contained"
                type="submit"
                className="btn-primary"
              >
                Update
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box
          id="alert-dialog-slide-description"
          className="alert-decription p- pt-0 mt-20"
        >
          <Typography className="header-blue-22">Dog information</Typography>
          <Box

          // sx={{ margin: 'auto', width: '98%' }}
          >
            <Grid container spacing={2} sx={{ marginTop: '5px' }}>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  fullWidth
                  name="First name"
                  autocomplete="First name"
                  label="FIRST NAME"
                  placeholder="FIRST NAME"
                  value={petFirstName}
                  onChange={(e) => SetPetfirstName(e.target.value)}
                  error={Boolean(petFirstNameError)}
                  helperText={petFirstNameError}
                  className="input-field-styling required-field"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label',
                    shrink: 'true'
                  }}
                  InputProps={{}}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  fullWidth
                  name="Last name"
                  autocomplete="Last name"
                  label="LAST NAME"
                  value={petLastName}
                  placeholder="LAST NAME"
                  // defaultValue={franchiseeName}
                  onChange={(e) => SetPetLastName(e.target.value)}
                  // error={Boolean(franchiseeNameError)}
                  // helperText={franchiseeNameError}
                  className="input-field-styling"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee'
                  }}
                  InputProps={{}}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <FormControl
                  error={genderError}
                  fullWidth
                  className="select-drop-down-label-styling"
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="input-field-dropdown-styling required-label"
                  >
                    GENDER
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="GENDER"
                    value={gender}
                    // defaultValue={state}
                    className="input-field-styling"
                    onChange={(e) => SetGender(e.target.value)}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    {/* <MenuItem value="Other">Other</MenuItem> */}
                  </Select>
                  {genderError && (
                    <FormHelperText>Gender is required</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl
                  error={acquisitionSourceError}
                  fullWidth
                  className="select-drop-down-label-styling"
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="input-field-dropdown-styling required-label"
                  >
                    ACQUISITION SOURCE
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="ACQUISITION SOURCE"
                    value={acquisitionSource}
                    // defaultValue={state}
                    className="input-field-styling"
                    onChange={(e) => SetacquisitionSource(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 224,
                          overflowY: 'auto'
                        }
                      }
                    }}
                  >
                    {acquisitionList &&
                      acquisitionList.map((data) => (
                        <MenuItem
                          key={data.sortKey}
                          value={data.acquisitionSource}
                        >
                          {data.acquisitionSource}
                        </MenuItem>
                      ))}
                    {/* <MenuItem value="Shelter/Rescue">Shelter/Rescue</MenuItem>
                    <MenuItem value="Breeder">Breeder</MenuItem>
                    <MenuItem value="Friend/Family">Friend/Family</MenuItem>
                    <MenuItem value="Pet Store">Pet Store</MenuItem>
                    <MenuItem value="Others">Others</MenuItem> */}
                  </Select>
                  {acquisitionSourceError && (
                    <FormHelperText>
                      AcquisitionSource is required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl
                  error={breedError}
                  fullWidth
                  className="select-drop-down-label-styling"
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="input-field-dropdown-styling required-label"
                  >
                    BREED
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    className="input-field-styling"
                    label="BREED"
                    value={breed}
                    onChange={(e) => SetBreed(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 224,
                          overflowY: 'auto'
                        }
                      }
                    }}
                  >
                    {breeds.map((data) => (
                      <MenuItem value={data.breedName}>
                        {data.breedName}
                      </MenuItem>
                    ))}
                  </Select>
                  {breedError && (
                    <FormHelperText>Breed is required</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sm={5}
                md={4}
                className="form-datepicker-container"
              >
                {/* <TextField
                  fullWidth
                  name="Birthday"
                  autoComplete="off"
                  label="BIRTHDAY"
                  placeholder="BIRTHDAY"
                  value={birthDate}
                  type="date"
                  // defaultValue={franchiseeName}
                  onChange={(e) => SetBirthDate(e.target.value)}
                  // error={Boolean(birthDateError)}
                  helperText={birthDateError}
                  className="input-field-styling required-field"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label',
                    shrink: 'true'
                  }}
                  InputProps={{ min: minDateValue, max: currentDate }}
                /> */}
                {/* <CustomDatePicker
                  label="BIRTHDAY"
                  onChange={SetBirthDate}
                  required={Boolean(true)}
                  minDate={minDateValue}
                  maxDate={currentDate}
                  fieldValue={birthDate}
                /> */}
                <CustomDatePickerForAllBrowser
                  label={'BIRTHDAY'}
                  onChange={SetBirthDate}
                  required={Boolean(true)}
                  fieldValue={birthDate}
                  minDate={minDateValue}
                  maxDate={currentDate}
                />
                {birthDateError && (
                  <FormHelperText>Birth date is required</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sm={5} md={4}>
                {/* <Grid item xs={12} md={6} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={veterinarian}
                        className="check-box-span text-blue"
                        color="default"
                        sx={{
                          '&.Mui-checked': {
                            color: '#003087'
                          }
                        }}
                        onChange={(e) => SetVeterinarian(e.target.checked)}
                      />
                    }
                    label={
                      <Typography className="check-box-text" sx={{color: '#003087 !important'}}>
                        VETERINARIAN
                      </Typography>
                    }
                  />
                  {veterinarianError && <FormHelperText className="error-message">Veterinarian is required</FormHelperText>} 
                </Grid> */}
                {/* <Grid item xs={12} sm={4} md={4}> */}
                <FormControl
                  fullWidth
                  className="select-drop-down-label-styling"
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="input-field-dropdown-styling"
                  >
                    VETERINARIAN
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    className="input-field-styling"
                    label="VETERINARIAN"
                    value={veterinarian}
                    onChange={(e) => SetVeterinarian(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 224,
                          overflowY: 'auto'
                        }
                      }
                    }}
                  >
                    {veterinarianList &&
                      veterinarianList.map((data) => (
                        <MenuItem key={data.sortKey} value={data.businessName}>
                          {data.businessName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {/* </Grid> */}
              </Grid>
              {/* <Grid item xs={12} sm={4} md={4}>
                <TextField
                  fullWidth
                  name="Allergies"
                  autocomplete="Allergies"
                  label="ALLERGIES"
                  placeholder="Allergies"
                  value={allergies}
                  onChange={(e) => SetAllergies(e.target.value)}
                  // error={Boolean(allergiesError)}
                  // helperText={allergiesError}
                  className="input-field-styling"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee',
                    shrink: 'true'
                  }}
                  InputProps={{}}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={4} md={4}>
                <FormControl
                  fullWidth
                  className="select-drop-down-label-styling"
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="input-field-dropdown-styling"
                  >
                    ALLERGIES
                  </InputLabel>
                  <Select
                    multiple
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    className="input-field-styling"
                    label="ALLERGIES"
                    value={allergies}
                    onChange={(e) => SetAllergies(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 224,
                          overflowY: 'auto'
                        }
                      }
                    }}
                  >
                    {allergiesList &&
                      allergiesList.map((data) => (
                        <MenuItem key={data.sortKey} value={data.allergyName}>
                          {data.allergyName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid> */}
              {/* <Grid item xs={12} sm={4} md={4}>
                <TextField
                  fullWidth
                  id="Weight"
                  name="Weight"
                  label="WEIGHT"
                  className="input-field-styling"
                  type="text"
                  // placeholder="777-777-7777"
                  // onChange={(e) => SetMobileNumber(e.target.value)}
                  onChange={SetWeight}
                  value={weight}
                  // error={Boolean(weightError)}
                  // helperText={weightError}
                  InputLabelProps={{
                    className: 'label-style-create-franchisee'
                  }}
                  // inputProps={{ maxLength: 12 }}

                  InputProps={{
                    endAdornment: (
                      <span style={{ fontWeight: 'bold', fontSize: '1.25em' }}>
                        lb
                      </span>
                    )
                  }}
                />
              </Grid> */}

              <Grid
                item
                xs={12}
                sm={1}
                md={1}
                sx={
                  {
                    // marginRight: '10px'
                  }
                }
              >
                <Avatar
                  src={imgUrl !== '' ? imgUrl : null}
                  sx={{ height: '50px', width: '50px' }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                md={3}
                sx={
                  {
                    // marginLeft: '20px'
                  }
                }
              >
                <Box>
                  <Typography className="check-box-text">
                    Edit Profile Image
                  </Typography>
                  <input
                    accept="image/*"
                    multiple
                    type="file"
                    id="select-child-file"
                    name="profileImage"
                    style={{ display: 'none' }}
                    onChange={(event) => handleImageUpload(event)}
                  />
                  <label htmlFor="select-child-file">
                    <Button
                      variant="contained"
                      component="span"
                      className="upload-image"
                      sx={{ marginTop: '6px !important' }}
                    >
                      <Typography className="upload-text">Upload</Typography>
                    </Button>
                  </label>
                </Box>
                {profileImageError && (
                  <FormHelperText>{profileImageError}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Typography className="heading-text">
                  Tell us about your dog
                </Typography>
              </Grid>

              <Grid item xs={12} md={6} sm={6}>
                <Box
                  className="radio-button-heading"
                  sx={{ marginTop: '15px' }}
                >
                  Does your dog bark at other dogs when out on a walk or at you
                  for attention?
                </Box>
                <FormControl className=" radio-button-text">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={bark}
                    name="radio-buttons-group"
                    onChange={(e) => SetBark(e.target.value)}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <FormControlLabel
                        value="Yes"
                        control={<Radio className="radio-button-style" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio className="radio-button-style" />}
                        label="No"
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <Box
                  className="radio-button-heading"
                  sx={{ marginTop: '15px' }}
                >
                  Is your dog afraid of stranger or certain groups of people?
                </Box>
                <FormControl className=" radio-button-text">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={strangers}
                    name="radio-buttons-group"
                    onChange={(e) => SetStrangers(e.target.value)}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <FormControlLabel
                        value="Yes"
                        control={<Radio className="radio-button-style" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio className="radio-button-style" />}
                        label="No"
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} sm={6}>
                <Box
                  className="radio-button-heading"
                  sx={{ marginTop: '15px' }}
                >
                  Has your dog ever bitten a person or another dog, regardless
                  or injury(not including nipping or play biting)?
                </Box>
                <FormControl className=" radio-button-text">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={biting}
                    name="radio-buttons-group"
                    onChange={(e) => SetBiting(e.target.value)}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <FormControlLabel
                        value="Yes"
                        control={<Radio className="radio-button-style" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio className="radio-button-style" />}
                        label="No"
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <Box
                  className="radio-button-heading"
                  sx={{ marginTop: '15px' }}
                >
                  Is your dog very possessive about his/her food or toys?
                </Box>
                <FormControl className=" radio-button-text">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={possessive}
                    name="radio-buttons-group"
                    onChange={(e) => SetPossessive(e.target.value)}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <FormControlLabel
                        value="Yes"
                        control={<Radio className="radio-button-style" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio className="radio-button-style" />}
                        label="No"
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <Box
                  className="radio-button-heading"
                  sx={{ marginTop: '15px' }}
                >
                  Do you believe your dog is able to skip basic obedience and
                  take advanced classes or agility?
                </Box>
                <FormControl className=" radio-button-text">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={advanced}
                    name="radio-buttons-group"
                    onChange={(e) => SetAdvanced(e.target.value)}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <FormControlLabel
                        value="Yes"
                        control={<Radio className="radio-button-style" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio className="radio-button-style" />}
                        label="No"
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <Box
                  className="radio-button-heading"
                  sx={{ marginTop: '15px' }}
                >
                  Is your dog social?
                </Box>
                <FormControl className=" radio-button-text">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={social}
                    name="radio-buttons-group"
                    onChange={(e) => SetSocial(e.target.value)}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <FormControlLabel
                        value="Yes"
                        control={<Radio className="radio-button-style" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio className="radio-button-style" />}
                        label="No"
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} md={6} sm={6}>
                <Grid item xs={12} md={11} sm={11}>
                  <TextField
                    fullWidth
                    multiline
                    id="my-textarea"
                    name="Notes"
                    autocomplete="Notes"
                    label="NOTES"
                    placeholder="Notes here . . . "
                    variant="outlined"
                    value={petNotes}
                    className="text-area-styl"
                    onChange={(e) => descriptionSet(e.target.value)}
                    InputLabelProps={{
                      className: 'label-style-create-franchisee'
                    }}
                    InputProps={{ rows: 6 }}
                  />
                </Grid>
              </Grid> */}
              <Grid item xs={12} md={6} sm={6} className="-d-none">
                <Typography className="text-blue font-16 weight-600">
                  COMPLETED
                </Typography>
                <Grid container>
                  <Grid item xs={12} md={6} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={orientation}
                          className="check-box-span text-blue"
                          color="default"
                          sx={{
                            '&.Mui-checked': {
                              color: '#003087'
                            }
                          }}
                          onChange={(e) => SetOrientation(e.target.checked)}
                        />
                      }
                      label={
                        <Typography className="check-box-text">
                          Orientation
                        </Typography>
                      }
                    />
                    {/* {orientationError && (
                            <FormHelperText>
                              Orientation is required
                            </FormHelperText>
                          )} */}
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={evaluation}
                          className="check-box-span text-blue"
                          color="default"
                          sx={{
                            '&.Mui-checked': {
                              color: '#003087'
                            }
                          }}
                          onChange={(e) => SetEvaluation(e.target.checked)}
                        />
                      }
                      label={
                        <Typography className="check-box-text">
                          Evaluation
                        </Typography>
                      }
                    />
                    {/* {evaluationError && (
                            <FormHelperText>
                              Evaluation is required
                            </FormHelperText>
                          )} */}
                  </Grid>
                </Grid>
                <Grid container sx={{ marginTop: '5px' }}>
                  <Grid item xs={12} md={6} sm={6}>
                    <FormControlLabel
                      // control={
                      //   <Checkbox
                      //     checked={agility}
                      //     className="check-box-span"
                      //     color="default"
                      //     sx={{
                      //       '&.Mui-checked': {
                      //         color: '#003087'
                      //       }
                      //     }}
                      control={
                        <Checkbox
                          checked={agility}
                          className="check-box-span text-blue"
                          color="default"
                          sx={{
                            '&.Mui-checked': {
                              color: '#003087'
                            }
                          }}
                          onChange={(e) => SetAgility(e.target.checked)}
                        />
                      }
                      label={
                        <Typography className="check-box-text">
                          Agility
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tricks}
                          className="check-box-span text-blue"
                          color="default"
                          sx={{
                            '&.Mui-checked': {
                              color: '#003087'
                            }
                          }}
                          onChange={(e) => SetTricks(e.target.checked)}
                        />
                      }
                      label={
                        <Typography className="check-box-text">
                          Tricks
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Typography
              className="header-blue-22"
              sx={{ marginTop: '15px', marginBottom: '15px' }}
            >
              Vaccination Details
            </Typography>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={5}
                md={4}
                className="form-datepicker-container"
              >
                {/* <CustomDatePicker
                  label="RABIES EXPIRATION"
                  onChange={SetRabiesExpiration}
                  minDate={currentDate}
                  fieldValue={rabiesExpiration}
                /> */}
                <CustomDatePickerForAllBrowser
                  label={'RABIES EXPIRATION'}
                  onChange={SetRabiesExpiration}
                  fieldValue={rabiesExpiration}
                  minDate={currentDate}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={4}
                className="form-datepicker-container"
              >
                {/* <CustomDatePicker
                  label="BORDETELLA EXPIRATION"
                  onChange={SetBordetellaExpiration}
                  minDate={currentDate}
                  fieldValue={bordetellaExpiration}
                /> */}
                <CustomDatePickerForAllBrowser
                  label={'BORDETELLA EXPIRATION'}
                  onChange={SetBordetellaExpiration}
                  fieldValue={bordetellaExpiration}
                  minDate={currentDate}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={4}
                className="form-datepicker-container"
              >
                {/* <CustomDatePicker
                  label="COMBO SHOT EXPIRATION DATE"
                  onChange={SetDhppExpiration}
                  minDate={currentDate}
                  fieldValue={dhppExpiration}
                /> */}
                <CustomDatePickerForAllBrowser
                  label={'COMBO SHOT EXPIRATION'}
                  onChange={SetDhppExpiration}
                  fieldValue={dhppExpiration}
                  minDate={currentDate}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} sm={4} md={4}>
                <Box
                  className="Profile-parent"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <label
                    htmlFor="select-child-profile"
                    className="label-select"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    ATTACH DOCUMENT
                  </label>
                  <input
                    type="file"
                    accept=".pdf, .doc, .heif, .hevc, .jpeg, .png, .heic, .mp4, .webp, .jpg"
                    multiple
                    hidden
                    id="select-child-profile"
                    // onChange={handleChildFileUpload}
                    onChange={(event) => handleFileInput(event)}
                  />
                </Box>
                {/* <i className='text-blue headerTopCloseIcon p-5'>{multipleFile.length} of 5 files uploaded</i> */}
              </Grid>
              <Grid item xs={12} sm={8} md={8}>
                <Box className="location-details-label font-16">
                  ATTACHED DOCUMENT
                </Box>
                <Box className="location-details-value">
                  {documentList && documentList?.length > 0 ? (
                    documentList?.map((item, index) => {
                      // let arr = item?.documentUrl?.split('-');
                      //   let name = arr[arr.length - 1];
                      //   name = name && decodeURIComponent(name);
                      // render the document list with comma separated

                      return (
                        <span key={index}>
                          <a
                            href={item.documentUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="text-grey"
                          >
                            {/* {item.documentName} */}
                            {/* {item.documentUrl.split('-')[5] || 'View Document'} */}
                            {item.documentName || 'View Document'}
                          </a>
                          <span
                            style={{
                              color: 'black',
                              margin: '5px',
                              cursor: 'pointer'
                            }}
                            onClick={() => deletePetDocument(item)}
                          >
                            (Remove File)
                          </span>
                          {index !== documentList.length - 1 ? ', ' : ''}
                        </span>
                      );
                    })
                  ) : (
                    <span>No document attached</span>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              mt={1}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-start'}
            >
              <Box sx={{ float: 'left', clear: 'both' }} ml={1}>
                {/* <i>{multipleFile.length} of 5 files uploaded</i> */}
                {imageError && (
                  <FormHelperText className="error-message">
                    {imageError}
                  </FormHelperText>
                )}
              </Box>
              <Box className="card-image-carousel m- ml-10">
                {/* Render the uploaded images */}
                {multipleFile?.map((file, index) => (
                  <div
                    className="single-uploaded-image single-uploaded-files"
                    key={file.id}
                  >
                    <Box className="word-break" id={index}>
                      {file.name}
                    </Box>
                    <Box
                      className="remove-imag remove-file text-blue"
                      onClick={() => removeFile(file.id)}
                    >
                      x
                    </Box>
                  </div>
                ))}
              </Box>
            </Grid>
            <Grid spacing={2}>
              {/* <Box
                sx={{
                  marginTop: '20px',
                  display: 'flex',
                  justifyContent: 'end'
                }}
              >
                <Button
                  sx={{ mt: { xs: 0, md: 0 } }}
                  variant="contained"
                  type="submit"
                  className="btn-primary"
                >
                  Update
                </Button>
              </Box> */}
              <Grid item xs={12} sm={12}>
                {showError && (
                  <Box className="error-message">{errorMessage}</Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      {/* confirmation dialog */}
      <Dialog
        open={openSuccess}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleSuccessClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
        className={!openSuccess ? 'closeEditClient' : ''}
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? '' : 'Save Changes'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  Please confirm that you want to save your changes?
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Dog details has been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() =>
              isUpdated ? handleSuccessClose('Discard') : setSuccessOpen(false)
            }
          >
            {isUpdated ? 'Close' : 'Go Back to Editing'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleSuccessClose('Save')}
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* confirmation dialog */}

      <Dialog
        open={openConfirmPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
        className={!openConfirmPopup ? 'closeEditClient' : ''}
      >
        <DialogTitle className="alert-title align-center">
          Discard Changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You have unsaved changes.
                <Typography className="title-text-sub">
                  What do you want to do?{' '}
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmClose('Discard')}
          >
            Discard Changes
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmClose('Save')}
          >
            Go Back to Editing
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditPet;
