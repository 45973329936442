import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Autocomplete,
  Slide,
  FormHelperText
} from '@mui/material';
import { ApiService } from '../../../../services/ApiService';

import { CustomDatePicker } from 'src/components/CustomDatePicker';
import Loader from 'src/components/Loader';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function EditClientAlerts({
  openAlertNotesDialog,
  setOpenAlertNotesDialog,
  selectedItem,
  setInvokeRefresh
}) {
  const [commentNotes, setCommentNotes] = useState('');
  const [expDate, setExpDate] = useState('');
  /* States  to set the selected alert type */
  const [alertType, setAlertType] = useState('');
  /* States to show the alert list name in select drop down */
  const [alertList, setAlertList] = useState([]);

  const [alertTypeError, setAlertTypeError] = useState('');

  /* States for error messages */
  const [commentNotesError, setCommentNotesError] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [isFieldUpdated, setisFieldUpdated] = useState(false);

  useEffect(() => {
    getAlertType();
  }, []);

  useEffect(() => {
    if (selectedItem) {
      setCommentNotes(selectedItem?.comment);
      setExpDate(selectedItem?.expireDate);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (alertList?.length > 0 && selectedItem?.alertTypeId) {
      setAlertType(selectedItem?.alertTypeId);
    }
  }, [alertList]);

  /* Function to get staff members */

  const getAlertType = async () => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}alerts?alertTypeFor=${selectedItem?.alertFor}`
      );
      let data = response;
      if (data?.statusCode && !data?.error) {
        setAlertList(data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  /* Function to close the main dialog box */
  const handleClose = () => {
    setOpenAlertNotesDialog(false);
  };

  /* Function to set staff members selected */

  const selectAlertType = (val) => {
    setAlertType(val);
    setAlertTypeError('');
    setisFieldUpdated(true);
  };

  /* Function to set task details  */
  const handleCommentNotes = (val) => {
    setCommentNotesError('');
    setCommentNotes(val);
    setisFieldUpdated(true);
  };

  /* Function to set EXPIRATION date */
  const ExpirationDate = (date) => {
    if (date === '01-01-1970') {
      return;
    }

    setExpDate(date);
    setisFieldUpdated(true);
  };

  const editAlert = async () => {
    setIsLoading(true);
    const findAlert = alertList?.find((item) => item?.sortKey === alertType);
    let encodedAlertId = encodeURIComponent(selectedItem?.alertId);
    let clientid = localStorage.getItem('clientId');
    console.log(findAlert, 'selectedItemselectedItemselectedItem');

    try {
      let url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}client/editAlert?id=${encodedAlertId}`;
      let obj = {
        updatedBy: selectedItem?.createdBy,
        updatedByName: selectedItem?.createdByName,
        clientId: selectedItem?.clientId || clientid,
        alertTypeName: findAlert?.alertType,
        comment: commentNotes,
        expireDate: expDate,
        alertTypeId: findAlert?.alertId,
        alertColor: findAlert?.colorLabel,
        status: 1,
        alertFor: selectedItem?.alertFor,
        ...(selectedItem?.alertFor === 'Dog'
          ? { petId: selectedItem?.petId }
          : {}),
        ...(selectedItem?.alertFor === 'Dog'
          ? { petName: selectedItem?.petName }
          : {})
      };

      const response = await ApiService.put(`${url}`, obj);

      if (response?.statusCode === 200) {
        setIsLoading(false);
        setShowSuccessMessage(true);
        setSuccessMessage(response?.message);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setShowMessage(true);
      setErrorMessage(err?.message || 'Internal Server Error');
    }
  };

  /* Function to validateFields */
  const validateFields = () => {
    let data = Object.keys(alertType).length;

    if (data === 0) {
      setAlertTypeError('Please select alert type');
    }

    if (!commentNotes) {
      setCommentNotesError(`Comments is required`);
      return;
    }

    if (commentNotes && data > 0 && isFieldUpdated) {
      editAlert();
    }
  };

  const closePopup = () => {
    setShowSuccessMessage(false);
    setOpenAlertNotesDialog(false);
    setInvokeRefresh(true);
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setSuccessMessage}
          closePopup={closePopup}
        />
      )}
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      <Dialog
        open={openAlertNotesDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="details-dialog"
        className="dialog-box-right-addTask"
        maxWidth="sm"
      >
        <DialogTitle
          className="alert-title align-right"
          sx={{ backgroundColor: '#FFFFFF ! important' }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography className="page-header weight-600">
              Edit Alert
            </Typography>
            {/* remove icon */}
            <Button
              className="details-remove-icon task-cross p-0 no-hover"
              onClick={() => handleClose('Close')}
            >
              <svg
                width="30"
                height="31"
                viewBox="0 0 30 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 27.2695C8.385 27.2695 3 21.8845 3 15.2695C3 8.65453 8.385 3.26953 15 3.26953C21.615 3.26953 27 8.65453 27 15.2695C27 21.8845 21.615 27.2695 15 27.2695ZM15 0.269531C6.705 0.269531 0 6.97453 0 15.2695C0 23.5645 6.705 30.2695 15 30.2695C23.295 30.2695 30 23.5645 30 15.2695C30 6.97453 23.295 0.269531 15 0.269531ZM18.885 9.26953L15 13.1545L11.115 9.26953L9 11.3845L12.885 15.2695L9 19.1545L11.115 21.2695L15 17.3845L18.885 21.2695L21 19.1545L17.115 15.2695L21 11.3845L18.885 9.26953Z"
                  fill="#E64646"
                />
              </svg>
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: '#FFFFFF ! important' }}>
          <Box>
            <Grid container className="grid-containe">
              <Grid item xs={12} sm={12}>
                <>
                  <Autocomplete
                    // multiple
                    value={
                      alertList?.find(
                        (option) => option?.sortKey === alertType
                      ) || null
                    }
                    className="select-staff required-field"
                    options={alertList || []}
                    getOptionLabel={(option) => option.alertType}
                    onChange={(event, selectedOptions) => {
                      // const selectedType = selectedOptions.map((option) => {
                      //   return {
                      //     id: option.sortKey,
                      //     name: option.alertType
                      //   };
                      // });
                      selectAlertType(selectedOptions?.sortKey);
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option?.sortKey}>
                          {option?.alertType}
                        </li>
                      );
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label={params?.label || 'ALERT TYPE'}
                          className="field-top-margin-edit-location select-field "
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee select-staff required-label'
                          }}
                        />
                      );
                    }}
                  />
                  <FormHelperText>{alertTypeError}</FormHelperText>
                </>
              </Grid>

              <Grid item xs={12} sm={12} className="w-100vh">
                <TextField
                  fullWidth
                  multiline
                  id="my-textarea"
                  name="comments"
                  label={'COMMENTS'}
                  value={commentNotes}
                  onChange={(e) => handleCommentNotes(e.target.value)}
                  error={Boolean(commentNotesError)}
                  helperText={commentNotesError}
                  placeholder="Type here..."
                  variant="outlined"
                  className={`field-top-margin-edit-location required-field`}
                  InputLabelProps={{
                    className: `label-style-create-franchisee required-label`
                  }}
                  InputProps={{ rows: 6, style: { color: 'black' } }}
                />
              </Grid>

              <Grid item xs={12} sm={12} className="form-datepicker-container">
                <CustomDatePicker
                  label="EXPIRATION DATE (Optional)"
                  fieldValue={expDate}
                  onChange={ExpirationDate}
                  minDate={new Date()}
                  className="field-top-margin-edit-location show"
                />
              </Grid>

              <Grid container className="grid-containe">
                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="button-style-add-task field-top-margin-edit-location"
                    onClick={validateFields}
                    sx={{ marginTop: '20px' }}
                  >
                    <Typography className="submit-button-text">
                      Update Now
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default EditClientAlerts;
