import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Grid,
  Slide,
  styled,
  Typography,
  Card,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination
} from '@mui/material';
import Loader from '../../../../components/Loader';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import BundleEdit from './BundleEdit';
import { getLocalData } from 'src/utils';
import { ApiService } from '../../../../services/ApiService';
import { DeleteIcon, ArrowLeft } from 'src/assets/SVG/SvgIcons';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
function BundlesDetails({
  openEditPage,
  setOpenEditPage,
  isOpenEdit,
  setIsOpenEdit,
  selectedData,
  getBundles,
  setShowScreen
}) {
  let details = selectedData[0];
  console.log(details);
  let item = details && selectedData[0]?.items;
  let itemPrice = selectedData[0].items[0].pricingOption;
  console.log(item);
  console.log(itemPrice);
  let userId = getLocalData('userId');
  console.log(userId);
  console.log(details.locationId);
  console.log(details.bundleId);
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText(details.bundleName);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const BundleDetails = styled(Container)(
    ({ theme }) => `margin-top: ${theme.spacing(2)};`
  );
  const navigate = useNavigate();
  useEffect(() => {
    let isLogin = getLocalData('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
  }, [navigate]);
  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  /* Function to close the supplier details page */
  const handleClose = (val) => {
    if (val === 'Cancel') {
      setOpenDeleteDialog(false);
    } else if (val === 'message-close') {
      setOpenMessageDialog(false);
      setShowScreen(0);
      getBundles();
    } else if (val === 'Delete') {
      setOpenDeleteDialog(false);
      deleteRecord();
    }
  };
  const deleteRecord = async () => {
    setIsLoading(true);
    let locationId = encodeURIComponent(details.locationId);
    let bundleId = encodeURIComponent(details.bundleId);
    let usrId = encodeURIComponent(userId);
    console.log(usrId + 'userIduserId');
    try {
      console.log(userId + 'userIduserId');
      let response = await ApiService.delete(
        `${process.env.REACT_APP_API_END_POINT}bundles?locationId=${locationId}&bundleId=${bundleId}&userId=${usrId}`
      );
      if (response.statusCode && !response.error) {
        setIsLoading(false);
        setOpenMessageDialog(true);
        getBundles();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handleClosePricingOptionDetails = () => {
  //   setShowScreen(0);
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedData = item.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleClickEdit = () => {
    setIsOpenEdit(true);
    setOpenEditPage(true);
  };

  const handleCloseDetails = () => {
    setShowScreen(0);
  };
  return (
    <>
      <Loader IsLoading={isLoading} />
      <BundleDetails maxWidth={'100%'} className="location-details-wrapper">
        <Container maxWidth="100%">
          {/* <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          > */}
          {/* <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box onClick={() => handleClosePricingOptionDetails()} className="back-arrow-close"> */}
          {/* Left arrow icon */}
          {/* <ArrowLeft />
                </Box>
                <Typography className="header-blue-32">{details.bundleName}</Typography>
              </Box>
            </Grid> */}
          {/* <Grid item> */}
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box
                  onClick={() => handleCloseDetails()}
                  className="back-arrow-close"
                >
                  {/* left arrow icon */}
                  <ArrowLeft />
                </Box>

                <Typography className="header-blue-32">
                  Bundle Details
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  className="details-remove-icon no-hover"
                  onClick={handleClickOpenDeleteDialog}
                >
                  {/* Delete Icon */}
                  <DeleteIcon />
                </Button>
                {/* Edit Button */}
                <Button
                  sx={{ mt: { xs: 0, md: 0 } }}
                  variant="contained"
                  onClick={handleClickEdit}
                  className="btn-primary edit-button"
                >
                  Edit
                </Button>
              </Box>
            </Grid>
          </Grid>

          {/* </Grid> */}
          {/* </Grid> */}
          <Box className="user-details-container">
            <Grid container mt={3} className="details-row">
              <Grid item xs={12} sm={1.5} className="details-col">
                <Box className="details-label">BUNDLE NAME</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="details-col">
                <Box className="details-value">{details.bundleName}</Box>
              </Grid>
              <Grid item xs={12} sm={1.5} className="details-col">
                <Box className="details-label">START DATE</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="details-col">
                <Box className="details-value">{details.startDate}</Box>
              </Grid>
            </Grid>
            <Grid container mt={2} className="details-row">
              <Grid item xs={12} sm={1.5} className="details-col">
                <Box className="details-label">BUNDLE SKU</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="details-col">
                <Box className="details-value">{details.sku}</Box>
              </Grid>
              <Grid item xs={12} sm={1.5} className="details-col">
                <Box className="details-label">END DATE</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="details-col">
                <Box className="details-value">{details.endDate}</Box>
              </Grid>
            </Grid>

            <Grid container mt={2} className="details-row">
              <Grid item xs={12} sm={1.5} className="details-col">
                <Box className="details-label">BUNDLE PRICE</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="details-col">
                <Box className="details-value">
                  ${parseFloat(details.bundlePrice)?.toFixed(2)}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1.5} className="details-col">
                <Box className="details-label">DESCRIPTION</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="details-col ">
                <Box className="bundleDesc">{details.description}</Box>
              </Grid>
            </Grid>
            <Grid container mt={2} className="details-row">
              <Grid item xs={12} sm={1.5} className="details-col">
                <Box className="details-label">Discount</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="details-col">
                <Box className="details-value">
                  {parseFloat(details.discount)?.toFixed(2)}%
                </Box>
              </Grid>
              <Grid item xs={12} sm={1.5} className="details-col">
                <Box className="details-label">SellOnline</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="details-col ">
                <Box className="bundleDesc">
                  {details.sellOnline ? 'Yes' : 'No'}
                </Box>
              </Grid>
            </Grid>

            {/* <Grid container mt={2} className="details-row">
              <Grid item xs={6} sm={6} className="details-col"> </Grid>
              <Grid item xs={6} sm={1.5} className="details-col">
                <Box className="details-label">BUNDLE PRICE</Box>
              </Grid>
              <Grid item xs={6} sm={4.5} className="details-col">
                <Box className="details-value">$ {details.bundlePrice}</Box>
              </Grid>
            </Grid> */}
          </Box>
        </Container>
        <h1 className="header-bundle-item">All Items</h1>
        <Container maxWidth="100%">
          <Card>
            <TableContainer className="featured-table-container">
              <Table>
                <TableHead className="feature-table-head">
                  <TableRow>
                    <TableCell>Item Names</TableCell>
                    <TableCell>variants</TableCell>
                    {/* <TableCell>Pricing Options</TableCell> */}
                    <TableCell>Categories</TableCell>
                    <TableCell>Price</TableCell>
                    {/* <TableCell>Qty</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody className="table-body-data">
                  {paginatedData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell className="table-cell-data">
                        {row.itemName}
                      </TableCell>
                      <TableCell className="table-cell-data">
                        {(row?.variants &&
                          row?.variants
                            ?.filter((variant) => variant?.value)
                            ?.map?.((item, index, array) =>
                              index === array.length - 1
                                ? item?.value
                                : `${item?.value}, `
                            )
                            .join('')) ||
                          '-'}
                      </TableCell>
                      {/* <TableCell className="table-cell-data">{row.itemType}</TableCell> */}
                      {/* <TableCell className="table-cell-data">{row?.pricingOption?.name ? row?.pricingOption?.name : ''}</TableCell> */}
                      <TableCell className="table-cell-data">
                        {row.itemCategoryName}
                      </TableCell>
                      <TableCell className="table-cell-data">
                        {row.price
                          ? `$${parseFloat(row.price)?.toFixed(2)}`
                          : '$0.00'}
                      </TableCell>
                      {/* <TableCell className="table-cell-data">-</TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={item.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
        {isOpenEdit ? (
          <BundleEdit
            selectedData={selectedData}
            openEditPage={openEditPage}
            setOpenEditPage={setOpenEditPage}
            setIsOpenEdit={setIsOpenEdit}
          />
        ) : null}
      </BundleDetails>
      <>
        <Dialog
          open={openDeleteDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleClose('Cancel')}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className="alert-title align-center">
            {'Deactivate'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="alert-decription align-center aloi"
            >
              <Box display="flex" justifyContent="center" direction="column">
                <Typography className="title-text-sub">
                  Are you sure you want to deactivate this record?
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions className="alert-button-control">
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Delete')}
            >
              Deactivate
            </Button>
            <Divider className="vertical-divider" />
            <Button
              className="font-17 cancel-btn no-hover"
              onClick={() => handleClose('Cancel')}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openMessageDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleClose('message-close')}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="alert-decription align-center"
            >
              <Box display="flex" justifyContent="center" direction="column">
                <Typography className="title-text-sub">
                  Selected bundle has been deactivate successfully!
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions className="alert-button-control">
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('message-close')}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
}

export default BundlesDetails;
