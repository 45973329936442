import { Box, Stack, Card, Grid, CardHeader } from '@mui/material';
import { useSalesDetailFunctions } from './SalesDetailFunctions';
import ReturnItems from './ItemsTable';

export default function DetailBox({
  clientDetail,
  tableData,
  detail,
  setActive,
  selectedOrderId
}) {
  const { dateFormate } = useSalesDetailFunctions(
    detail,
    setActive,
    selectedOrderId
  );
  return (
    <Box className="detailbox-main">
      <Box className="detail-section" sx={{
            gap: {
              sm: '5px!important',
              
            }
          }}>
        <Box
          className="section-main"
          sx={{
            flexDirection: {
              xs: 'column !important',
              sm: 'column !important',
              md: 'row !important'
            }
          }}
        >
          <Box>
            <div>PAYMENT METHOD :</div>
            <div>{clientDetail?.paymentSourceType || '-'}</div>
          </Box>
          <Box>
            <Stack>PURCHASE DATE :</Stack>
            <Stack>
              {' '}
              {clientDetail?.orderDate
                ? dateFormate(clientDetail.orderDate)
                : clientDetail?.dateOfPurchase &&
                  dateFormate(clientDetail?.dateOfPurchase)}
            </Stack>
          </Box>
        </Box>
        <Box
          className="section-main"
          sx={{
            flexDirection: {
              xs: 'column !important',
              sm: 'column !important',
              md: 'row !important'
            }
          }}
        >
          <Box>
            <Stack>TRANSACTION ID :</Stack>
            <Stack>
              {clientDetail?.transactionId || clientDetail?.orderId}
            </Stack>
          </Box>
          <Box>
            <Stack>PAYMENT STATUS:</Stack>
            <Stack>{clientDetail?.sqaureOrderState || ''}</Stack>
          </Box>
          
        </Box>
        <Box
          className="section-main"
          sx={{
            flexDirection: {
              xs: 'column !important',
              sm: 'column !important',
              md: 'row !important'
            }
          }}
        >
          <Box>
            <Stack>TOTAL TRANSACTION AMOUNT :</Stack>
            <Stack>
              {clientDetail?.totalSquareAmountWithTaxInDollar
                ? `$${parseFloat(
                    clientDetail?.totalSquareAmountWithTaxInDollar
                  )?.toFixed(2)}`
                : '$0.00'}
            </Stack>
          </Box>
          <Box>
            <Stack>DISCOUNT:</Stack>
            <Stack>
              {clientDetail?.totalSquareDiscountAmountInDollar
                ? `$${parseFloat(
                    clientDetail?.totalSquareDiscountAmountInDollar
                  )?.toFixed(2)}`
                : '$0.00'}
            </Stack>
          </Box>
        </Box>
        <Box
          className="section-main"
          sx={{
            flexDirection: {
              xs: 'column !important',
              sm: 'column !important',
              md: 'row !important'
            }
          }}
        >
          <Box>
            <Stack>DOG :</Stack>
            <Stack>{clientDetail?.petName || ''}</Stack>
          </Box>
          <Box>
            <Stack>REFUND AMOUNT:</Stack>
            <Stack>
              {clientDetail?.refundAmount
                ? `$${parseFloat(clientDetail?.refundAmount)?.toFixed(2)}`
                : ''}
            </Stack>
          </Box>
          
        </Box>
        <Box
          className="section-main"
          sx={{
            flexDirection: {
              xs: 'column !important',
              sm: 'column !important',
              md: 'row !important'
            }
          }}
        >
          <Box>
            <Stack>REFUND PAYMENT STATUS:</Stack>
            <Stack>{clientDetail?.refundStatus || ''}</Stack>
          </Box>
          <Box>
            {clientDetail?.last4 && (
              <>
                <Stack>LAST FOUR DIGITS OF CARD NUMBER :</Stack>
                <Stack>
                {clientDetail?.last4 && clientDetail?.last4}
                </Stack>
              </>
            )}
          </Box>
        </Box>
      </Box>
      {clientDetail?.items && (
        <Box className="detail-table">
          <Card
            sx={{
              padding: '15px',
              borderRadius: '19px 19px 0px 0px'
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                sm={5}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '12px'
                }}
              >
                <CardHeader
                  title="All Locations"
                  className="table-header-name"
                  sx={{ fontSize: '20px', color: '#003087' }}
                />
              </Grid>
            </Grid>

            <ReturnItems
              tableData={tableData}
              detail={detail}
              setActive={setActive}
              selectedOrderId={selectedOrderId}
              clientDetail={clientDetail}
            />
          </Card>
        </Box>
      )}
    </Box>
  );
}
