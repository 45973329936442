// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
// import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BusinessIcon from '@mui/icons-material/Business';
// import EdgesensorLowOutlinedIcon from '@mui/icons-material/EdgesensorLowOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import PolicyIcon from '@mui/icons-material/Policy';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
// import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
// import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import {
  PosIcon,
  DashboardIcon,
  InventoryIcon
} from '../../../../assets/SVG/SvgIcons';

export const Menus = [
  {
    title: 'Dashboard',
    path: '/home',
    icon: <DashboardIcon />,
    roles: [
      'Admin',
      'Super Manager',
      'Owner',
      'Manager',
      // 'Front Desk',
      // 'Trainer',
      'SuperAdmin'
    ]
  },
  {
    title: 'Dashboard',
    path: '/staff-dashboard',
    icon: <SpeedOutlinedIcon />,
    roles: ['Front Desk', 'Trainer']
  },
  // {
  //   title: 'Franchise Management',
  //   path: '/franchisee-management',
  //   icon: <StorefrontOutlinedIcon />,
  //   roles: ['Admin', 'Super Manager']
  // },
  // {
  //   title: 'Location Management',
  //   path: '/location-management',
  //   icon: <PinDropOutlinedIcon />,
  //   roles: ['Admin', 'Super Manager']
  // },
  // {
  {
    title: 'Franchises',
    path: '/',
    icon: <StorefrontOutlinedIcon />,
    roles: [],
    childrens: [
      {
        title: 'Franchise Management',
        path: '/franchisee-management',
        icon: '',
        roles: ['Admin', 'Super Manager']
      },
      {
        title: 'Location Management',
        path: '/location-management',
        icon: '',
        roles: ['Admin', 'Super Manager']
      }
    ]
  },
  {
    title: 'Business Operations',
    path: '/',
    icon: <BusinessIcon />,
    roles: [],
    childrens: [
      {
        title: 'Products',
        path: '/product-management',
        icon: '',
        roles: ['Admin', 'Super Manager']
      },
      {
        title: 'Products',
        path: '/franchise-products',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin']
      },
      {
        title: 'Services',
        path: '/services',
        icon: '',
        roles: ['Admin', 'Super Manager']
      },
      {
        title: 'Services',
        path: '/franchise-services',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin']
      },

      {
        title: 'Suppliers',
        path: '/manage-suppliers',
        icon: '',
        roles: []
      },
      {
        title: 'Pricing Options',
        path: '/pricing-options',
        icon: '',
        roles: ['Admin', 'Owner', 'Manager', 'SuperAdmin']
      },
      {
        title: 'Business Entities',
        path: '/business-entities',
        icon: '',
        roles: []
      },
      {
        title: 'Trophies',
        path: '/trophy-management',
        icon: '',
        roles: ['Admin', 'Super Manager']
      },
      {
        title: 'Bundles',
        path: '/bundles-management',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin'] // , 'Admin', 'Super Manager'
      },
      {
        title: 'Bundles',
        path: '/franchisor-bundle',
        icon: '',
        roles: ['Admin', 'Super Manager']
      },
      {
        title: 'Contracts',
        path: '/contract-management',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin'] // , 'Admin', 'Super Manager'
      },
      {
        title: 'Contracts',
        path: '/franchisor-contract',
        icon: '',
        roles: ['Admin', 'Super Manager']
      },
      {
        title: 'Gift Cards',
        path: '/gift-cards',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin'] // , 'Admin', 'Super Manager'
      },
      {
        title: 'Memberships',
        path: '/memberships',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin']
      },
      {
        title: 'Memberships',
        path: '/franchisor-memberships',
        icon: '',
        roles: ['Admin', 'Super Manager']
      },
      {
        title: 'Promotions',
        path: '/promotion-management',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin'] // , 'Admin', 'Super Manager'
      },
      {
        title: 'Promotions',
        path: '/franchisor-promotion',
        icon: '',
        roles: ['Admin', 'Super Manager']
      }
    ]
  },
  {
    title: 'Scheduling',
    path: '/schedules',
    icon: <CalendarMonthOutlinedIcon />,
    roles: [],
    childrens: [
      {
        title: 'Schedules',
        path: '/schedules',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin']
      },
      {
        title: 'Rooms',
        path: '/rooms',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin']
      },
      {
        title: 'Operating Hours',
        path: '/operating-hours',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin']
      },
      {
        title: 'Staff Timeoff',
        path: '/staff-timeoff',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin']
      },
      {
        title: 'Holiday',
        path: '/holiday',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin']
      }
    ]
  },
  {
    title: 'Clients',
    path: '/client-management',
    icon: <GroupsOutlinedIcon />,
    roles: [
      'Admin',
      'Super Manager',
      'Owner',
      'Manager',
      'Front Desk',
      'Trainer',
      'SuperAdmin'
    ]
  },
  {
    title: 'Marketing',
    path: '/',
    icon: <CampaignOutlinedIcon />,
    roles: [],
    childrens: [
      {
        title: 'Cohorts',
        path: '/franchisor-cohorts',
        icon: '',
        roles: ['Admin', 'Super Manager']
      },
      {
        title: 'Templates',
        path: '/franchisor-template',
        icon: '',
        roles: ['Admin', 'Super Manager']
      },
      {
        title: 'Cohorts',
        path: '/cohorts',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin']
      },
      {
        title: 'Templates',
        path: '/template',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin']
      },
      {
        title: 'Campaign',
        path: '/campaign-management',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin']
      },
      {
        title: 'Journeys',
        path: '/journey-management',
        icon: '',
        roles: ['Owner', 'Manager', 'Admin', 'Super Manager', 'SuperAdmin']
      }
    ]
  },
  {
    title: 'Schedule',
    path: '/schedules',
    icon: <CalendarMonthOutlinedIcon />,
    roles: ['Trainer', 'Front Desk']
  },
  {
    title: 'POS',
    path: '/pos',
    icon: <PosIcon />,
    roles: [],
    childrens: [
      {
        title: 'Dashboard',
        path: '/pos',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin']
      },
      // {
      //   title: 'Sales',
      //   path: '/sales-history',
      //   icon: '',
      //   roles: ['Owner', 'Manager', 'Trainer', 'Front Desk']
      // },
      {
        title: 'Gift Cards',
        path: '/gift-cards',
        icon: '',
        roles: ['Manager', 'Trainer', 'Front Desk']
      },
      {
        title: 'Returns & Refunds',
        path: '/sales-history',
        icon: '',
        roles: ['Owner', 'Manager', 'Trainer', 'Front Desk', 'SuperAdmin']
      }
    ]
  },

  {
    title: 'Inventory',
    path: '/inventory-managment',
    icon: <InventoryIcon />,
    roles: [],
    childrens: [
      {
        title: 'Products',
        path: '/franchise-products',
        icon: '',
        roles: ['Front Desk']
      },
      {
        title: 'Receive Inventory',
        path: '/receive-inventory',
        icon: '',
        roles: ['Front Desk']
      },
      {
        title: 'Inventory on Hand',
        path: 'inventory-onhand',
        icon: '',
        roles: ['Front Desk']
      }
    ]
  },
  // {
  //   title: 'Inventory Managment',
  //   path: '',
  //   icon: <Inventory2OutlinedIcon />,
  //   roles: [],
  //   childrens: [
  //     {
  //       title: 'Purchase Order',
  //       path: '/purchase-order',
  //       icon: '',
  //       roles: ['Owner', 'Manager', 'Front desk']
  //     },
  //     {
  //       title: 'Inventory Adjustment',
  //       path: '/inventory-adjustment',
  //       icon: '',
  //       roles: ['Owner', 'Manager', 'Front desk']
  //     },
  //     {
  //       title: 'Transfer Inventory',
  //       path: '/inventory-transfer',
  //       icon: '',
  //       roles: ['Owner', 'Manager', 'Front desk']
  //     },
  //     {
  //       title: 'Transfer History',
  //       path: '/transfer-history',
  //       icon: '',
  //       roles: ['Owner', 'Manager', 'Front desk']
  //     }
  //   ]
  // },
  // {
  //   title: 'Users',
  //   path: '/user-management',
  //   icon: <GroupsOutlinedIcon />,
  //   roles: ['Admin', 'Super Manager']
  // },
  {
    title: 'Staff Management',
    path: '/staff-management',
    icon: <PeopleOutlineOutlinedIcon />,
    roles: []
  },
  {
    title: 'Marketing Management',
    path: '/marketing',
    icon: <CampaignOutlinedIcon />,
    roles: []
  },
  {
    title: 'Administrative',
    path: '/',
    icon: <ManageAccountsIcon />,
    roles: [],
    childrens: [
      {
        title: 'User Management',
        path: '/user-management',
        icon: '',
        roles: ['Admin', 'Super Manager']
      }
      // {
      //   title: 'Task Management',
      //   path: '/task-management',
      //   icon: '',
      //   roles: ['Admin', 'Super Manager']
      // }
    ]
  },
  {
    title: 'Liability',
    path: '/liability',
    icon: <PolicyIcon />,
    roles: ['Admin', 'Super Manager']
  },
  // {
  //   title: 'Trophy Management',
  //   path: '/trophy-management',
  //   icon: <BusinessIcon />,
  //   roles: ['Admin', 'Super Manager']
  // },
  {
    title: 'Franchise Operations',
    path: '/pos',
    icon: <StorefrontOutlinedIcon />,
    roles: [],
    childrens: [
      {
        title: 'Purchase Order',
        path: '/purchase-order',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin']
      },
      {
        title: 'Inventory Management',
        path: '/inventory-managment',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin']
      },
      {
        title: 'Staff Management',
        path: '/staff-management',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin']
      },
      {
        title: 'Business Entities',
        path: '/business-entities',
        icon: '',
        roles: ['Owner', 'Manager', 'Front Desk', 'SuperAdmin']
      },
      {
        title: 'Featured Items',
        path: '/featured-items',
        icon: '',
        roles: ['Owner', 'Manager', 'SuperAdmin']
      }
      // {
      //   title: 'Reporting',
      //   path: 'report',
      //   icon: '',
      //   roles: ['Owner', 'Manager']
      // }
    ]
  },
  {
    title: 'Reports',
    path: '/report', // reporting
    icon: <ShowChartOutlinedIcon />,
    roles: ['Admin', 'Super Manager', 'Owner', 'Manager', 'SuperAdmin'], // 'Admin', 'Super Manager'
    childrens: [
      // {
      //   title: 'Performance Report',
      //   path: '/performance-report',
      //   icon: '',
      //   roles: ['Admin', 'Super Manager']
      // },
      // {
      //   title: 'AvgSpendingOverTime Report',
      //   path: '/avg-spendingOverTime-report',
      //   icon: '',
      //   roles: ['Admin', 'Super Manager']
      // },
      // {
      //   title: 'StaffRentation Report',
      //   path: '/staffRentation-report',
      //   icon: '',
      //   roles: ['Admin', 'Super Manager']
      // }
      {
        title: 'First Visit Report',
        path: '/report/client-visit',
        icon: '',
        roles: ['Owner', 'Manager', 'Admin', 'Super Manager', 'SuperAdmin']
      },
      {
        title: 'Royalty Report',
        path: '/report/royalty-report',
        icon: '',
        roles: ['Admin', 'Super Manager', 'SuperAdmin']
      },
      {
        title: 'Class Appointment Report',
        path: '/report/class-appointment',
        icon: '',
        roles: ['Owner', 'Manager', 'Admin', 'Super Manager', 'SuperAdmin']
      },
      {
        title: 'Inventory  Report',
        path: '/report/inventory-count',
        icon: '',
        roles: ['Owner', 'Manager', 'Admin', 'Super Manager', 'SuperAdmin']
      },
      {
        title: 'Last Visit Report',
        path: '/report/last-visit',
        icon: '',
        roles: ['Owner', 'Manager', 'Admin', 'Super Manager', 'SuperAdmin']
      },
      {
        title: 'Service Hours Report',
        path: '/report/service',
        icon: '',
        roles: ['Owner', 'Manager', 'Admin', 'Super Manager', 'SuperAdmin']
      },
      {
        title: 'Email Logs Report',
        path: '/report/email-log',
        icon: '',
        roles: ['Owner', 'Manager', 'Admin', 'Super Manager', 'SuperAdmin']
      },
      {
        title: 'Vaccination Report',
        path: '/report/vaccination',
        icon: '',
        roles: ['Owner', 'Manager', 'Admin', 'Super Manager', 'SuperAdmin']
      },
      {
        title: 'Visits Remaining Report',
        path: '/report/visits-remaining',
        icon: '',
        roles: ['Owner', 'Manager', 'Admin', 'Super Manager', 'SuperAdmin']
      },
      {
        title: 'Class Analysis Report',
        path: '/report/class-analysis',
        icon: '',
        roles: ['Owner', 'Manager', 'Admin', 'Super Manager', 'SuperAdmin']
      },
      {
        title: 'Client Attendance Report',
        path: '/report/client-attendance',
        icon: '',
        roles: ['Owner', 'Manager', 'Admin', 'Super Manager', 'SuperAdmin']
      },

      {
        title: 'Client Details Report',
        path: '/report/client-details',
        icon: '',
        roles: ['Owner', 'Manager', 'Admin', 'Super Manager', 'SuperAdmin']
      },
      {
        title: 'Client Source Report',
        path: '/report/client-source',
        icon: '',
        roles: ['Owner', 'Manager', 'Admin', 'Super Manager', 'SuperAdmin']
      },
      // {
      //   title: 'Membership Report',
      //   path: '/report/membership',
      //   icon: '',
      //   roles: ['Admin', 'Super Manager', 'Owner', 'Manager', 'SuperAdmin']
      // },
      {
        title: 'Sales Transaction Report',
        path: '/report/sales-transaction',
        icon: '',
        roles: ['Owner', 'Manager', 'Admin', 'Super Manager', 'SuperAdmin']
      },
      {
        title: 'Payroll Report',
        path: '/report/payroll-report',
        icon: '',
        roles: ['Owner', 'Manager', 'Admin', 'Super Manager', 'SuperAdmin']
      }
    ]
  }
  // {
  //   title: 'Journey Management',
  //   path: '/journey-management',
  //   icon: <CampaignOutlinedIcon />,
  //   roles: ['Admin', 'Super Manager', 'Owner', 'Manager']
  // },
  // {
  //   title: 'Promotion Management',
  //   path: '/franchisor-promotion',
  //   icon: <GroupsOutlinedIcon />,
  //   roles: [
  //     'Admin',
  //     'Super Manager'
  //   ]
  // },
  // {
  //   title: 'Contract Management',
  //   path: '/franchisor-contract',
  //   icon: <GroupsOutlinedIcon />,
  //   roles: [
  //     'Admin',
  //     'Super Manager'
  //   ]
  // },
  // {
  //   title: 'Franchisee Journey',
  //   path: '/franchisee-journey',
  //   icon: <CampaignOutlinedIcon />,
  //   roles: ['Owner', 'Manager']
  // },
  // {
  //   title: 'Settings',
  //   path: '/',
  //   icon: <SettingsOutlinedIcon />,
  //   roles: [],
  //   childrens: [
  //     {
  //       title: 'Liability Waivers',
  //       path: '/liability',
  //       icon: '',
  //       roles: ['Admin', 'Super Manager']
  //     }
  //   ]
  // },
  // {
  //   title: 'Settings',
  //   path: 'settings',
  //   icon: <SettingsOutlinedIcon />,
  //   roles: ['Owner', 'Manager', 'Trainer', 'Front Desk']
  // }
];
