import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  Typography,
  Grid,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Autocomplete,
  Slide,
  DialogActions,
  Divider,
  FormHelperText
} from '@mui/material';
import { ApiService } from '../../../../services/ApiService';
import './AddAlertNotes.css';
import { getLocalData } from 'src/utils';
import Loader from '../../../../components/Loader';
import { CustomDatePicker } from 'src/components/CustomDatePicker';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function AddAlertNotes({
  openAlertNotesDialog,
  setOpenAlertNotesDialog,
  setOpenAlertNotes,
  // getEventToRefresh,
  refreshAlertNotes,
  getClientItem,
  type
}) {
  // console.log("getClientItem", getClientItem);

  const [radioButtonValue, setradioButtonValue] = useState('notes');
  const [commentNotes, setCommentNotes] = useState('');
  const [expDate, setExpDate] = useState('');
  /* States  to set the selected alert type */
  const [alertType, setAlertType] = useState({});
  /* States to show the alert list name in select drop down */
  const [alertList, setAlertList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [alertTypeError, setAlertTypeError] = useState('');
  const [selectType, setSelectType] = useState(false);

  /* State to open/close confirmation dialog box */
  const [openConfirm, setOpen] = useState(false);

  /* State for checking the state of field update */
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);

  /* State to check whether the task  is added  */
  const [isUpdated, setIsUpdated] = useState(false);

  /* States for error messages */
  const [commentNotesError, setCommentNotesError] = useState('');

  useEffect(() => {
    setOpen(false);
    getAlertType();
    setIsUpdated(false);
  }, []);

  /* Variable getting user id from local storage */
  let userId = getLocalData('userId');
  let userName = getLocalData('UserName');

  // const isFranchisor = getLocalData('isFranchisor');
  // const isPrimaryOwner = getLocalData('isPrimaryOwner');
  // const locationId = getLocalData('locationId');
  // let encodedLocationId = encodeURIComponent(locationId);

  /* Function to get staff members */

  const getAlertType = async () => {
    try {
      let alerttype = '';
      if (type === 'pet') alerttype = 'Dog';
      else if (type === 'client') alerttype = 'Client';

      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}alerts?alertTypeFor=${alerttype}`
      );
      let data = response;
      if (data.statusCode && !data.error) {
        setAlertList(data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  /* Function to add task  firstName lastName */

  const addAlertNotes = async () => {
    try {
      setLoading(true);

      let clientid = localStorage.getItem('clientId');
      let url = `${process.env.REACT_APP_CHAT_API_END_POINT}client`;
      let obj = {
        createdBy: userId,
        createdByName: userName
      };

      if (type === 'client') obj.clientId = getClientItem.sortKey;
      if (type === 'pet') {
        obj.petId = getClientItem.sortKey;
        obj.clientId = clientid;
        // dog name bind the first name and last name
        obj.petName = getClientItem.firstName + ' ' + getClientItem.lastName;
      }

      if (radioButtonValue === 'alert') {
        url = `${url}/addAlert`;
        obj.alertTypeName = alertType.alertType;
        obj.comment = commentNotes;
        obj.expireDate = expDate;
        obj.alertTypeId = alertType.sortKey;
        obj.status = 1;
        // obj.createdByName = userName;
        obj.alertFor = type === 'pet' ? 'Dog' : 'Client';
      } else if (radioButtonValue === 'notes') {
        url = `${url}/addNotes`;
        obj.notes = commentNotes;
        obj.notesFor = type === 'pet' ? 'Dog' : 'Client';
      }

      const response = await ApiService.post(`${url}`, obj);
      let data = response;

      if (data.statusCode && !data.error) {
        setIsUpdated(true);
        setOpen(true);
        setLoading(false);
        setOpenAlertNotesDialog(false);
        // getEventToRefresh();

        // refresh the notes section data
        refreshAlertNotes();
      } else {
        setIsUpdated(false);
        setOpen(false);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  /* Function to close the main dialog box */
  const handleClose = () => {
    if (isFieldUpdated) {
      setOpenAlertNotesDialog(true);
      setOpen(true);
      clearFields();
    } else {
      setOpen(false);
      setOpenAlertNotesDialog(false);
      setOpenAlertNotes(false);
    }
  };

  /* Function to close the confirmation dialog box */

  const handleConfirmClose = (e) => {
    if (e === 'Discard') {
      setOpen(false);
      setOpenAlertNotesDialog(false);
      setIsFieldUpdated(false);
      setOpenAlertNotes(false);
      clearFields();
    } else if (e === 'Save') {
      setOpenAlertNotes(true);
      setOpen(false);
      setOpenAlertNotesDialog(true);
    }
  };

  /* Function to clear all fields */

  const clearFields = () => {
    setradioButtonValue('notes');
    setCommentNotes('');
    setExpDate('');
    // alertType.length = 0;
    setAlertType({});
    setCommentNotesError('');
  };

  /* Function to set staff members selected */

  const selectAlertType = (val) => {
    // if value is null then set the state to empty
    if (!val) {
      setAlertType({});
      setAlertTypeError('Please select alert type');
    } else {
      setAlertType(val);
      setAlertTypeError('');
    }
    setIsFieldUpdated(true);
  };

  /* Function to set task details  */
  const handleCommentNotes = (val) => {
    if (!val) {
      setCommentNotes('');
      // setCommentNotesError('Notes is required');
      setCommentNotesError(`${selectType ? 'Comments' : 'Notes'} is required`);
    } else {
      setIsFieldUpdated(true);
      setCommentNotesError('');
      setCommentNotes(val);
    }
  };

  /* Function to set EXPIRATION date */
  const ExpirationDate = (date) => {
    if (date === '01-01-1970') {
      return;
    }

    setIsFieldUpdated(true);
    setExpDate(date);
  };

  /* Function to set the assign to */

  const setAssignTo = (e) => {
    if (e === 'alert') {
      setSelectType(true);
      setradioButtonValue(e);
      setIsFieldUpdated(true);
      setCommentNotesError('');
      setCommentNotes('');
    } else {
      setSelectType(false);
      setradioButtonValue(e);
      setIsFieldUpdated(true);
      setAlertTypeError('');
      setCommentNotes('');
    }
  };

  /* Function to validateFields */
  const validateFields = () => {
    let data = Object.keys(alertType).length;

    if (radioButtonValue === 'alert' && data === 0) {
      setAlertTypeError('Please select alert type');
    }
    // if (radioButtonValue === 'notes' && !commentNotes) {
    //   setCommentNotesError('Notes is required');
    // }
    if (!commentNotes) {
      setCommentNotesError(`${selectType ? 'Comments' : 'Notes'} is required`);
    }

    // if ((radioButtonValue === 'alert' && data > 0) || (radioButtonValue === 'notes' && commentNotes)) {
    if (
      (radioButtonValue === 'alert' && data > 0 && commentNotes) ||
      commentNotes
    ) {
      addAlertNotes();
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Dialog
        open={openAlertNotesDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="details-dialog"
        className="dialog-box-right-addTask"
        maxWidth="sm"
      >
        <DialogTitle
          className="alert-title align-right"
          sx={{ backgroundColor: '#FFFFFF ! important' }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography className="page-header weight-600">
              Add Alert & Notes
            </Typography>
            {/* remove icon */}
            <Button
              className="details-remove-icon task-cross p-0 no-hover"
              onClick={() => handleClose('Close')}
            >
              <svg
                width="30"
                height="31"
                viewBox="0 0 30 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 27.2695C8.385 27.2695 3 21.8845 3 15.2695C3 8.65453 8.385 3.26953 15 3.26953C21.615 3.26953 27 8.65453 27 15.2695C27 21.8845 21.615 27.2695 15 27.2695ZM15 0.269531C6.705 0.269531 0 6.97453 0 15.2695C0 23.5645 6.705 30.2695 15 30.2695C23.295 30.2695 30 23.5645 30 15.2695C30 6.97453 23.295 0.269531 15 0.269531ZM18.885 9.26953L15 13.1545L11.115 9.26953L9 11.3845L12.885 15.2695L9 19.1545L11.115 21.2695L15 17.3845L18.885 21.2695L21 19.1545L17.115 15.2695L21 11.3845L18.885 9.26953Z"
                  fill="#E64646"
                />
              </svg>
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: '#FFFFFF ! important' }}>
          <FormControl className=" radio-button-text">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={radioButtonValue}
              name="radio-buttons-group"
              onChange={(e) => setAssignTo(e.target.value)}
            >
              <Box sx={{ display: 'flex' }}>
                <FormControlLabel
                  className="radio-button-style"
                  value="alert"
                  control={<Radio />}
                  label="Alert"
                />
                <FormControlLabel
                  className="radio-button-style"
                  value="notes"
                  control={<Radio />}
                  label="Notes"
                />
              </Box>
            </RadioGroup>
          </FormControl>

          <Box>
            <Grid container className="grid-containe">
              <Grid item xs={12} sm={12}>
                {selectType && (
                  <>
                    <Autocomplete
                      // multiple
                      className="select-staff required-field"
                      options={alertList}
                      getOptionLabel={(option) => option.alertType}
                      onChange={(event, selectedOptions) => {
                        // const selectedType = selectedOptions.map((option) => {
                        //   return {
                        //     id: option.sortKey,
                        //     name: option.alertType
                        //   };
                        // });
                        selectAlertType(selectedOptions);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={params?.label || 'ALERT TYPE'}
                          className="field-top-margin-edit-location select-field "
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee select-staff required-label'
                          }}
                        />
                      )}
                    />
                    <FormHelperText>{alertTypeError}</FormHelperText>
                  </>
                )}
              </Grid>

              <Grid item xs={12} sm={12} className="w-100vh">
                <TextField
                  fullWidth
                  multiline
                  id="my-textarea"
                  name="comments"
                  label={selectType ? 'COMMENTS' : 'NOTES'}
                  value={commentNotes}
                  onChange={(e) => handleCommentNotes(e.target.value)}
                  error={Boolean(commentNotesError)}
                  helperText={commentNotesError}
                  placeholder="Type here..."
                  variant="outlined"
                  className={`field-top-margin-edit-location required-field`}
                  InputLabelProps={{
                    className: `label-style-create-franchisee required-label`
                  }}
                  InputProps={{ rows: 6 }}
                />
              </Grid>

              {selectType && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className="form-datepicker-container"
                >
                  <CustomDatePicker
                    label="EXPIRATION DATE (Optional)"
                    fieldValue={expDate}
                    onChange={ExpirationDate}
                    minDate={new Date()}
                    className="field-top-margin-edit-location show"
                  />
                </Grid>
              )}

              <Grid container className="grid-containe">
                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="button-style-add-task field-top-margin-edit-location"
                    onClick={validateFields}
                    sx={{ marginTop: '20px' }}
                  >
                    <Typography className="submit-button-text">
                      Add Now
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      {/* Confirmation dialog */}
      <Dialog
        open={openConfirm}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmClose('Discard')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {!isUpdated ? 'Discard Changes?' : ''}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes, what do you want
                  <Typography className="title-text-sub">to do?</Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  {radioButtonValue === 'alert' ? 'Alert' : 'Notes'} has been
                  added successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleConfirmClose('Save')}
            >
              Go back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddAlertNotes;
