import React from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  FormHelperText,
  MenuItem,
  InputAdornment
} from '@mui/material';
// import MenuItem from '@mui/material/MenuItem';

export default function ReturnForm({
  formData,
  setFormData,
  errors,
  clientDetail,
  setErrors
}) {
  const refundMethodOptions =
    clientDetail?.paymentSourceType === 'COMP'
      ? [{ label: 'Comp', value: 'COMP' }]
      : [
          { label: 'Cash', value: 'CASH' },
          { label: 'Card', value: 'CARD' }
        ];

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
    setErrors({
      ...errors,
      [name]: ''
    });
  };

  return (
    <Box className="return-main">
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '27px' }}>
        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{ width: '49%' }}
          className="select-drop-down-label-styling required-field"
        >
          <InputLabel
            className="input-field-dropdown-styling required-label"
            style={{
              fontWeight: '800',
              fontSize: '16px',
              backgroundColor: '#fff'
            }}
          >
            REFUND METHOD
          </InputLabel>
          <Select
            value={formData?.refundMethod}
            onChange={(event) =>
              handleInputChange('refundMethod', event.target.value)
            }
            label="REFUND METHOD"
            className="input-field-styling"
          >
            {refundMethodOptions?.map((item, index) => (
              <MenuItem key={index} id={index} value={item?.value}>
                {item?.label}
              </MenuItem>
            ))}
          </Select>
          {errors?.refundMethod && (
            <FormHelperText>{errors?.refundMethod}</FormHelperText>
          )}
        </FormControl>

        <TextField
          fullWidth
          id="refund-amount"
          sx={{ width: '49%' }}
          label="REFUND AMOUNT"
          variant="outlined"
          value={formData?.refundAmount}
          className="text-field-styling"
          error={errors?.refundAmount}
          helperText={errors?.refundAmount}
          onChange={(event) =>
            handleInputChange('refundAmount', event.target.value)
          }
          InputLabelProps={{
            className: 'label-style-text-field'
          }}
          InputProps={{
            pattern: '[0-9]*\\.?[0-9]*',
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
        />
      </Box>
      <Grid container>
        <Grid item sm={6}>
          <TextField
            fullWidth
            multiline
            id="my-textarea"
            label="REASON FOR RETURN"
            placeholder="REASON FOR RETURN"
            variant="outlined"
            value={formData.refundReason}
            className="location-description required-field"
            error={errors?.refundReason}
            helperText={errors?.refundReason}
            onChange={(event) =>
              handleInputChange('refundReason', event.target.value)
            }
            InputLabelProps={{
              className: 'label-style-text-field required-label'
            }}
            InputProps={{
              style: { color: 'black' },
              rows: 6
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
