import React, { useState, useEffect } from 'react';
import { Box, Typography, Container } from '@mui/material';
import { DynamicButton } from '../../../../components/Checkout';
import { useSalesDetailFunctions } from './SalesDetailFunctions';
import { getLocalData } from 'src/utils';
import { ApiService } from 'src/services/ApiService';
import { useNavigate } from 'react-router-dom';
import Header from 'src/components/Header';

export const ReturnSuccess = ({
  setActive,
  receiptData,
  setReceiptData,
  refundOrderId,
  selectedClient
}) => {
  let BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;
 
  const paramsClientId = selectedClient?.sortKey && selectedClient?.sortKey?.replace('#CLIENT#', '')

  const { printReceipt } = useSalesDetailFunctions();
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(true);
  const [intervalCount, setIntervalCount] = useState(0);
  const [returnStatus, setReturnStatus] = useState('PENDING');

  const locationId = getLocalData('locationId');

  useEffect(() => {
    setIntervalCount(0);
  }, []);



  useEffect(() => {
    const confirmRefresh = (e) => {
      if (processing) {
        e.returnValue =
          'Leaving this page will stop the payment processing. Are you sure?';
      }
    };
    window.onbeforeunload = confirmRefresh;

    // Clean up the event listener when the component unmounts
    return () => {
      window.onbeforeunload = null;
    };
  }, [processing]);

  useEffect(() => {
    if (processing && intervalCount < 17) {
      const interval = setInterval(() => {
        setIntervalCount(intervalCount + 1);
        returnDetails();
      }, 2000);

      return () => {
        clearInterval(interval);
      };
    }

    if (intervalCount > 16) {
      setProcessing(false);
      setReturnStatus('PENDING');
    }

    // If processing is false, return a no-op function
    return () => {};
  }, [processing, intervalCount]);

  const returnDetails = async () => {
    const ZROrderId = encodeURIComponent(refundOrderId);
    let encodedLocationId = encodeURIComponent(locationId);

    try {
      let response = await ApiService.get(
        `${BASE_URL}checkout/refundDetails?locationId=${encodedLocationId}&refundOrderId=${ZROrderId}`
      );
      if (response.statusCode === 200) {
        if (response?.data?.Item?.refundStatus) {
          let returnStatus = response?.data?.Item?.refundStatus;

          if (returnStatus && returnStatus === 'COMPLETED') {
            setReturnStatus(response?.data?.Item?.refundStatus);
            setReceiptData(response?.data?.Item);
            setProcessing(false);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const emailReceipt = async () => {
    const ZROrderId = encodeURIComponent(refundOrderId);
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${BASE_URL}checkout/sendRefundMail?locationId=${encodedLocationId}&id=${ZROrderId}`
      );
      if (response.statusCode === 200) {
        if (response?.data?.Item?.refundStatus) {
          let returnStatus = response?.data?.Item?.refundStatus;
          if (returnStatus && returnStatus === 'COMPLETED') {
            setReturnStatus(response?.data?.Item?.refundStatus);
            setReceiptData(response?.data?.Item);
            setProcessing(false);
          }
        }
      }
      setActive(4);
    } catch (error) {
      console.error(error);
      setActive(4);
    }
  };

  return (
    <>
      <Container maxWidth="100%" className="main-container">
        <Header title="" />
      </Container>
      {processing ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className="spinner-box">
            <div className="circle-border">
              <div className="circle-core" />
            </div>
          </div>
          <Typography
            variant="h1"
            align="center"
            color="primary"
            style={{ marginBottom: '15px' }}
          >
            Refund is processing...
          </Typography>
          <Typography
            variant="h1"
            align="center"
            color="primary"
            style={{ marginBottom: '15px' }}
          >
            Please do not press Back or
            <br />
            Refresh
          </Typography>
        </Box>
      ) : returnStatus === 'PENDING' ? (
        <Box className="orderSuccess">
          <Box
            display="flex"
            justifyContent="center"
            direction="column"
            marginTop="0"
            className="tick-container"
          >
            <svg
              width="123"
              height="123"
              viewBox="0 0 452 452"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
            >
              <path
                d="M225.312 17.0068C340.736 17.0068 434.304 110.576 434.304 225.999C434.304 341.423 340.736 434.991 225.312 434.991M216 107V237.62L320.496 342.116M149.728 31.1027C149.728 31.1027 154.769 29.0848 160.339 27.3443C165.909 25.6039 172.369 23.7793 172.369 23.7793M86.2607 69.916C86.2607 69.916 90.2758 66.2599 94.8841 62.6793C99.4923 59.0987 104.904 55.127 104.904 55.127M40.4048 128.497C40.4048 128.497 42.8858 123.666 45.9478 118.698C49.0099 113.73 52.6876 108.115 52.6876 108.115M17.9678 199.427C17.9678 199.427 18.6 194.033 19.7282 188.307C20.8564 182.582 22.3349 176.034 22.3349 176.034M21.7933 273.723C21.7933 273.723 20.496 268.45 19.5482 262.692C18.6003 256.934 17.6924 250.283 17.6924 250.283M51.3947 341.974C51.3947 341.974 48.3326 337.489 45.4284 332.427C42.5234 327.366 39.3442 321.454 39.3442 321.454M103.023 395.537C103.023 395.537 98.5852 392.408 94.0915 388.684C89.5977 384.961 84.5498 380.537 84.5498 380.537M170.141 427.626C170.141 427.626 164.888 426.25 159.375 424.335C153.862 422.42 147.584 420.045 147.584 420.045"
                stroke="#FDA91A"
                strokeWidth="34"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Box>
          <Typography
            variant="h1"
            align="center"
            color="primary"
            style={{ marginBottom: '15px' }}
          >
            Refund status pending
          </Typography>
          <Typography
            variant="body1"
            align="center"
            style={{
              maxWidth: '369px',
              fontWeight: '400',
              fontSize: '16px',
              color: '#000000',
              margin: '0 auto 35px'
            }}
          >
            We will update you once the refund status updates.
          </Typography>
          <DynamicButton
            title={'Back to Sales History'}
            onClick={() => {
              navigate(
                paramsClientId
                  ? `/sales-history/${paramsClientId}`
                  : `/sales-history`
              );
              window.location.reload();
            }}
            variant="text"
            style={{
              padding: '0',
              maxWidth: 'max-content',
              background: 'transparent',
              textDecoration: 'underline',
              fontWeight: '600',
              fontSize: '15px',
              margin: '0 auto',
              display: 'block'
            }}
          />
        </Box>
      ) : returnStatus === 'COMPLETED' ? (
        <Box className="orderSuccess">
          <Typography
            variant="h1"
            align="center"
            color="primary"
            style={{ marginBottom: '15px' }}
          >
            Return Successful
          </Typography>
          <Typography
            variant="body1"
            align="center"
            style={{
              maxWidth: '369px',
              fontWeight: '400',
              fontSize: '16px',
              color: '#000000',
              margin: '0 auto 35px'
            }}
          >
            Congratulations! Your return has been successfully processed. Thank
            you for your purchase.
          </Typography>
          <Box
            style={{
              maxWidth: '330px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0 auto'
            }}
          >
            <DynamicButton
              title={'Print Receipt'}
              variant="contained"
              onClick={() => printReceipt(receiptData)}
              style={{
                padding: '10px 23px',
                borderRadius: '10px',
                textTransform: 'capitalize',
                maxWidth: 'max-content',
                fontWeight: '600',
                fontSize: '16px',
                margin: '0 auto 24px',
                border: '2px solid #003087'
              }}
            />

            <DynamicButton
              title={'Email Receipt'}
              variant="contained"
              onClick={() => emailReceipt()}
              style={{
                padding: '10px 23px',
                borderRadius: '10px',
                textTransform: 'capitalize',
                maxWidth: 'max-content',
                fontWeight: '600',
                fontSize: '16px',
                margin: '0 auto 24px',
                border: '2px solid #003087'
              }}
            />
          </Box>
          <DynamicButton
            title={'Back to Sales History'}
            onClick={() => {
              navigate(
                paramsClientId
                  ? `/sales-history/${paramsClientId}`
                  : `/sales-history`
              );
              window.location.reload();
            }}
            variant="text"
            style={{
              padding: '0',
              maxWidth: 'max-content',
              background: 'transparent',
              textDecoration: 'underline',
              fontWeight: '600',
              fontSize: '15px',
              margin: '0 auto',
              display: 'block'
            }}
          />
        </Box>
      ) : (
        ''
      )}
    </>
  );
};