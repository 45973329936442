/* eslint-disable no-useless-computed-key */
/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
import {
  Container,
  Box,
  Button,
  styled,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Card,
  InputLabel,
  Select,
  FormControl,
  MenuItem
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { DeleteIcon } from 'src/assets/SVG/SvgIcons'; // ArrowLeft
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { getLocalData, setFullLocationId } from 'src/utils';
import { mt } from 'date-fns/locale';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// LocationDetails page style wrapper
const ServiceDetailsWrapper = styled(Container)(
  ({ theme }) => `
          margin-top: ${theme.spacing(2)};
  `
);

export const TemplateDetail = (props) => {
  const { selectedRows, setShowScreen } = props;
  const [rowData, setRowData] = useState([selectedRows]);
  let selectedRow = rowData[0];

  console.log(selectedRows, 'fjfhdfhjdf');
  const handleResetStates = () => {
    setShowScreen(0);
  };

  const handleOpenScreen = () => {
    setShowScreen(3);
  };
  const handleDuplicateScreen = () => {
    setShowScreen(2);
  };
  // method to capitalize first letter
  function capitalizeStatus(status) {
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  }

  console.log(selectedRow?.HtmlPart, 'HtmlPartHtmlPart');

  return (
    <div className="custom-summary">
      <ServiceDetailsWrapper
        maxWidth={'100%'}
        className="location-details-wrapper p-0"
      >
        {selectedRow.TemplateType === 'EMAIL' ? (
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box
                    onClick={() => handleResetStates()}
                    className="back-arrow-close"
                  >
                    <ArrowLeft />
                  </Box>

                  <Typography className="header-blue-32">
                    {selectedRow.TemplateName}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
                gap={2}
              >
                <Grid>
                  <Button
                    className="cohorts-outlined-btn"
                    variant="outlined"
                    onClick={(e) => handleDuplicateScreen()}
                  >
                    Duplicate
                  </Button>
                </Grid>
                {selectedRow.createdBy !== 'Franchisor' && (
                  <Grid>
                    <Button
                      // sx={{ mt: { xs: 0, md: 0 } }}
                      variant="contained"
                      onClick={(e) => handleOpenScreen()}
                      className="btn-primary edit-button template-edit-btn"
                    >
                      Edit
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Box className="location-details-container" mt={5} ml={3}>
              {/* <Grid container spacing={2} > */}
              <Grid container mt={3} className="location-details-row">
                <Grid item xs={12} sm={1.4} className="location-details-col">
                  <Box className="location-details-label summary-heading">
                    NAME
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4.6} className="location-details-col">
                  <Box className="template-detail-value summary-value">
                    {selectedRow.TemplateName}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={1.4} className="location-details-col">
                  <Box className="location-details-label summary-heading">
                    CHANNEL
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4.6} className="location-details-col">
                  <Box className="template-detail-value summary-value">
                    {selectedRow.TemplateType === 'EMAIL'
                      ? capitalizeStatus(selectedRow.TemplateType)
                      : 'Text'}
                  </Box>
                </Grid>
              </Grid>
              <Grid container mt={3} className="location-details-row">
                <Grid item xs={12} sm={1.4} className="location-details-col">
                  <Box className="location-details-label summary-heading">
                    CREATED BY
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4.6} className="location-details-col">
                  <Box className="template-detail-value summary-value">
                    {selectedRow.createdBy}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              ml={2}
              mt={5}
            >
              <Grid item>
                {/* <CardHeader title="All Services" className="table-header" /> */}
                {/* <h2 className="table-heading">All Services</h2> */}
                <Typography className="table-heade header-blue-20">
                  {' '}
                  Message
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={3} ml={2.5} className="location-details-row">
              <Grid item xs={12} sm={1.5} className="location-details-col">
                <Box className="location-details-label">SUBJECT</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="location-details-col">
                <Box className="template-detail-value">
                  {selectedRow?.Subject}
                </Box>
              </Grid>
            </Grid>
            <Grid container mt={3} ml={2.5} className="location-details-row">
              <Grid item xs={12} sm={12} className="location-details-col">
                <Box className="location-details-label">BODY</Box>
              </Grid>
              <Grid
                item
                mt={2}
                xs={12}
                sm={12}
                className="location-details-col campaign-details cohort-detail-text"
              >
                <div
                  dangerouslySetInnerHTML={{ __html: selectedRow.HtmlPart }}
                />
                {/* <Box mb={2} className="template-detail-value">
                               Hi! John doe
                                </Box>
                                <Box className="template-detail-value">

                                    We hope this message finds you and your beloved pets in great spirits. As the holiday season approaches, we have some tail-wagging news to share! <br />
                                    <br />Get ready to pamper your furry friends like never before with our exclusive Black Friday sale!
                                    From November 26th to November 29th, you can enjoy unbeatable discounts and exciting offers on a wide range of
                                    dog products and services.
                                </Box> */}
              </Grid>
            </Grid>
            {/* <Grid container mt={3} ml={2.5} className="location-details-row">
                            <Grid item xs={12} sm={12} className="location-details-col">
                                <Box className="location-details-label">Here's Sneak Peek of What's In Store</Box>
                            </Grid>
                            <Grid item xs={12} sm={12} className="location-details-col campaign-details">
                                <Box className="template-detail-value">
                                    <ul>
                                        <li>
                                            20% Off All Grooming Services: Treat your dog to a spa day with our professional grooming services.
                                        </li>
                                        <li>
                                            Buy One, Get One Free on Dog Toys: Double the fun for your playful pals!
                                        </li>
                                        <li>
                                            50% Off First Training Class: Enhance your dog's skills and strengthen your bond.
                                        </li>
                                        <li>
                                            Free Treats with Every Purchase: Because every dog deserves a treat!
                                        </li>
                                    </ul>
                                </Box>
                            </Grid>
                        </Grid></> */}
          </>
        ) : (
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box
                    onClick={() => handleResetStates()}
                    className="back-arrow-close"
                  >
                    <ArrowLeft />
                  </Box>

                  <Typography className="header-blue-32">
                    {selectedRow.TemplateName}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
                gap={2}
              >
                <Grid>
                  <Button
                    className="cohorts-outlined-btn"
                    variant="outlined"
                    onClick={(e) => handleDuplicateScreen()}
                  >
                    Duplicate
                  </Button>
                </Grid>
                {selectedRow.createdBy !== 'Franchisor' && (
                  <Grid>
                    <Button
                      // sx={{ mt: { xs: 0, md: 0 } }}
                      variant="contained"
                      onClick={(e) => handleOpenScreen()}
                      className="btn-primary edit-button template-edit-btn"
                    >
                      Edit
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Box className="location-details-container" mt={5} ml={3}>
              {/* <Grid container spacing={2} > */}
              <Grid container mt={3} className="location-details-row">
                <Grid item xs={12} sm={1.4} className="location-details-col">
                  <Box className="location-details-label summary-heading">
                    NAME
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4.6} className="location-details-col">
                  <Box className="template-detail-value summary-value">
                    {selectedRow.TemplateName}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={1.4} className="location-details-col">
                  <Box className="location-details-label summary-heading">
                    CHANNEL
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4.6} className="location-details-col">
                  <Box className="template-detail-value summary-value">
                    {selectedRow.TemplateType === 'EMAIL'
                      ? capitalizeStatus(selectedRow.TemplateType)
                      : 'Text'}
                  </Box>
                </Grid>
              </Grid>
              <Grid container mt={3} className="location-details-row">
                <Grid item xs={12} sm={1.4} className="location-details-col">
                  <Box className="location-details-label summary-heading">
                    CREATED BY
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4.6} className="location-details-col">
                  <Box className="template-detail-value summary-value">
                    {selectedRow.createdBy}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              ml={2}
              mt={5}
            >
              <Grid item>
                {/* <CardHeader title="All Services" className="table-header" /> */}
                {/* <h2 className="table-heading">All Services</h2> */}
                <Typography className="table-heade header-blue-20">
                  {' '}
                  Message
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              mt={2}
              ml={2.5}
              className="location-details-row cohort-detail-text"
            >
              <Box>{selectedRow.Body}</Box>
              {/* <Grid item mt={3} xs={12} sm={12} className="location-details-col campaign-details">
                                <Box mb={2} className="template-detail-value">
                                Holiday Greetings from Zoom Room Belmont
                                </Box>
                                <Box className="template-detail-value">
                                Wishing you and your furry friends a positively joyful holiday season filled with love, laughter, and tail wags! <br />
                                    <br />To make this season extra special, enjoy 15% off all holiday-themed dog toys and treats at  belmont.
                                  <br />Just mention this text to redeem your discount.<br /> <br />
                                  Our Holiday Hours: <br />
                                  Christmas Eve: 08:00 AM - 05:00 PM <br /><br />
                                  Thank you for being part of our [Your Dog Franchise] family. We can't wait to celebrate the holidays with you <br />
                                  and your beloved pets!
                                </Box>
                            </Grid> */}
            </Grid>
          </>
        )}
      </ServiceDetailsWrapper>
    </div>
  );
};
